<template>
  <div>
    <div v-show="!isLoading">
      <hsPageHeader
        :title="$t(`${translateKey}.title`)"
        :sub-title="$t(`${translateKey}.description`)"
        :back-text="$t(`${translateKey}.back-text`)"
        back-route="SalesReport"
        :back-only-mobile="false"
      />

      <div class="filters">
        <div class="multiselect-filter">
          <span>Selecionar produtos</span>
          <hs-multiselect
            v-model="selectedProducts"
            :options="products"
            label="text"
            track-by="id"
            :multiple="true"
            :taggable="true"
          />
        </div>

        <Multiselect :options.sync="offers" @change="handleChangeSelectedOffers" label="Selecionar oferta" />

        <div class="date">
          <span>Data inicial</span>
          <DateFilter
            placeholder="DD/MM/YYYY"
            :filter="startDateFilter"
            class="dateFilter"
            @changed="handleChangeStartDate"
            :emitted="true"
          />
        </div>

        <div class="date">
          <span>Data final</span>
          <DateFilter
            placeholder="DD/MM/YYYY"
            :filter="endDateFilter"
            @changed="handleChangeEndDate"
            class="dateFilter"
            :emitted="true"
          />
        </div>
      </div>
      <div class="sales-made-report__report-container">
        <div ref="chart" id="chart"></div>
      </div>
    </div>
    <div class="loading" v-show="isLoading">
      <hs-loading-icon cherry />
    </div>
  </div>
</template>

<script>
import 'vue-multiselect/dist/vue-multiselect.min.css';
import { mapState } from 'vuex';
import * as jose from 'jose';

import SparkEcommerceApiClient from '@/services/sparkecommerce/api_client.js';

import hsPageHeader from '@/components/_structures/PageHeader.vue';
import ToastHelper from '@/shared/helpers/toast';
import Multiselect from './components/Multiselect.vue';
import DateFilter from '@/components/Filters/components/DateFilter.vue';

const secretKey = process.env.VUE_APP_MONGODB_SECRET_KEY;
const audience = 'mongo-charts';

export default {
  name: 'ReleasesReport',
  components: {
    hsPageHeader,
    Multiselect,
    DateFilter,
  },
  async mounted() {
    sessionStorage.removeItem('persist:mongodb-charts:refresh-entities');
    await this.renderChart();
    await this.getProducts();
  },
  data() {
    return {
      selectedKey: null,
      chartRef: null,
      products: [],
      offers: [],
      productsMongoQuery: '',
      offersMongoQuery: '',
      startDateMongoQuery: '',
      endDateMongoQuery: '',
      mongoQuery: '',
      isLoading: true,
      selectedProducts: [],
      startDateFilter: {
        type: 'Date',
        key: 'enrolled_at%3E%3D',
        label: this.$root.$t('sparkmembers.members-list.filters.date.label'),
        format: { day: 'numeric', year: 'numeric', month: 'numeric' },
        value: null,
      },
      endDateFilter: {
        type: 'Date',
        key: 'enrolled_at%3E%3D',
        label: this.$root.$t('sparkmembers.members-list.filters.date.label'),
        format: { day: 'numeric', year: 'numeric', month: 'numeric' },
        value: null,
      },
    };
  },
  watch: {
    async mongoQuery(e) {
      this.isLoading = true;
      try {
        if (e) {
          await this.chartRef.setFilter(JSON.parse(this.mongoQuery));
        } else {
          await this.chartRef.setFilter({});
        }
      } catch (error) {
        ToastHelper.dangerMessage('Erro ao aplicar o filtro');
      } finally {
        this.isLoading = false;
      }
    },
    async selectedProducts() {
      if (!this.selectedProducts.length) {
        this.offers = [];
        this.mongoQuery = '';
        return;
      }
      let newOffers = [];
      let filtersForProduct = [];

      this.selectedProducts.forEach(async product => {
        const { offers } = product;

        const formattedOffer = offers.map(o => ({ item: { text: o.title, id: o.id } }));

        newOffers = [...newOffers, ...formattedOffer];
        filtersForProduct.push({ 'products.productId': product.id });
      });

      this.offers = newOffers;
      this.productsMongoQuery = `{ "$or": ${JSON.stringify(filtersForProduct)} }`;
      this.mongoQuery = this.productsMongoQuery;
    },
  },
  computed: {
    ...mapState('school', ['selectedSchool']),
    translateKey() {
      return 'menu.sparkpay.sales.releases_report';
    },
  },
  methods: {
    renderChart: async function() {
      try {
        this.isLoading = true;
        const secret = new TextEncoder().encode(secretKey);
        const alg = 'HS256';
        const jwt = await new jose.SignJWT({ schoolId: this.selectedSchool.id })
          .setProtectedHeader({ alg })
          .setSubject(this.selectedSchool.id)
          .setIssuedAt()
          .setAudience(audience)
          .setExpirationTime('1h')
          .sign(secret);

        // @ts-ignore
        const { ChartsEmbedSDK } = global;

        const sdk = new ChartsEmbedSDK({
          baseUrl: 'https://charts.mongodb.com/charts-herospark-zzxnp',
          getUserToken: () => jwt,
        });

        const dashboardId = '62cdde42-3bc9-4d79-b3f5-fabf976c4e31';

        this.chartRef = await sdk.createDashboard({
          dashboardId,
          heightMode: 'fixed',
          widthMode: 'scale',
          showAttribution: false,
          autoRefresh: false,
          maxDataAge: 60,
        });

        this.chartRef.render(this.$refs.chart);
      } catch (error) {
        ToastHelper.dangerMessage('Erro ao carregar o dashboard');
      } finally {
        this.isLoading = false;
      }
    },
    async getProducts() {
      try {
        const products = await SparkEcommerceApiClient.products.all({ page: 1, items: 999 });
        const formattedProducts = products.map(p => ({ text: p.name, id: p.id, offers: p.offers }));

        this.products = formattedProducts;
      } catch (error) {
        ToastHelper.dangerMessage(this.$t('sparkmembers.products-list.toast-messages.products-loading-error'));
      }
    },
    async handleChangeSelectedProducts(selectedProducts) {
      if (!selectedProducts.length) {
        this.offers = [];
        this.mongoQuery = '';
        return;
      }
      let newOffers = [];
      let filtersForProduct = [];

      selectedProducts.forEach(async product => {
        const { offers } = product;

        const formattedOffer = offers.map(o => ({ item: { text: o.title, id: o.id } }));

        newOffers = [...newOffers, ...formattedOffer];
        filtersForProduct.push({ 'products.productId': product.id });
      });

      this.offers = newOffers;
      this.productsMongoQuery = `{ "$or": ${JSON.stringify(filtersForProduct)} }`;
      this.mongoQuery = this.productsMongoQuery;
    },
    async handleChangeSelectedOffers(selectedOffers) {
      if (!selectedOffers.length) {
        this.mongoQuery = this.productsMongoQuery;
        return;
      }

      let filtersForOffer = [];
      selectedOffers.forEach(async offer => {
        filtersForOffer.push({ 'products.offerId': offer.id });
      });

      this.offersMongoQuery = `{ "$or": ${JSON.stringify(filtersForOffer)} }`;
      this.mongoQuery = `{ "$and": [ ${this.productsMongoQuery}, ${this.offersMongoQuery} ] }`;
    },
    async handleChangeStartDate(date) {
      if (!date) return;
      this.isLoading = true;
      try {
        const actualFilter = await this.chartRef.getFilter();
        const [year, month, day] = date.split('-');
        const finalDate = `${year}/${month}/${Number(day)}`;

        const newFilter = {
          ...actualFilter,
          'transaction.createdAt': {
            ...actualFilter['transaction.createdAt'],
            $gte: new Date(finalDate),
          },
        };

        await this.chartRef.setFilter(newFilter);
      } catch (error) {
        ToastHelper.dangerMessage('Erro ao aplicar o filtro');
      } finally {
        this.isLoading = false;
      }
    },
    async handleChangeEndDate(date) {
      if (!date) return;
      try {
        const actualFilter = await this.chartRef.getFilter();
        const [year, month, day] = date.split('-');
        const finalDate = `${year}/${month}/${Number(day)}`;

        const newFilter = {
          ...actualFilter,
          'transaction.createdAt': {
            ...actualFilter['transaction.createdAt'],
            $lte: new Date(finalDate),
          },
        };

        await this.chartRef.setFilter(newFilter);
      } catch (error) {
        ToastHelper.dangerMessage('Erro ao aplicar o filtro');
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.sales-made-report__container {
  padding: 24px 16px;

  @media (min-width: $screen-md) {
    padding: 24px 32px;
  }
}

.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  height: 100%;
}

.sales-made-report__iframe-metabase {
  background-color: $white;
  min-height: 500px;
  width: 100%;
}

.sales-made-report__report-container {
  height: calc(100vh - 212px);

  > div {
    height: 100%;
  }

  @media (min-width: $screen-md) {
    // padding: 32px;
  }
}

.filters {
  margin-top: 24px;
  margin-bottom: 8px;
  margin-left: 32px;

  display: flex;
  flex-direction: row;

  gap: 16px;

  .form-group {
    margin-bottom: 0;
  }

  /deep/.multiselect-filter .dropdown-menu.show {
    width: 100%;
    min-width: 280px;
  }

  .date {
    display: flex;
    flex-direction: column;
    gap: 8px;

    > span {
      font-weight: bold;
    }
  }
  .dateFilter {
    width: fit-content;
    height: 41px !important;
    background-color: white;
    font-size: 14px;
    color: #bababa;
    font-weight: 400;
    border-radius: 4px;
    border: 2px solid #bababa;

    padding: 8px;
    gap: 5px;

    /deep/ label,
    /deep/ .btn {
      padding: 0;
    }
  }
}
.multiselect-filter {
  display: flex;
  flex-direction: column;
  gap: 8px;

  > span {
    font-weight: bold;
  }
  .hs-multiselect {
    width: 100%;
    min-width: 400px;
    max-width: 400px;
    /deep/.multiselect__tags {
      border: 2px solid #bababa;
      border-radius: 4px;
      line-height: unset;
      display: flex;
      align-items: center;
      .multiselect__placeholder {
        color: #bababa;
        font-size: 14px;
      }
    }
  }
}
</style>
