<template>
  <div>
    <div class="header">
      <hsPageHeader
        :title="$t(`${translateKey}.title`)"
        :sub-title="$t(`${translateKey}.description`)"
        :back-text="$t(`${translateKey}.back-text`)"
        back-route="SalesReport"
        :back-only-mobile="false"
      />

      <div class="report-header-actions">
        <div class="exports-list">
          <p v-if="exportRequests.length" class="exports-list-title">
            Últimos relatórios gerados:
          </p>
          <div v-for="(request, index) in exportRequests" :key="index">
            <a v-if="request.report_url" :href="request.report_url" class="exports-list-done" target="_blank">
              Relatório {{ formatDate(request.sent_at) }}
            </a>
            <p v-else class="exports-list-waiting">
              Gerando relatório (Em progresso...)
            </p>
          </div>
        </div>
        <MButton
          variant="primary"
          :disabled="disableReportDownload || isLoadingExport"
          class="mongo-report-export-button"
          @click="downloadReport"
        >
          <hs-icon variant="light" :size="20" icon="download" class="mr-2" />
          Exportar Dados
        </MButton>
      </div>
    </div>

    <InvoiceConfigBanner v-if="isInvoiceReport" />

    <MConfirmModal
      id="export-modal"
      :image="require('@/assets/images/funnel/modal-envelope.svg')"
      :title="$t('menu.sparkpay.sales.sales_report.export_report.title')"
      :text="$t('menu.sparkpay.sales.sales_report.export_report.description')"
    >
      <template v-slot:footer>
        <div class="modal-buttons">
          <MButton variant="primary" @click="closeModal" label="Ok, entendi" />
        </div>
      </template>
    </MConfirmModal>

    <div class="filters">
      <div class="multiselect-filter">
        <span>Selecionar produtos</span>
        <hs-multiselect
          v-model="selectedProducts"
          :options="products"
          label="text"
          track-by="id"
          :multiple="true"
          :taggable="true"
        />
      </div>

      <Multiselect :options.sync="offers" @change="handleChangeSelectedOffers" label="Selecionar oferta" />

      <div v-if="displayDateFilter" class="date-filters-wrapper">
        <div class="date">
          <span>Data inicial</span>
          <DateFilter
            placeholder="DD/MM/YYYY"
            :filter="startDateFilter"
            class="dateFilter"
            @changed="handleChangeStartDate"
            :emitted="true"
          />
        </div>

        <div class="date">
          <span>Data final</span>
          <DateFilter
            placeholder="DD/MM/YYYY"
            :filter="endDateFilter"
            @changed="handleChangeEndDate"
            class="dateFilter"
            :emitted="true"
          />
        </div>
      </div>
    </div>
    <div class="sales-made-report__report-container">
      <div ref="chart" id="chart"></div>
    </div>
  </div>
</template>

<script>
import 'vue-multiselect/dist/vue-multiselect.min.css';
import { mapState } from 'vuex';
import { analyticsService } from '@/services';
import * as jose from 'jose';

import SparkEcommerceApiClient from '@/services/sparkecommerce/api_client.js';
import hsPageHeader from '@/components/_structures/PageHeader.vue';
import ToastHelper from '@/shared/helpers/toast';
import Multiselect from './Multiselect.vue';
import DateFilter from '@/components/Filters/components/DateFilter.vue';
import MButton from '@/shared/components/MButton.vue';
import InvoiceConfigBanner from './InvoiceConfigBanner.vue';
import dayjs from 'dayjs';
import MConfirmModal from '@/shared/components/MConfirmModal.vue';

const secretKey = process.env.VUE_APP_MONGODB_SECRET_KEY;
const audience = 'mongo-charts';

const COLLECTION_PRODUCT_MAP = {
  purchase: product => ({ 'products.productId': product.id }),
  transaction: product => ({ 'products.productId': product.id }),
  tracking: product => ({ productName: product.text }),
};

const COLLECTION_OFFER_MAP = {
  purchase: offer => ({ 'products.offerId': offer.id }),
  transaction: offer => ({ 'products.offerId': offer.id }),
  tracking: offer => ({ offerName: offer.text }),
};

export default {
  name: 'MongoReport',
  components: {
    hsPageHeader,
    Multiselect,
    DateFilter,
    MButton,
    InvoiceConfigBanner,
    MConfirmModal,
  },
  props: {
    dashboardId: {
      type: String,
      required: true,
    },
    translateKey: {
      type: String,
      required: true,
    },
    isInvoiceReport: {
      type: Boolean,
      default: false,
    },
    disableReportDownload: {
      type: Boolean,
      default: true,
    },
    exportType: {
      type: String,
      default: '',
    },
    reportCollection: {
      type: String,
      default: 'transaction',
    },
    displayDateFilter: {
      type: Boolean,
      default: true,
    },
    isRefundedAt: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      pollingInterval: null,
      selectedKey: null,
      chartRef: null,
      chartByIdRef: null,
      selectedProducts: [],
      selectedOffers: [],
      selectedStartDate: null,
      selectedEndDate: null,
      products: [],
      offers: [],
      loadingChartById: false,
      productsMongoQuery: '',
      offersMongoQuery: '',
      startDateMongoQuery: '',
      endDateMongoQuery: '',
      mongoQuery: '',
      isLoadingExport: false,
      startDateFilter: {
        type: 'Date',
        key: 'enrolled_at%3E%3D',
        label: this.$root.$t('sparkmembers.members-list.filters.date.label'),
        format: { day: 'numeric', year: 'numeric', month: 'numeric' },
        value: null,
      },
      endDateFilter: {
        type: 'Date',
        key: 'enrolled_at%3E%3D',
        label: this.$root.$t('sparkmembers.members-list.filters.date.label'),
        format: { day: 'numeric', year: 'numeric', month: 'numeric' },
        value: null,
      },
      exportRequests: [],
      COLLECTION_DATE_MAP: {},
    };
  },
  async mounted() {
    await this.renderChart();
    await this.getProducts();
    this.getExportRequests();
    this.startRequestPolling();

    this.COLLECTION_DATE_MAP = {
      purchase: 'purchase.firstCreatedAt',
      transaction: this.isRefundedAt ? 'transaction.refundedAt' : 'transaction.paidAt',
    };
  },
  computed: {
    ...mapState('school', ['selectedSchool']),
    ...mapState('auth', ['loggedUser']),
  },
  methods: {
    startRequestPolling() {
      this.pollingInterval = setInterval(() => {
        this.getExportRequests();
      }, 15000);
    },
    stopRequestPolling() {
      clearInterval(this.pollingInterval);
    },
    formatDate(date) {
      return dayjs(date).format('DD/MM/YYYY [às] HH:mm');
    },
    getExportRequests() {
      const { email } = this.loggedUser;

      SparkEcommerceApiClient.reportExportRequests.getRequests(this.exportType, email).then(requests => {
        this.exportRequests = requests;
      });
    },
    async setFilter(filter) {
      await this.chartRef.setFilter(filter);
    },
    showModal() {
      this.$bvModal.show('export-modal');
    },
    closeModal() {
      this.$bvModal.hide('export-modal');
    },
    async downloadReport() {
      try {
        this.isLoadingExport = true;
        const { first_name, email } = this.loggedUser;

        const payload = {
          email,
          full_name: first_name,
          export_type: this.exportType,
          product_id: this.selectedProducts.map(p => p.id),
          product_name: this.selectedProducts.map(p => p.text),
          offer_name: this.selectedOffers.map(o => o.text),
          offer_id: this.selectedOffers.map(o => o.id),
          start_date: this.selectedStartDate,
          end_date: this.selectedEndDate,
        };

        await SparkEcommerceApiClient.salesReport.exportData(payload);
        this.$bvModal.show('export-modal');

        this.trackAnalyticsEvent();
      } catch {
        ToastHelper.dangerMessage('Erro ao exportar dados');
      } finally {
        this.isLoadingExport = false;
        this.getExportRequests();
        ToastHelper.successMessage('Dados exportados com sucesso');
      }
    },
    async generateJWTToken() {
      const secret = new TextEncoder().encode(secretKey);
      const alg = 'HS256';
      const jwt = await new jose.SignJWT({ schoolId: this.selectedSchool.id })
        .setProtectedHeader({ alg })
        .setSubject(this.selectedSchool.id)
        .setIssuedAt()
        .setAudience(audience)
        .setExpirationTime('1h')
        .sign(secret);

      return jwt;
    },
    trackAnalyticsEvent() {
      const routeName = this.$route.name;

      analyticsService.track({
        event: 'Report Downloaded',
        props: {
          report: routeName,
        },
      });
    },
    renderChart: async function() {
      const jwt = await this.generateJWTToken();

      // @ts-ignore
      const { ChartsEmbedSDK } = global;

      const sdk = new ChartsEmbedSDK({
        baseUrl: 'https://charts.mongodb.com/charts-herospark-zzxnp',
        getUserToken: () => jwt,
      });

      this.chartRef = await sdk.createDashboard({
        dashboardId: this.dashboardId,
        heightMode: 'fixed',
        widthMode: 'scale',
        showAttribution: false,
        autoRefresh: false,
        maxDataAge: 60,
      });

      await this.chartRef.render(this.$refs.chart);
    },
    async getProducts() {
      try {
        const products = await SparkEcommerceApiClient.products.all({ page: 1, items: 999 });
        const formattedProducts = products.map(p => ({ text: p.name, id: p.id, offers: p.offers }));

        this.products = formattedProducts;
      } catch (error) {
        ToastHelper.dangerMessage(this.$t('sparkmembers.products-list.toast-messages.products-loading-error'));
      }
    },
    async handleChangeSelectedOffers(selectedOffers) {
      if (!selectedOffers.length) {
        this.mongoQuery = this.productsMongoQuery;
        return;
      }

      let filtersForOffer = [];
      this.selectedOffers = selectedOffers;

      selectedOffers.forEach(async offer => {
        const offerFilter = COLLECTION_OFFER_MAP[this.reportCollection](offer);
        filtersForOffer.push(offerFilter);
      });

      this.offersMongoQuery = `{ "$or": ${JSON.stringify(filtersForOffer)} }`;
      this.mongoQuery = `{ "$and": [ ${this.productsMongoQuery}, ${this.offersMongoQuery} ] }`;
    },
    async handleChangeStartDate(date) {
      if (!date) return;
      const actualFilter = await this.chartRef.getFilter();
      const [year, month, day] = date.split('-');
      const finalDate = dayjs(`${year}/${month}/${Number(day)}`).startOf('date');

      const key = this.COLLECTION_DATE_MAP[this.reportCollection];

      this.selectedStartDate = new Date(finalDate.format());

      const newFilter = {
        ...actualFilter,
        [key]: {
          ...actualFilter[key],
          $gte: new Date(finalDate.format()),
        },
      };

      this.setFilter(newFilter);
    },
    async handleChangeEndDate(date) {
      if (!date) return;
      const actualFilter = await this.chartRef.getFilter();
      const [year, month, day] = date.split('-');
      const finalDate = dayjs(`${year}/${month}/${Number(day)}`).endOf('date');

      const key = this.COLLECTION_DATE_MAP[this.reportCollection];

      this.selectedEndDate = new Date(finalDate.format());

      const newFilter = {
        ...actualFilter,
        [key]: {
          ...actualFilter[key],
          $lte: new Date(finalDate.format()),
        },
      };

      this.setFilter(newFilter);
    },
  },
  watch: {
    mongoQuery() {
      const filter = this.mongoQuery ? JSON.parse(this.mongoQuery) : {};
      this.setFilter(filter);
    },
    async selectedProducts() {
      if (!this.selectedProducts.length) {
        this.offers = [];
        this.mongoQuery = '';
        return;
      }
      let newOffers = [];
      let filtersForProduct = [];

      this.selectedProducts.forEach(async product => {
        const { offers } = product;

        const formattedOffer = offers.map(o => ({ item: { text: o.title, id: o.id } }));

        newOffers = [...newOffers, ...formattedOffer];

        const productFilter = COLLECTION_PRODUCT_MAP[this.reportCollection](product);
        filtersForProduct.push(productFilter);
      });

      this.offers = newOffers;
      this.productsMongoQuery = `{ "$or": ${JSON.stringify(filtersForProduct)} }`;
      this.mongoQuery = this.productsMongoQuery;
    },
  },
  beforeDestroy() {
    this.stopRequestPolling();
  },
};
</script>

<style lang="scss" scoped>
.header {
  display: flex;
  flex-direction: column;
  background-color: white;

  @media (min-width: $screen-md) {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
}

.sales-made-report__container {
  padding: 24px 16px;

  @media (min-width: $screen-md) {
    padding: 24px 32px;
  }
}

.sales-made-report__iframe-metabase {
  background-color: $white;
  min-height: 500px;
  width: 100%;
}

.sales-made-report__report-container {
  height: calc(100vh - 212px);

  > div {
    height: 100%;
  }
}

.filters {
  padding: 16px;
  display: grid;
  grid-template-columns: 1fr;
  gap: 16px;

  @media (min-width: $screen-md) {
    padding: 32px;
    grid-template-columns: 400px 1fr 1fr;
  }

  .form-group {
    margin-bottom: 0;
  }

  .date {
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 100%;

    > span {
      font-weight: bold;
    }
  }
  .dateFilter {
    width: 100%;
    height: 41px !important;
    background-color: white;
    font-size: 14px;
    color: #bababa;
    font-weight: 400;
    border-radius: 4px;
    border: 2px solid #bababa;

    padding: 8px;
    gap: 5px;

    /deep/ label,
    /deep/ .btn {
      padding: 0;
    }
  }
}

/deep/ .modal-content {
  min-height: 500px;
  min-width: 800px;
  padding: 10px;

  .modal-body {
    padding: 0;

    > .chartById {
      height: 500px;
    }
  }
}

.modal-buttons {
  display: flex;
  height: auto;
  justify-content: center;
  padding: 12px;
  padding-top: 0;
}

.multiselect-filter {
  display: flex;
  flex-direction: column;
  gap: 8px;

  > span {
    font-weight: bold;
  }
  .hs-multiselect {
    /deep/.multiselect__tags {
      border: 2px solid #bababa;
      border-radius: 4px;
      line-height: unset;
      display: flex;
      align-items: center;
      .multiselect__placeholder {
        color: #bababa;
        font-size: 14px;
      }
    }
  }
}

.report-header-actions {
  display: grid;
  grid-template-columns: 1fr;
  padding: 16px;
  align-items: center;
  justify-content: center;
  gap: 16px;

  @media (min-width: $screen-md) {
    grid-template-columns: 160px 185px;
    padding: 32px;
  }
}

.date-filters-wrapper {
  display: flex;
  column-gap: 24px;
  align-items: center;
}

.exports-list {
  display: flex;
  flex-direction: column;
  max-height: 90px;
  overflow-y: auto;
}

.exports-list-waiting {
  margin: 0;
  font-size: 0.75rem;
  color: #bababa;
}

.exports-list-done {
  margin: 0;
  font-size: 0.75rem;
  color: #7427f1;
  text-decoration: underline;

  &:hover {
    color: #3a088a;
  }
}

.exports-list-title {
  margin: 0;
  font-size: 0.75rem;
  color: #262626;
  font-weight: bold;
}

.mongo-report-export-button {
  width: 100%;

  @media (min-width: $screen-md) {
    width: 185px;
  }
}
</style>
