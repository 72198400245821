<template>
  <div>
    <MongoReport
      dashboard-id="4cc64e99-e91e-45b0-a725-9a2c4cc89892"
      :translate-key="translateKey"
      :disable-report-download="false"
      report-collection="purchase"
      export-type="order_bump"
    />

    <div class="order-bump-sales-made-report__container">
      <AutoPilotBanner
        :title="$t(`${translateKey}.banner.title`)"
        :description="$t(`${translateKey}.banner.description`)"
        imageName="coin-upgrade.svg"
        selected-model="upsell"
      />
    </div>
  </div>
</template>

<script>
import AutoPilotBanner from '@/components/AutoPilotBanner.vue';
import MongoReport from './components/MongoReport.vue';

export default {
  name: 'OrderBumpSalesMadeReport',
  components: {
    AutoPilotBanner,
    MongoReport,
  },
  data() {
    return {
      translateKey: 'menu.sparkpay.sales.order_bump_sales_made_report',
    };
  },
};
</script>

<style lang="scss" scoped>
.order-bump-sales-made-report__container {
  padding: 24px 16px;

  @media (min-width: $screen-md) {
    padding: 24px 32px;
  }
}
</style>
