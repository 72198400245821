import i18nHelper from '@/shared/helpers/i18n';

const $t = i18nHelper.$t;

export default {
  data() {
    return {
      statusTypes: {
        unitary: {
          paid: { key: $t('status.payment.paid'), variant: 'success' },
          refused: { key: $t('status.payment.refused'), variant: 'danger-outline' },
          waiting_payment: { key: $t('status.payment.waitingPayment'), variant: 'warning-outline' },
          overdue: { key: $t('status.payment.overdue'), variant: 'disabled' },
          refund_analyzing: { key: $t('status.payment.refundAnalyzing'), variant: 'primary-outline' },
          chargeback: { key: $t('status.payment.chargeback'), variant: 'primary' },
          refunded: { key: $t('status.payment.refunded'), variant: 'success-outline' },
          error: { key: $t('status.payment.error'), variant: 'danger-outline' },
        },
        recurrency: {
          initiated: { key: $t('status.recurrency.initiated'), variant: 'info-outline' },
          active: { key: $t('status.recurrency.active'), variant: 'success' },
          past_due: { key: $t('status.recurrency.pastDue'), variant: 'warning' },
          cancelled: { key: $t('status.recurrency.cancelled'), variant: 'danger' },
          inactive: { key: $t('status.recurrency.inactive'), variant: 'disabled' },
          ended: { key: $t('status.recurrency.ended'), variant: 'disabled-outline' },
        },
        smartInstallment: {
          active: { key: $t('status.payment.active'), variant: 'success' },
          past_due: { key: $t('status.payment.pastDue'), variant: 'warning' },
          ended: { key: $t('status.payment.ended'), variant: 'disabled-outline' },
          initiated: { key: $t('status.payment.initiated'), variant: 'info-outline' },
        },
        installments: {
          initiated: { key: $t('status.payment.initiated'), variant: 'info-outline' },
          active: { key: $t('status.payment.active'), variant: 'success' },
          past_due: { key: $t('status.payment.pastDue'), variant: 'warning' },
          cancelled: { key: $t('status.payment.cancelled'), variant: 'danger' },
          ended: { key: $t('status.payment.ended'), variant: 'success-outline' },
          inactive: { key: $t('status.payment.inactive'), variant: 'disabled' },
        },
        installment: {
          paid: { key: $t('status.payment.paid'), variant: 'success' },
          refused: { key: $t('status.payment.refused'), variant: 'danger-outline' },
          cancelled: { key: $t('status.payment.cancelled'), variant: 'danger' },
          waiting_payment: { key: $t('status.payment.waitingPayment'), variant: 'warning-outline' },
          scheduled: { key: $t('status.payment.scheduled'), variant: 'info' },
          overdue: { key: $t('status.payment.overdue'), variant: 'disabled' },
          refund_analyzing: { key: $t('status.payment.refundAnalyzing'), variant: 'primary-outline' },
          chargeback: { key: $t('status.payment.chargeback'), variant: 'primary' },
          refunded: { key: $t('status.payment.refunded'), variant: 'success-outline' },
          error: { key: $t('status.payment.error'), variant: 'danger-outline' },
        },
      },
    };
  },
  methods: {
    getStatusLabel(type, status) {
      return this.statusTypes[type][status]?.key || $t('status.unknown');
    },
    getStatusVariant(type, status) {
      return this.statusTypes[type][status]?.variant || 'disabled';
    },
    getStatus(type, status) {
      return [this.getStatusLabel(type, status), this.getStatusVariant(type, status)];
    },
    getRecurrencyCancelledStatuses() {
      return ['canceled', 'subscription_canceled', 'cancelled', 'inactive', 'ended', 'initiated'];
    },
  },
};
