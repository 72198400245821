<template>
  <section class="WalletBankAccount">
    <h3 v-if="bankAccount">Conta bancária</h3>
    <p v-if="bankAccount">As contas bancárias abaixo são as que você poderá selecionar no momento do saque.</p>

    <div v-if="!bankAccount && !isLoading" class="empty-state">
      <div class="empty-state">
        <img src="@/assets/images/check-list.svg" />
        <h4>Você ainda não cadastrou nenhuma conta bancária</h4>
        <p>Para realizar o seu primeiro saque cadastre a sua conta bancária.</p>
      </div>
      <MButton
        variant="primary"
        class="WalletBankAccount__button"
        icon="wallet"
        label="Adicionar uma conta"
        @click="$router.push({ name: 'MyAccountSettings' })"
      />
    </div>

    <ul v-else-if="isLoading" class="WalletBankAccount__list">
      <li class="WalletBankAccount__list-item">
        <div>
          <hs-placeholder animated width="130px" height="20px" />
          <hs-placeholder animated width="40px" height="20px" />
        </div>
      </li>
    </ul>

    <ul v-else class="WalletBankAccount__list">
      <li class="WalletBankAccount__list-item">
        <div class="left">
          <p>
            <span>{{ bankCode }}</span> - {{ $t(`sparkpay.wallet.bank-account.type.${bankAccountType}`) }}
          </p>
          <p class="text-nowrap">{{ bankAccountInfo }}</p>
        </div>
      </li>
      <!-- 
      == FOR THE FUTURE IMPLEMENTATION  ==
      == All comments is for the future ==

      <li v-for="account in bankAccounts" :key="account.id" class="WalletBankAccount__list-item">
        <div class="left">
          <p>Conta corrente</p>
          <p>** 55</p>
        </div>
        <div v-if="isDefault(account)" class="right selected">
          <hs-icon variant="light" icon="check" />
          <input type="radio" :id="account.id" :value="account.id" v-model="picked" />
          <label :for="account.id">Conta Padrão</label>
        </div>
        <div v-else class="right">
          <input type="radio" :id="account.id" :value="account.id" v-model="picked" />
          <label :for="account.id">Selecionar como padrão</label>
        </div>
      </li> 
-->
    </ul>
  </section>
</template>

<script>
import { mapState } from 'vuex';

import MButton from '@/shared/components/MButton.vue';

import { GetBankMixin } from '@/sparkpay/mixins/GetBankMixin';

export default {
  name: 'WalletBankAccount',
  mixins: [GetBankMixin],
  components: {
    MButton,
  },
  data() {
    return {
      picked: 1,
      showBalance: true,
      isLoading: false,
      gateway: {},
      bank: null,
      bankAccount: null,
    };
  },
  watch: {
    // picked(newValue, oldValue){
    // handle picked change
    // }
  },
  computed: {
    ...mapState('school', ['selectedSchool']),
  },
  methods: {
    // isDefault(account) {
    //   return account.id === this.picked;
    // },
  },
};
</script>

<style scoped lang="scss">
.WalletBankAccount {
  h3 {
    font-size: 16px;
    font-weight: bold;
  }

  p {
    margin-bottom: 20px;
  }

  &__list {
    list-style: none;
    padding: 0;
  }

  &__list-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: $white;
    border-radius: 4px;
    padding: 16px 24px;
    margin-bottom: 8px;

    & > div {
      display: flex;
      align-items: center;
      gap: 20px;
    }

    p {
      margin: 0;
    }

    label {
      margin: 0;
      text-decoration: underline;
      color: $purple-dark;
      font-weight: bold;
      font-size: 14px;
      cursor: pointer;
    }

    input {
      display: none;
    }

    .selected,
    .selected label {
      color: #348535;
    }

    .right {
      gap: 8px;
    }
  }
}

@media screen and (max-width: 767px) {
  .WalletBankAccount {
    &__button {
      width: 100%;
    }

    &__list-item {
      gap: 16px;
      flex-direction: column;

      .left {
        width: 100%;
        justify-content: space-between;
      }

      .right {
        align-self: flex-end;
      }
    }
  }
}

.empty-state {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h4 {
    font-size: 16px;
    font-weight: bold;
  }

  p {
    font-size: 14px;
  }
}
</style>
