<template>
  <div class="installment-table tlw-flex tlw-flex-col tlw-justify-between tlw-mx-8">
    <h3 class="installment-table__title">
      {{ $t('sparkpay.sales.details.installment-table.title') }}
    </h3>
    <hs-table
      class="payment-table"
      ref="salesTable"
      hover
      responsive
      selectable
      select-mode="single"
      :fields="fields"
      :items="formattedSales"
      @selectedItems="payment => setSelectedPayment(payment[0])"
    >
      <template slot="due_at" slot-scope="row">
        {{ formatDate(row.item.due_at) }}
      </template>

      <template slot="confirmated" slot-scope="row">
        {{ formatDate(row.item.paid_at) }}
      </template>

      <template slot="amount" slot-scope="row">
        {{ totalReceivedText(row.item) }}
      </template>

      <template slot="status" slot-scope="row">
        <Tag
          :label="getStatusLabel('installment', row.item.status)"
          :variant="getStatusVariant('installment', row.item.status)"
        />
      </template>

      <template slot="action" slot-scope="row">
        <div class="payments-table__actions-wrapper">
          <RecoveryPaymentButton
            v-if="row.item.payment_method === PaymentMethodsList.CREDIT_CARD"
            :payment-id="row.item.id"
            :payment-status="row.item.status"
            :recovery-count="row.item.recovery_counter"
            :sale="row.item"
            :subscription-status="subscription.status"
            @fetch-orders="$emit('fetch-orders')"
          />
          <BolixPaymentActions
            v-else-if="displayBolixActions(row.item, row.index)"
            :sale="row.item"
            :is-first-payment-paid="isFirstPaymentPaid"
            @fetch-orders="$emit('fetch-orders')"
          />
          <span v-else />

          <hs-icon v-if="isPaidOrRefunded(row.item.status)" icon="chevron-right" />
        </div>
      </template>
    </hs-table>

    <div
      v-for="(sale, saleIndex) in formattedSales"
      :key="sale.id"
      tabindex="0"
      data-testid="installment-card"
      class="installment-card"
      @click="setSelectedPayment(sale)"
    >
      <div class="installment-card__header">
        <div class="installment-card__header-icon">
          <hs-icon class="icon" :size="21" icon="calendar-alt" />
        </div>
        <div>
          <p>Data da Cobrança {{ formatDate(sale.due_at) }}</p>
        </div>
      </div>
      <div class="installment-card__content">
        <p>{{ totalReceivedText(sale) }}</p>
        <Tag
          :label="getStatusLabel('installment', sale.status)"
          :variant="getStatusVariant('installment', sale.status)"
        />
      </div>
      <div class="installment-card__footer">
        <RecoveryPaymentButton
          v-if="sale.payment_method === PaymentMethodsList.CREDIT_CARD"
          :payment-id="sale.id"
          :payment-status="sale.status"
          :recovery-count="sale.recovery_counter"
          :subscription-status="subscription.status"
          :sale="sale"
          @fetch-orders="$emit('fetch-orders')"
        />
        <BolixPaymentActions
          v-else-if="displayBolixActions(sale, saleIndex)"
          :sale="sale"
          :is-first-payment-paid="isFirstPaymentPaid"
          @fetch-orders="$emit('fetch-orders')"
        />
      </div>
    </div>

    <InstallmentsPaymentDrawer
      v-if="selectedPayment"
      :payment="selectedPayment"
      :subscription="subscription"
      @close="selectedPayment = null"
    />
  </div>
</template>

<script>
import dayjs from 'dayjs';
import GeneralHelper from '@/shared/helpers/general';
import PaymentSummaryMixin from '@/sparkpay/mixins/PaymentSummaryMixin.js';
import SalesDetailsMixin from '@/sparkpay/mixins/SalesDetailsMixin.js';
import RecoveryPaymentButton from './RecoveryPaymentButton.vue';
import { PaymentMethodsList, PaymentStatusList } from '@/types/payments';
import BolixPaymentActions from '@/sparkpay/views/Sales/components/BolixPaymentActions.vue';
import StatusMixin from '@/mixins/StatusMixin';
import Tag from '@/components/MTag.vue';
import InstallmentsPaymentDrawer from './InstallmentsPaymentDrawer.vue';

export default {
  name: 'PaymentsTable',
  mixins: [PaymentSummaryMixin, SalesDetailsMixin, StatusMixin],
  components: {
    RecoveryPaymentButton,
    BolixPaymentActions,
    InstallmentsPaymentDrawer,
    Tag,
  },
  props: {
    sales: {
      required: true,
      type: Array,
    },
    subscription: {},
  },
  data() {
    return {
      PaymentMethodsList,
      selectedPayment: null,
      fields: [
        {
          key: 'due_at',
          label: 'Cobrança',
          sortable: true,
        },
        { key: 'confirmated', label: this.$t('sparkpay.sales.sales-table.fields.confirmed'), sortable: true },
        { key: 'amount', label: this.$t('sparkpay.sales.sales-table.fields.total'), sortable: true },
        { key: 'status', label: this.$t('sparkpay.sales.sales-table.fields.status'), sortable: true },
        { key: 'action', label: '' },
      ],
    };
  },
  methods: {
    isPaidOrRefunded(status) {
      return [PaymentStatusList.PAID, PaymentStatusList.REFUNDED].includes(status);
    },
    setSelectedPayment(payment) {
      if (!payment) {
        this.selectedPayment = null;
        return;
      }

      const { payment_method, status } = payment;

      if (payment_method === PaymentMethodsList.BANK_SLIP) {
        this.selectedPayment = status === PaymentStatusList.REFUNDED ? payment : null;
        return;
      }

      const isCardOrPix = [PaymentMethodsList.CREDIT_CARD, PaymentMethodsList.PIX].includes(payment_method);
      const isPaidOrRefunded = this.isPaidOrRefunded(status);

      if (isCardOrPix && isPaidOrRefunded) {
        this.selectedPayment = payment;
        return;
      }

      this.selectedPayment = null;
    },
    formatDate(date) {
      return date ? dayjs(date).format('DD/MM/YYYY') : '—';
    },
    totalReceived(subscription) {
      return this.netValueByParticipation(subscription);
    },
    totalReceivedText(subscription) {
      return GeneralHelper.currency(this.totalReceived(subscription) / 100);
    },
    formatBadgeText(sale) {
      const { status, recovery_counter } = sale;
      const translateKey = 'sparkpay.sales.smart-installments-table.status';

      if (status === 'waiting_payment' && recovery_counter > 0) {
        return this.$t(`${translateKey}.waiting_payment_recovery`);
      }

      return this.$t(`${translateKey}.${sale.status}`);
    },
    formatBadgeStatus(sale) {
      const { status, recovery_counter } = sale;

      if (status === 'waiting_payment' && recovery_counter > 0) {
        return 'waiting_payment_recovery';
      }

      return status;
    },
  },
  computed: {
    formattedSales() {
      let found = false;

      return this.sales.map(sale => {
        if (sale.status === 'waiting_payment') {
          if (!found) {
            found = true;
            return sale;
          } else {
            return { ...sale, status: 'scheduled' };
          }
        } else {
          return sale;
        }
      });
    },
  },
};
</script>

<style lang="scss">
.installment-table {
  margin: 0.5rem 1rem;

  &__title {
    margin-bottom: 1rem;
    font-size: 1.25rem;
  }
}
@media screen and (min-width: 1024px) {
  .installment-table {
    margin: 1.5rem 2rem;
  }
}
@media screen and (max-width: 1024px) {
  .installment-table {
    gap: 16px;
  }
}
.payment-table {
  display: none;

  @media (min-width: $screen-md) {
    display: block;

    .table {
      thead {
        tr {
          height: 37px;
        }
      }

      tr {
        height: 56px;
      }

      td {
        padding: 8px 32px 8px 16px;
      }
    }
  }
}

.installment-card {
  background: white;
  padding: 16px;
  border-radius: 4px;
  box-shadow: 0px 4px 8px rgba(63, 63, 63, 0.08);
  display: block;

  &__header {
    display: flex;
    gap: 8px;
    padding-bottom: 16px;
    border-bottom: 1px solid #cfcfcf;
    &-icon {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #ead3fe;
      color: $grey-32;
    }

    p {
      margin-bottom: 0;
      color: $grey;
      font-size: 0.875rem;
      line-height: 21px;
    }
  }

  &__content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 1rem;

    p {
      font-weight: 600;
      font-size: 1rem;
      color: $grey;
      margin-bottom: 0;
    }
  }

  &__footer {
    width: 100%;
    margin-top: 1rem;
  }

  @media (min-width: $screen-md) {
    display: none;
  }
}

.payments-table__actions-wrapper {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}
</style>
