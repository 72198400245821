<template>
  <div class="tlw-p-8">
    <Loading v-if="isLoading" />
    <div v-else-if="items.length">
      <Banner
        title="Dicas sobre os logs"
        link="https://ajuda.herospark.com/hc/pt-br/articles/28939194329101-Como-utilizar-o-Hist%C3%B3rico-de-automa%C3%A7%C3%B5es-webhooks"
        linkText="Consulte nossa FAQ para compreender e esclarecer suas dúvidas sobre os logs de automação."
      />

      <b-table class="tlw-mt-6" striped responsive :fields="fields" :items="items" small bordered>
        <template #cell(url)="data">
          <div class="text">
            {{ data.item.url }}
          </div>
        </template>
        <template #cell(status)="data">
          <div class="AutomationBadgeContainer">
            <div
              class="AutomationBadge"
              :class="getBadgeClass(data.item.status)"
              v-b-tooltip.hover.bottom="getBadgeTooltipText(data.item.status)"
            >
              {{ getBadgeStatus(data.item.status) }}
            </div>
            <div v-if="data.item.test" class="AutomationBadge test">
              Teste
            </div>
          </div>
        </template>

        <template #cell(response)="data">
          <div class="AutomationResponse">
            {{ data.item.response }}
          </div>
        </template>

        <template #cell(body)="data">
          <div class="AutomationBody">
            <p class="text">{{ data.item.body }}</p>
            <button
              v-if="data.item.requestData !== null"
              v-b-tooltip.hover.left="`Copiar requisição enviada`"
              @click="copyToClipboard(data.item.requestData)"
            >
              <hs-icon icon="copy" />
            </button>
          </div>
        </template>
      </b-table>

      <hs-pagination
        v-if="totalItems > 30"
        v-model="currentPage"
        :per-page="30"
        :total-rows="totalItems"
        align="center"
      />
    </div>
    <div class="empty" v-else>
      <img src="@/assets/images/robot-error.svg" />
      <h3>Nenhum Resultado de Log Encontrado</h3>
      <p>
        Ainda não há registros de execução para esta automação. Assim que a automação for disparada pela primeira vez,
        você poderá visualizar os resultados e detalhes dos logs aqui.
      </p>
    </div>
  </div>
</template>

<script>
import ToastHelper from '@/shared/helpers/toast';
import { getLogs } from '@/services/jarvis';
import dayjs from 'dayjs';
import Loading from '@/components/Loading.vue';
import Banner from '@/components/Banner.vue';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

const STATUS_CONVERTER = {
  initiated: {
    badge: 'initiated',
    title: 'Iniciado',
    tooltip: 'Aguardando para ser processado.',
  },
  processing: {
    badge: 'info',
    title: 'Processando',
    tooltip: 'Ação em processamento.',
  },
  completed_successfully: {
    badge: 'success',
    title: 'Executado',
    tooltip: 'O envio foi feito corretamente.',
  },
  finished_with_error: {
    badge: 'error',
    title: 'Erro',
    tooltip: 'Houve um erro no envio e ele não foi finalizado.',
  },
  not_performable: {
    badge: 'warning',
    title: 'Filtrado',
    tooltip: 'A ação não foi concluída porque não atende aos critérios do filtro aplicado.',
  },
};

export default {
  components: {
    Loading,
    Banner,
  },
  data() {
    return {
      isLoading: true,
      currentPage: 1,
      totalPages: 1,
      fields: [
        { key: 'created_at', label: 'Criados em' },
        { key: 'url', label: 'URL' },
        { key: 'status', label: 'Status' },
        { key: 'response', label: 'Resposta' },
        { key: 'body', label: 'Body da resposta' },
      ],
      items: [],
      totalItems: 0,
    };
  },
  async mounted() {
    await this.getLogs(this.currentPage);
  },
  watch: {
    currentPage(newPage, oldPage) {
      if (newPage != oldPage && newPage <= this.totalPages) {
        this.getLogs(newPage);
      }
    },
  },
  methods: {
    async getLogs(page) {
      const { automationId } = this.$route.params;
      try {
        this.isLoading = true;
        const response = await getLogs({
          page,
          hermes_action_id: automationId,
        });

        this.items = this.formatLogsResponse(response.data.logs);
        this.totalPages = response.data.total_pages;
        this.totalItems = response.data.total_count;
      } catch (error) {
        ToastHelper.dangerMessage('Erro ao carregar os logs');
      } finally {
        this.isLoading = false;
      }
    },
    formatLogsResponse(response) {
      return response.map(log => {
        const requestData = this.extractRequestData(log.request);
        const actionDetail = JSON.parse(log.action_detail || '{}');
        return {
          created_at: dayjs(log.created_at).format('DD/MM/YYYY [às] HH:mm'),
          url: requestData?.url || actionDetail?.url,
          status: log.status,
          response: JSON.parse(log.result || '{}')?.code || '--',
          body: JSON.parse(log.result || '{}')?.body || '--',
          requestData: requestData,
          test: log.test,
        };
      });
    },
    extractRequestData(request) {
      try {
        if (!request) return null;

        const parsedRequest = typeof request === 'string' ? JSON.parse(request) : request;

        if (typeof parsedRequest !== 'object' || parsedRequest === null) return null;

        const { body, headers, url } = parsedRequest;
        if (!body && !headers && !url) return null;

        return {
          body: body || {},
          headers: headers || {},
          url: url || null,
        };
      } catch (error) {
        console.error('Erro ao extrair dados do request:', error); // eslint-disable-line no-console
        return null;
      }
    },
    copyToClipboard(data) {
      if (!data) {
        ToastHelper.dangerMessage('Conteúdo inválido para cópia');
        return;
      }

      try {
        navigator.clipboard.writeText(JSON.stringify(data, null, 2));
        ToastHelper.successMessage('Requisição copiada com sucesso!');
      } catch (error) {
        console.error('Erro ao copiar para clipboard:', error); // eslint-disable-line no-console
        ToastHelper.dangerMessage('Não foi possível copiar o conteúdo');
      }
    },
    getBadgeClass(status) {
      return STATUS_CONVERTER[status].badge;
    },
    getBadgeStatus(status) {
      return STATUS_CONVERTER[status].title;
    },
    getBadgeTooltipText(status) {
      return STATUS_CONVERTER[status].tooltip;
    },
  },
};
</script>

<style lang="scss" scoped>
.empty {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  max-width: 440px;
  margin: 0 auto;
  color: #000;

  text-align: center;

  h3 {
    margin-top: 18px;
    font-size: 16px;
    font-weight: 700;
    line-height: 150%;
  }

  p {
    font-size: 14px;
    line-height: 150%;
    text-align: center;
  }
}

.text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: initial;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.AutomationBody {
  width: 100%;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  gap: 4px;

  p {
    margin: 0;
  }

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    padding: 4px;
    border-radius: 99px;

    &:hover {
      background-color: #ead3fe;
      cursor: pointer;
    }
  }
}
.AutomationResponse {
  text-align: center;
}

.AutomationBadgeContainer {
  display: flex;
  gap: 4px;
  flex-wrap: wrap;
}

.AutomationBadge {
  display: inline-flex;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 14px;

  text-wrap: nowrap;

  &.success {
    color: #112b11;
    background-color: #e3fab8;
  }

  &.info {
    color: #1e4278;
    background-color: #b3dafe;
  }

  &.error {
    color: #802121;
    background-color: #feb4b4;
  }

  &.warning {
    color: #bf8208;
    background-color: #ffeaa6;
  }

  &.initiated {
    background-color: #3575d4;
    color: #b3dafe;
  }

  &.test {
    background-color: #262626;
    color: #fff;
  }
}

/deep/ {
  .table {
    border-collapse: inherit;
    border-spacing: 0;
  }
  .table thead th {
    background-color: #e3e3e3;
    border-color: #cfcfcf;
    font-weight: bold;
    text-align: center;
    color: #262626;
    padding: 8px;
    font-size: 14px;
    text-transform: initial;
    text-wrap: nowrap;
  }

  .table-striped tbody tr:nth-of-type(odd) {
    background-color: #fff;
  }

  .table-striped tbody tr:nth-of-type(even) {
    background-color: #e3e3e3;
  }

  .table tbody tr {
    box-shadow: none;
  }

  .table tbody td {
    border-color: #cfcfcf;
    padding: 8px;
    max-width: 404px;
    word-break: break-all;

    &:first-child {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }

    &:last-child {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
  }
}
</style>
