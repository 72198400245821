






















import { Vue, Component, Prop } from 'vue-property-decorator';

@Component
export default class SalesReportCard extends Vue {
  translateKey = 'menu.sparkpay.sales.sales_report.cards';

  @Prop({ type: String }) title: string;
  @Prop({ type: String }) description: string;
  @Prop({ type: String }) icon: string;
  @Prop({ type: Boolean, default: false }) newFeature: boolean;
}
