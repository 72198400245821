<template>
  <NoSalesWarning v-if="showNoSalesWarning" />
  <div v-else>
    <Filters
      :filters="filters"
      data-testid="units-list-filter"
      class="subscriptions-list__filter"
      @changed="mountUnitsList(1)"
    />

    <hsLoading v-if="isLoading" class="flex-fill" />
    <div v-else class="subscriptions-list__items-container">
      <NoFilteredSalesWarning v-if="showNoFilteredSalesWarning" />
      <template v-else>
        <RecurrencySalesTable :sales="subscriptions" :fields="fields" class="desktop-only" />

        <RecurrencyCardList :sales-list="subscriptions" class="mobile-only" />
      </template>

      <div v-if="totalNumberOfSubscriptions > 10" class="tlw-flex flex-fill tlw-flex-col tlw-justify-end">
        <hs-pagination
          v-model="currentPage"
          :go-page-text="$t('sparkpay.sales.pagination.go-to-page')"
          :per-page="10"
          :total-rows="totalNumberOfSubscriptions"
          align="center"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { orderService } from '@/services';

import ToastHelper from '@/shared/helpers/toast';
import { hsLoading } from '@/components';
import NoSalesWarning from '../components/NoSalesWarning';
import NoFilteredSalesWarning from '../components/NoFilteredSalesWarning';
import RecurrencySalesTable from './RecurrencySalesTable';
import RecurrencyCardList from './RecurrencyCardList';
import StatusMixin from '@/mixins/StatusMixin';
import Filters from '@/components/Filters/index.vue';
import FilterMixin from '@/sparkpay/mixins/FilterMixin';

export default {
  mixins: [StatusMixin, FilterMixin],
  name: 'SubscriptionsList',
  data() {
    return {
      isLoading: false,
      filters: [
        {
          type: 'Main',
          key: 'full_name',
          label: this.$t('sparkpay.sales.filters.full-name.subscription-label'),
          value: '',
        },
        {
          type: 'MultiSelect',
          key: 'status',
          label: this.$t('sparkpay.sales.filters.status.label'),
          multiple: true,
          options: [],
          value: null,
        },
        {
          type: 'MultiSelect',
          key: 'type',
          label: this.$t('sparkpay.sales.filters.kind.label'),
          multiple: false,
          options: [
            {
              item: {
                label: this.$t('sparkpay.sales.filters.kind.options.credit_card.label'),
                value: 'credit_card',
              },
            },
            {
              item: {
                label: this.$t('sparkpay.sales.filters.kind.options.boleto.label'),
                value: 'bank_slip',
              },
            },
            {
              item: {
                label: this.$t('sparkpay.sales.filters.kind.options.pix.label'),
                value: 'pix',
              },
            },
          ],
          value: null,
        },
        {
          type: 'MultiSelect',
          key: 'product_id',
          label: this.$t('sparkpay.sales.filters.product.label'),
          multiple: false,
          options: [],
          value: null,
        },
        {
          type: 'Date',
          key: 'created_at_start',
          label: 'Data de criação inicial',
          value: null,
        },
        {
          type: 'Date',
          key: 'created_at_end',
          label: 'Data de criação final',
          value: null,
        },
      ],
      subscriptions: [],
      fields: [
        { key: 'creation_date', label: this.$t('sparkpay.sales.sales-table.fields.creation_date') },
        { key: 'product', label: this.$t('sparkpay.sales.sales-table.fields.product') },
        { key: 'name', label: this.$t('sparkpay.sales.sales-table.fields.client') },
        { key: 'status', label: this.$t('sparkpay.sales.sales-table.fields.status') },
        { key: 'recurring_value', label: this.$t('sparkpay.sales.sales-table.fields.recurring_value') },
      ],
      currentPage: 1,
      totalNumberOfSubscriptions: 0,
    };
  },
  watch: {
    currentPage(newValue) {
      this.mountUnitsList(newValue);
    },
  },
  components: {
    hsLoading,
    RecurrencySalesTable,
    NoSalesWarning,
    NoFilteredSalesWarning,
    RecurrencyCardList,
    Filters,
  },
  methods: {
    mountUnitsList(page) {
      this.isLoading = true;
      orderService
        .getRecurrencyOrders([
          { key: 'per_page', value: '10' },
          { key: 'page', value: page },
          ...this.parseFiltersToParams(),
        ])
        .then(res => {
          this.subscriptions = res.orders;
          if (this.subscriptions.length === 0) this.subscriptions = [];
          this.totalNumberOfSubscriptions = res.total_count;
        })
        .catch(() => ToastHelper.dangerMessage(this.$t('sparkpay.sales.mount-sales-list-error')))
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
  computed: {
    showNoFilteredSalesWarning: function() {
      return !this.isLoading && !this.subscriptions.length && this.filters.some(f => f.value);
    },
    showNoSalesWarning: function() {
      return !this.isLoading && !this.subscriptions.length && !this.filters.some(f => f.value);
    },
  },
  created() {
    this.mountUnitsList(1);
    this.loadProductsOptions();
  },
  mounted() {
    const statusInstallmentTypes = Object.entries(this.statusTypes.recurrency).map(([key, value]) => {
      return {
        item: {
          label: value.key,
          value: key,
        },
      };
    });

    this.filters.find(filter => filter.key === 'status').options = statusInstallmentTypes;
  },
};
</script>

<style lang="scss" scoped>
.subscriptions-list__items-container {
  padding: 0 16px 32px;

  @media (min-width: $screen-md) {
    padding: 0 32px 32px;
  }
}

.mobile-only {
  width: 100%;

  @media (min-width: $screen-md) {
    display: none;
  }
}

.desktop-only {
  display: none;

  @media (min-width: $screen-md) {
    display: block;
    width: 100%;
  }
}

.subscriptions-list__filter {
  padding: 24px 16px 0;

  @media (min-width: $screen-md) {
    padding: 24px 32px 0;
  }
}
</style>
