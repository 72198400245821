<template>
  <div class="reprocess-payment-button">
    <span v-if="displayRecoveryButton" v-b-tooltip.hover.top="buttonTooltip">
      <MButton
        data-testid="reprocess-payment-button"
        :disabled="!canReprocess || this.isLoading"
        variant="primary-outline"
        icon="redo"
        label="Recobrar pagamento"
        class="reprocess-payment-button__button"
        @click="recoverPayment()"
      >
        <i
          class="fal fa-redo reprocess-payment-button__redo-icon"
          :class="{ 'reprocess-payment-button__redo-icon--loading': isLoading }"
        />

        {{ $t('recovery-payment-button.button-label') }}
      </MButton>
    </span>
    <div
      v-else-if="isOverduePayment"
      class="reprocess-payment-button__failed-attempts"
      data-testid="failed-attempts-message"
      v-b-tooltip.hover.top="buttonTooltip"
    >
      <i class="fal fa-info-circle" />

      {{ $t('recovery-payment-button.refused-label') }}
    </div>
  </div>
</template>

<script>
import MButton from '@/shared/components/MButton.vue';
import paymentsService from '@/services/sparkecommerce/payments';
import ToastHelper from '@/shared/helpers/toast';

const MAX_RECOVERY_ATTEMPTS = 3;

export default {
  name: 'RecoveryPaymentButton',
  components: { MButton },
  props: {
    paymentId: {
      type: Number,
      required: true,
    },
    recoveryCount: {
      type: Number,
      required: true,
    },
    paymentStatus: {
      type: String,
      required: true,
    },
    sale: {
      type: Object,
      required: true,
    },
    subscriptionStatus: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      isLoading: false,
    };
  },
  methods: {
    async recoverPayment() {
      try {
        this.isLoading = true;

        await paymentsService.reprocess(this.paymentId);
        ToastHelper.successMessage(this.$t('recovery-payment-button.success-message'));
      } catch (error) {
        console.error('RecoveryPaymentButton', error); // eslint-disable-line no-console
        ToastHelper.dangerMessage(this.$t('recovery-payment-button.error-message'));
      } finally {
        this.$emit('fetch-orders');
        this.isLoading = false;
      }
    },
  },
  computed: {
    buttonTooltip() {
      return this.$t(`recovery-payment-button.attempts.${this.buttonStatus}.${this.attemptsLeft}`);
    },
    attemptsLeft() {
      return MAX_RECOVERY_ATTEMPTS - this.recoveryCount;
    },
    buttonStatus() {
      return this.canReprocess ? 'enabled' : 'disabled';
    },
    canReprocess() {
      return this.isOverduePayment && this.attemptsLeft && this.sale.enabledReprocess;
    },
    isOverduePayment() {
      const subscriptionStatuses = ['past_due'];
      const paymentStatuses = ['refused', 'error', 'overdue'];

      const isAPaymentReprocessedAndWaitingPayment = this.sale.status === 'waiting_payment' && this.sale.reprocessed;

      return (
        subscriptionStatuses.includes(this.subscriptionStatus) &&
        (isAPaymentReprocessedAndWaitingPayment || paymentStatuses.includes(this.sale.status))
      );
    },
    isRecoveredPayment() {
      return this.recoveryCount > 0;
    },
    isReprocessing() {
      return this.paymentStatus === 'waiting_payment' && this.isRecoveredPayment;
    },
    displayRecoveryButton() {
      return this.canReprocess || this.isReprocessing;
    },
  },
};
</script>

<style lang="scss">
.reprocess-payment-button {
  width: fit-content;
}

.reprocess-payment-button__button {
  width: 100%;

  @media (min-width: $screen-md) {
    width: fit-content;
  }
}

.reprocess-payment-button__failed-attempts {
  display: flex;
  column-gap: 8px;
  align-items: center;
  color: #db3939;
  font-size: 0.875rem;
  line-height: 1.313rem;
  font-weight: 700;
  justify-content: center;

  @media (min-width: $screen-md) {
    justify-content: flex-start;
  }
}

.reprocess-payment-button__redo-icon {
  margin-right: 8px;
}

.reprocess-payment-button__redo-icon--loading {
  animation: rotate 2s linear infinite;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>
