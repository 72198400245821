<template>
  <div class="invoice-report__content-container">
    <div class="invoice-report__instructions-card">
      <div class="invoice-report__instructions-content">
        <div class="invoice-report__instructions-content-title">
          {{ $t(`${translateKey}.steps.title`) }}
        </div>

        <div class="invoice-report__instructions-information">
          <div class="invoice-report__instructions-steps">
            <div class="invoice-report__instructions-step-item">
              <div class="invoice-report__instructions-step-bullet">
                1
              </div>

              <div class="invoice-report__instructions-step-text">
                {{ $t(`${translateKey}.steps.export_report_step`) }}

                <i class="fas fa-cloud-download invoice-report__cloud-icon" />
              </div>
            </div>

            <div class="invoice-report__instructions-step-item">
              <div class="invoice-report__instructions-step-bullet">
                2
              </div>

              <div class="invoice-report__instructions-step-text">
                {{ $t(`${translateKey}.steps.access_account_step`) }}
              </div>
            </div>

            <div class="invoice-report__instructions-step-item">
              <div class="invoice-report__instructions-step-bullet">
                3
              </div>

              <div class="invoice-report__instructions-step-text">
                {{ $t(`${translateKey}.steps.import_step`) }}
              </div>
            </div>
          </div>

          <div class="invoice-report__divider" />

          <div class="invoice-report__instructions-config">
            <div class="invoice-report__instructions-config-text">
              {{ $t(`${translateKey}.config-description`) }}
            </div>

            <hs-button
              variant="purple"
              class="invoice-report__instructions-config-button"
              @click="openInvoiceConfigSidebar"
            >
              {{ $t(`${translateKey}.config-button`) }}
            </hs-button>
          </div>
        </div>
      </div>

      <img
        :src="require('@/assets/images/SalesReport/virtual-paper-purple.svg')"
        alt="pedaço de papel"
        width="187"
        height="187"
        class="invoice-report__instructions-image"
      />
    </div>

    <InvoiceConfigSidebar v-if="isSidebarInvoiceOpened" @close="() => setSidebarInvoiceEnabled(false)" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import InvoiceConfigSidebar from '@/sparkpay/views/Sales/Reports/InvoiceConfigSidebar.vue';

export default {
  name: 'InvoiceConfigBanner',
  components: {
    InvoiceConfigSidebar,
  },
  data() {
    return {
      translateKey: 'menu.sparkpay.sales.invoice_report',
    };
  },
  computed: {
    ...mapGetters('sale', ['isSidebarInvoiceOpened']),
  },
  methods: {
    ...mapActions('sale', ['setSidebarInvoiceEnabled']),
    openInvoiceConfigSidebar() {
      this.setSidebarInvoiceEnabled(true);
    },
  },
};
</script>

<style lang="scss">
.invoice-report__content-container {
  padding: 24px 16px;
  @media (min-width: $screen-md) {
    padding: 24px 32px;
  }
}

.invoice-report__instructions-card {
  background-color: #fff;
  padding: 24px 16px;
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  @media (min-width: $screen-md) {
    padding: 32px;
  }
}

.invoice-report__instructions-content {
  max-width: 738px;
}

.invoice-report__instructions-content-title {
  color: $grey;
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 600;
  @media (min-width: $screen-md) {
    font-size: 1.25rem;
    line-height: 1.563rem;
  }
}

.invoice-report__instructions-image {
  display: none;
  @media (min-width: 1230px) {
    display: block;
  }
}

.invoice-report__instructions-information {
  display: grid;
  grid-template-columns: 1fr;
  column-gap: 32px;
  margin-top: 32px;
  @media (min-width: $screen-md) {
    grid-template-columns: 450px 1px 223px;
  }
}

.invoice-report__instructions-steps {
  display: flex;
  flex-direction: column;
}

.invoice-report__instructions-step-item {
  display: grid;
  grid-template-columns: 28px auto;
  column-gap: 8px;
  align-items: center;
  &:not(:first-child) {
    margin-top: 16px;
  }
}

.invoice-report__instructions-step-bullet {
  width: 28px;
  height: 28px;
  border-radius: 50%;
  background-color: #ead3fe;
  border: 2px solid #35126e;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #35126e;
}

.invoice-report__instructions-step-text {
  font-size: 0.875rem;
  line-height: 1.313rem;
  color: #35126e;
  display: flex;
}

.invoice-report__cloud-icon {
  margin-left: 8px;
  display: flex;
  align-items: center;
}

.invoice-report__divider {
  height: 1px;
  margin: 16px 0;
  background-color: #cfcfcf;
  @media (min-width: $screen-md) {
    height: auto;
    margin: 0;
  }
}

.invoice-report__instructions-config-text {
  font-size: 0.875rem;
  line-height: 1.313rem;
  color: #6f6f6f;
}

.invoice-report__instructions-config-button {
  margin-top: 24px;
}

.invoice-report__instructions-config-button {
  width: fit-content;
  padding: 8px 16px;
  background-color: $purple-dark;
  color: #fff;
  border-radius: 4px;
  font-size: 0.875rem;
  line-height: 1.313rem;
  font-weight: 700;
  &:hover {
    background-color: #4d1aa1;
  }
}
</style>
