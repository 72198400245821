<template>
  <div class="multiselect-filter">
    <span class="multiselect-filter__label" v-if="label">{{ label }}</span>
    <b-dropdown class="multiselect-filter__content" variant="outline-secondary">
      <template v-slot:button-content>
        {{ $t('components.filters.multi-select-filter.see-selected') }}
      </template>

      <b-dropdown-form @change="emitSelectedItems">
        <b-form-group v-if="options.length">
          <b-form-checkbox-group
            v-model="selected"
            value-field="item"
            text-field="item.text"
            stacked
            :options="options"
          />
        </b-form-group>
        <span v-else class="tlw-text-sm">Sem opções</span>
      </b-dropdown-form>
    </b-dropdown>
  </div>
</template>

<script>
export default {
  name: 'MultiselectReseasesReport',
  props: {
    options: {
      type: Array,
      default: () => [],
    },
    label: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      selected: [],
    };
  },
  methods: {
    emitSelectedItems(event) {
      event.preventDefault();
      this.$emit('change', this.selected);
    },
  },
};
</script>

<style lang="scss" scoped>
.multiselect-filter {
  display: flex;
  flex-direction: column;
  gap: 8px;

  &__label {
    font-size: 14px;
    color: #262626;
    font-weight: 600;
  }

  &__content {
    /deep/.btn {
      padding: 8px;
      background-color: white;
      font-size: 14px;
      color: #bababa;
      font-weight: 400;
      border-radius: 4px;
      border: 2px solid #bababa;
      text-align: start;
    }

    /deep/.dropdown-menu {
      border-radius: 5px;
      padding: 16px;

      li .b-dropdown-form {
        padding: 0;
      }

      li fieldset {
        margin: 0;
      }

      .custom-control {
        .custom-control-input:checked ~ .custom-control-label:before {
          background-color: #2050b5;
          border-radius: 2px;
        }
        .custom-control-label {
          cursor: pointer;
          color: #262626;
          font-size: 14px;
        }
        & + .custom-control {
          margin-top: 16px;
        }
      }
    }
  }
}
</style>
