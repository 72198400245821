<template>
  <div>
    <MXButton
      class="download-refund-receipt"
      variant="secondary"
      icon="fal fa-external-link"
      @click="generatePDF"
      :disabled="isGeneratingPdf"
    >
      {{ pdfButtonText }}
    </MXButton>

    <div class="download-refund-receipt__template">
      <RefundReceiptTemplate
        v-show="showPdfTemplate"
        :buyer-name="buyerName"
        :payment-data="paymentData"
        ref="pdfTemplate"
      />
    </div>
  </div>
</template>

<script>
import MXButton from '@/shared/components/MXButton.vue';
import RefundReceiptTemplate from './RefundReceiptTemplate.vue';
import ToastHelper from '@/shared/helpers/toast';
import html2pdf from 'html2pdf.js';

export default {
  name: 'DownloadRefundReceiptButton',
  components: {
    MXButton,
    RefundReceiptTemplate,
  },
  props: {
    buyerName: {
      type: String,
      required: true,
    },
    paymentData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isGeneratingPdf: false,
      showPdfTemplate: false,
    };
  },
  computed: {
    pdfButtonText() {
      if (this.isGeneratingPdf) return 'Gerando PDF...';

      return 'Comprovante de estorno';
    },
  },
  methods: {
    async generatePDF() {
      if (this.isGeneratingPdf) return;

      this.isGeneratingPdf = true;
      this.showPdfTemplate = true;

      try {
        await this.$nextTick();

        const element = this.$refs.pdfTemplate.$el;
        const options = {
          margin: [10, 10],
          filename: `comprovante-de-estorno-${this.paymentData.paymentId}.pdf`,
          image: { type: 'jpeg', quality: 0.98 },
          html2canvas: {
            scale: 2,
            useCORS: true,
            logging: false,
          },
          jsPDF: {
            unit: 'mm',
            format: 'a4',
            orientation: 'portrait',
          },
          pagebreak: { mode: 'avoid-all' },
        };

        await html2pdf()
          .set(options)
          .from(element)
          .save();
        ToastHelper.successMessage('Comprovante de estorno gerado com sucesso!');
      } catch (error) {
        ToastHelper.dangerMessage('Erro ao gerar comprovante de estorno');
      } finally {
        this.isGeneratingPdf = false;
        this.showPdfTemplate = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.download-refund-receipt {
  width: 100%;
  margin-top: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.download-refund-receipt__template {
  position: absolute;
  right: -1000px;
}
</style>
