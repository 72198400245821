<template>
  <div class="card-single-info">
    <hs-icon class="icon" :size="iconSize" :icon="icon" />

    <div class="card-single-info__content">
      <h3 class="title">{{ title }}</h3>
      <p class="subtitle">{{ subtitle }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SalesCardsSingleInfo',
  props: {
    icon: {
      type: String,
      required: false,
      default: 'piggy-bank',
    },
    iconSize: {
      type: String,
      required: false,
      default: '25',
    },
    title: {
      type: String,
    },
    subtitle: {
      type: String,
    },
  },
};
</script>

<style lang="scss">
.card-single-info {
  display: flex;
  padding: 16px 26px;
  gap: 18px;
  background-color: $white;
  border-radius: 4px;
  position: relative;
  overflow: hidden;
  width: 100%;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 4px;
    background-color: $purple-dark;
    z-index: 1;
  }

  &__content {
    .title {
      font-weight: 700;
      font-size: 1.25rem;
      color: $grey-32;
      margin-bottom: 4px;
    }

    .subtitle {
      font-size: 1rem;
      color: $color-neutral-600;
      margin: 0;
    }
  }
}
</style>
