<template>
  <hs-table
    :responsive="true"
    :fields="fields"
    :items="loading ? withdrawalsMock : withdrawals"
    :sort-by="'created_at'"
    :sort-desc="true"
  >
    <template slot="amount_cents" slot-scope="row">
      <strong v-if="row.item.amount_cents">{{ row.item.amount_cents }}</strong>
      <hs-placeholder animated width="100px" height="14px" v-else />
    </template>
    <template slot="created_at" slot-scope="row">
      <template v-if="row.item.created_at">
        {{ formartDate(row.item.created_at) }}
      </template>
      <hs-placeholder animated width="100px" height="14px" v-else />
    </template>
    <template slot="status" slot-scope="row">
      <hs-badge pill :variant="getBadgeVariant(row.item.status)" v-if="row.item.status">
        {{ $t(`sparkpay.wallet.withdrawals.withdrawals-table.status.${row.item.status}`) }}
      </hs-badge>
      <hs-placeholder animated width="100px" height="14px" v-else />
    </template>
  </hs-table>
</template>

<script>
import dayjs from 'dayjs';

export default {
  props: {
    withdrawals: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      fields: [
        {
          key: 'amount_cents',
          label: this.$t('sparkpay.wallet.withdrawals.withdrawals-table.fields.amount'),
          sortable: true,
        },
        {
          key: 'created_at',
          label: this.$t('sparkpay.wallet.withdrawals.withdrawals-table.fields.created_at'),
          sortable: true,
        },
        {
          key: 'status',
          label: this.$t('sparkpay.wallet.withdrawals.withdrawals-table.fields.status'),
          sortable: true,
        },
      ],
      withdrawalsMock: [
        {
          amount_cents: undefined,
          created_at: undefined,
          status: undefined,
        },
        {
          amount_cents: undefined,
          created_at: undefined,
          status: undefined,
        },
      ],
    };
  },
  methods: {
    formartDate(date) {
      return dayjs(date).format('DD/MM/YYYY [às] HH:mm');
    },
    getBadgeVariant(status) {
      const types = ['pending', 'in_progress', 'complete', 'canceled', 'error'];
      const variants = ['secondary', 'primary', 'success', 'cherry', 'danger'];

      return variants[types.indexOf(status)];
    },
  },
};
</script>
