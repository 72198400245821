<template>
  <div :class="['m-tag', variant]">
    {{ label }}
  </div>
</template>

<script>
export default {
  name: 'MTag',
  props: {
    label: {
      type: String,
      required: true,
    },
    variant: {
      type: String,
      default: 'primary',
    },
  },
};
</script>

<style lang="scss" scoped>
$variants: (
  primary: (
    bg: #7427f1,
    text: #fff,
    outline: #35126e,
  ),
  success: (
    bg: #348535,
    text: #fff,
    outline: #112b11,
  ),
  info: (
    bg: #3575d4,
    text: #fff,
    outline: #1e4278,
  ),
  warning: (
    bg: #eda81f,
    text: #262626,
    outline: #977d48,
  ),
  danger: (
    bg: #db3939,
    text: #fff,
    outline: #802121,
  ),
  disabled: (
    bg: #bababa,
    text: #262626,
    outline: #525252,
  ),
);

.m-tag {
  display: flex;
  align-items: center;
  padding: 3px 12px;
  border-radius: 200px;
  font-size: 12px;
  line-height: 15.06px;
  font-weight: 600;
  width: fit-content;
  text-wrap: nowrap;

  @each $tipo, $cores in $variants {
    &.#{$tipo} {
      background-color: map-get($cores, 'bg');
      color: map-get($cores, 'text');
      border: 1px solid map-get($cores, 'bg');
    }

    &.#{$tipo}-outline {
      background-color: transparent;
      border: 1px solid map-get($cores, 'outline');
      color: map-get($cores, 'outline');
    }
  }
}
</style>
