<template>
  <div class="sales-strategy-card-list">
    <div v-for="sale in salesList" :key="sale.id" class="sales-strategy-card-list__card" @click="openSidebar(sale)">
      <div class="sales-strategy-card-list__header">
        <div class="sales-strategy-card-list__header-date">
          {{ formatDate(sale.created_at) }}
        </div>

        <div class="sales-strategy-card-list__header-options">
          <DropdownStrategySales v-if="viewOfProducer(sale)" :sale="sale" />

          <hs-icon icon="angle-right" size="32" />
        </div>
      </div>

      <div class="sales-strategy-card-list__description">
        <div class="sales-strategy-card-list__product-name">
          {{ sale.product }}
        </div>

        <div class="sales-strategy-card-list__user-info">
          {{ sale.name }}
          <div class="sales-strategy-card-list__email">
            {{ sale.email }}
          </div>
        </div>
      </div>

      <div class="sales-strategy-card-list__progress-container">
        <div class="sales-strategy-card-list__status">
          <Tag
            :label="getStatusLabel('installments', sale.status)"
            :variant="getStatusVariant('installments', sale.status)"
          />
        </div>

        <div class="sales-strategy-card-list__received-value">
          {{ calculateTotalCollected(sale) }}
        </div>

        <div class="sales-strategy-card-list__progress-bar-container">
          <div class="sales-strategy-card-list__installments-progress">
            {{ formatInstallmentsProgressText(sale) }}
          </div>

          <div class="sales-strategy-card-list__total-collected">
            Valor recebido
          </div>

          <div class="sales-strategy-card-list__progress-bar">
            <div class="sales-strategy-card-list__progress" :style="{ width: calculatePercentage(sale) }" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import GeneralHelper from '@/shared/helpers/general';
import DropdownStrategySales from '@/sparkpay/views/Sales/components/DropdownStrategySales.vue';
import PaymentSummaryMixin from '@/sparkpay/mixins/PaymentSummaryMixin.js';
import dayjs from 'dayjs';
import StatusMixin from '@/mixins/StatusMixin';
import Tag from '@/components/MTag.vue';

export default {
  components: {
    DropdownStrategySales,
    Tag,
  },
  mixins: [PaymentSummaryMixin, StatusMixin],
  props: {
    salesList: {
      required: true,
      type: Array,
    },
  },
  methods: {
    ...mapActions('sale', ['selectSidebarSale']),
    formatDate(date) {
      return dayjs(date).format('DD/MM/YYYY HH:mm');
    },
    calculatePercentage(sale) {
      const percentage = (sale.paid_installments / sale.total_installments) * 100;
      return `${percentage}%`;
    },
    formatInstallmentsProgressText(sale) {
      return `${sale.paid_installments}/${sale.total_installments} parcelas pagas`;
    },
    openSidebar(sale) {
      this.selectSidebarSale(sale);
    },
    viewOfProducer(sale) {
      return sale.participation === null;
    },
    calculateTotalCollected(item) {
      const netValueByParticipation = this.netValueByParticipation(item);
      const collectedValueByInstallment = netValueByParticipation / item.total_installments;
      const collectedTotalValue = collectedValueByInstallment * item.paid_installments;

      return GeneralHelper.currency(collectedTotalValue / 100);
    },
  },
};
</script>

<style lang="scss">
.sales-strategy-card-list {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
}

.sales-strategy-card-list__card {
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0px 4px 8px rgba(63, 63, 63, 0.08);
  padding: 16px;
}

.sales-strategy-card-list__header {
  display: flex;
  justify-content: space-between;
}

.sales-strategy-card-list__header-options {
  width: 55px;
  display: flex;
  justify-content: flex-end;
  column-gap: 12px;
  align-items: center;
}

.sales-strategy-card-list__description {
  display: flex;
  flex-direction: column;
  margin-top: 16px;
}

.sales-strategy-card-list__product-name {
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.5rem;
  color: $grey;
}

.sales-strategy-card-list__user-info {
  font-size: 0.875rem;
  line-height: 1.313rem;
  color: $grey;
  margin-top: 16px;
}

.sales-strategy-card-list__email {
  margin-top: 4px;
}

.sales-strategy-card-list__progress-container {
  margin-top: 24px;
  display: grid;
  gap: 16px;
  grid-template-columns: 1fr 1fr;
  grid-template-areas:
    'status received-value'
    'progress-bar progress-bar';
}

.sales-strategy-card-list__status {
  grid-area: status;
}

.sales-strategy-card-list__received-value {
  grid-area: received-value;
  text-align: right;
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 600;
  color: $grey;
}

.sales-strategy-card-list__progress-bar-container {
  grid-area: progress-bar;
  color: #3f3f3f;
  font-size: 0.75rem;
  line-height: 1.125rem;
  display: grid;
  gap: 4px;
  grid-template-columns: 1fr 1fr;
  grid-template-areas:
    'progress collected'
    'bar bar';
}

.sales-strategy-card-list__installments-progress {
  grid-area: progress;
}

.sales-strategy-card-list__total-collected {
  grid-area: collected;
  text-align: right;
}

.sales-strategy-card-list__progress-bar {
  grid-area: bar;
  height: 8px;
  background-color: $grey-16;
  border-radius: 200px;

  @media (min-width: $screen-md) {
    height: 4px;
  }
}

.sales-strategy-card-list__progress {
  height: 100%;
  background-color: #3575d4;
  border-radius: 200px;
}
</style>
