import { TranslateResult } from 'vue-i18n';

export interface Event {
  id: number;
  school_id: number;
  trigger: string;
  created_at: Date;
  updated_at: Date;
}

export enum AutomationActionType {
  SENDGRID = 'Sendgrid',
  WEBHOOK = 'Webhook',
  HERO_WEBHOOK = 'HeroWebhook',
  SMS = 'Twilio',
}

export enum AutomationScopeType {
  INTEGRATION = 'Integration',
  USER = 'User',
  COLLABORATORS = 'Collaborators',
}

interface RequestHeader {
  key: string;
  value: string;
}
export interface WebhookSettings {
  url: string;
  request_method: string;
  headers: RequestHeader[];
  request_headers: {};
  body: string;
}
export interface AutomationSettings {
  body?: string;
  liquid_key?: string;
  role_ids?: [] | string;
  type?: string;
  subject?: string;
  to?: string;
  url?: string;
  request_method?: string;
  headers: [];
  request_headers?: object;
  scope?: string;
}
export interface AutomationConditionsFilter {
  _id: string;
  compare: string;
  value: number;
  key: string;
}
export interface AutomationConditions {
  filters: AutomationConditionsFilter[];
  operator: string;
}
export interface Automation {
  active: boolean;
  category?: string | TranslateResult;
  can_update: boolean;
  conditions: AutomationConditions;
  created_at: Date;
  event: Event;
  hermes_event_id: number;
  id: number;
  integration: string;
  name: string | TranslateResult;
  owner_type: string;
  owner_id: number;
  scope: string;
  configurations: null | AutomationSettings;
  settings: null | AutomationSettings;
  updated_at: Date;
}
export interface AutomationModel {
  title: any;
  desc: any;
  category: string;
  trigger: string;
  checkout: string[];
  filter_compare?: string;
  filter_key?: string;
  action: {
    active: boolean;
    conditions: object;
    integration: string;
    scope: string;
    settings: null | object;
    configurations: null | object;
  };
}

export enum FilterAutomationModel {
  ALL = 'all',
  RECURRENCY = 'recurrency',
  CART_RECOVERY = 'cart_recovery',
  INTERACT_WITH_LEADS = 'interact_with_leads',
  UPSELL = 'upsell',
  NEW_CART_RECOVERY = 'new_cart_recovery',
}

export enum SubscriptionTriggers {
  SUBSCRIPTION_END = 'subscription_end',
  SUBSCRIPTION_MARK_PAST_DUE = 'subscription_mark_past_due',
  SUBSCRIPTION_UPDATE = 'subscription_update',
  SUBSCRIPTION_RECOVER = 'subscription_recover',
  SUBSCRIPTION_CANCEL = 'subscription_cancel',
  SUBSCRIPTION_ACTIVATE = 'subscription_activate',
  SUBSCRIPTION_DEACTIVATE = 'subscription_deactivate',
}

export enum AutomationTypeIcon {
  EMAIL = 'envelope',
  MESSAGE = 'comments',
  TAG = 'tag',
  WEBHOOK = 'brackets',
}

export enum AutomationTypeId {
  EMAIL = 'email',
  MESSAGE = 'message',
  TAG = 'tag',
  WEBHOOK = 'webhook',
  HERO_WEBHOOK = 'webhook',
}
export interface AutomationType {
  id: AutomationTypeId;
  title: TranslateResult;
  icon: AutomationTypeIcon;
  active: Boolean;
}
