import { jarvisClient } from './client';

export async function getLogs({ hermes_action_id, page = 1 }) {
  return jarvisClient.get(`/logs/hermes/webhook?hermes_action_id=${hermes_action_id}&page=${page}`);
}

export async function getTransactionSummary() {
  return jarvisClient.get(`transaction/summary/paid`);
}

export async function getJourneyData() {
  return jarvisClient.get(`/journey`);
}
