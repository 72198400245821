<template>
  <div class="cards-info-subscription">
    <SalesCardsSingleInfo
      data-testid="installments-text"
      :title="formattedInstallmentsText"
      icon="badge-dollar"
      :subtitle="$t('sparkpay.sales.details.cards-info.liquid-value')"
    />

    <SalesCardsSingleInfo
      data-testid="paid-installments-text"
      :title="paidInstallmentsTotal"
      :icon="cardInfoIcon"
      :subtitle="$t('sparkpay.sales.details.cards-info.total-paid')"
    />

    <SalesCardsSingleInfo
      data-testid="left-installments-text"
      :title="leftInstallmentsTotal"
      :icon="cardInfoIcon"
      :subtitle="$t('sparkpay.sales.details.cards-info.to-recieve')"
    />
  </div>
</template>

<script>
import GeneralHelper from '@/shared/helpers/general';
import SalesCardsSingleInfo from '@/sparkpay/views/Sales/components/SalesCardsSingleInfo.vue';

export default {
  name: 'CardsInfoSubscription',
  components: {
    SalesCardsSingleInfo,
  },
  props: {
    sale: {
      type: Object,
      required: true,
    },
  },
  computed: {
    formattedInstallmentsText() {
      const { total_installments, net_value_cents } = this.sale;

      return `${total_installments}x ${GeneralHelper.currency(net_value_cents / 100)}`;
    },
    paidInstallmentsTotal() {
      const paidPayments = this.sale.payments.filter(payment => payment.status === 'paid');
      const paidValue = paidPayments.reduce(
        (acc, payment) =>
          acc + (payment.net_value_cents - payment.coproductions_fees_cents - payment.affiliate_fees_cents),
        0
      );

      return GeneralHelper.currency(paidValue / 100);
    },
    leftInstallmentsTotal() {
      const leftPayments = this.sale.payments.filter(payment => payment.status !== 'paid');
      const leftValue = leftPayments.reduce(
        (acc, payment) =>
          acc + (payment.net_value_cents - payment.coproductions_fees_cents - payment.affiliate_fees_cents),
        0
      );

      return GeneralHelper.currency(leftValue / 100);
    },
    cardInfoIcon() {
      const { payments } = this.sale;
      const isCreditCardPayment = payments[0]?.payment_method === 'credit_card';

      return isCreditCardPayment ? 'credit-card' : 'coins';
    },
  },
};
</script>

<style lang="scss">
.cards-info-subscription {
  display: flex;
  gap: 20px;
  flex-direction: column;
  margin: 1.5rem 1rem;

  @media (min-width: $screen-md) {
    flex-direction: row;
    margin: 1.5rem 2rem;
  }
}
</style>
