<template>
  <MXButton data-testid="copy-content-button" variant="tertiary" :icon="icon" @click="copyContent()">
    {{ buttonText }}
  </MXButton>
</template>

<script>
import ToastHelper from '@/shared/helpers/toast';
import MXButton from '@/shared/components/MXButton.vue';

export default {
  name: 'CopyContentButton',
  components: {
    MXButton,
  },
  props: {
    icon: {
      default: 'fal fa-clone',
      type: String,
    },
    buttonText: {
      default: 'Copiar',
      type: String,
    },
    content: {
      required: true,
      type: String,
    },
    successMessage: {
      default: 'Copiado com sucesso!',
      type: String,
    },
  },
  methods: {
    copyContent() {
      navigator.clipboard
        .writeText(this.content)
        .then(() => {
          ToastHelper.successMessage(this.successMessage);
        })
        .catch(() => {
          ToastHelper.dangerMessage('Falha ao copiar. Tente novamente.');
        });
    },
  },
};
</script>
