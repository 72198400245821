<template>
  <div class="tlw-flex tlw-flex-col tlw-justify-between tlw-mx-8 tlw-mt-4 lg:tlw-mt-6">
    <h3 class="tlw-text-base tlw-mt-6 tlw-mb-6 tlw-font-bold">
      {{ $t('sparkpay.sales.details.payment-table.title') }}
    </h3>
    <hs-table
      ref="salesTable"
      class="payments-table"
      hover
      responsive
      selectable
      select-mode="single"
      :fields="fields"
      :items="sales"
      @selectedItems="payment => setSelectedPayment(payment[0])"
    >
      <template slot="due_at" slot-scope="row">
        {{ formatDueDate(row.item) }}
      </template>
      <template slot="amount" slot-scope="row">
        {{ totalReceivedText(row.item) }}
      </template>
      <template slot="status" slot-scope="row">
        <Tag
          :label="getStatusLabel('installment', row.item.status)"
          :variant="getStatusVariant('installment', row.item.status)"
        />
      </template>
      <template slot="action" slot-scope="row">
        <div class="payments-table__actions-wrapper">
          <div v-if="isCreditCard(row.item)" v-b-tooltip.hover.top="messageButton(row)" class="tooltip-wd">
            <MButton
              v-if="isOverdue(row)"
              icon="redo"
              variant="primary-outline"
              type="submit"
              size="md"
              label="Recobrar pagamento"
              @click="reprocess(row.item.paymentId)"
              data-toggle="tooltip"
              data-placement="top"
              :disabled="!canReprocess(row)"
            />
          </div>
          <BolixPaymentActions
            v-else-if="displayBolixActions(row.item, row.index)"
            :sale="row.item"
            :is-first-payment-paid="isFirstPaymentPaid"
            @fetch-orders="$emit('getOrder')"
          />
          <span v-else />

          <hs-icon v-if="isPaidOrRefunded(row.item.status)" icon="chevron-right" />
        </div>
      </template>
    </hs-table>

    <RecurrencyPaymentDrawer
      v-if="selectedPayment"
      :payment="selectedPayment"
      :subscription="subscription"
      @close="selectedPayment = null"
    />
  </div>
</template>

<script>
import dayjs from 'dayjs';
import { PaymentMethodsList, PaymentStatusList } from '@/types/payments';
import GeneralHelper from '@/shared/helpers/general';
import paymentsService from '@/services/sparkecommerce/payments';
import ToastHelper from '@/shared/helpers/toast';
import SalesDetailsMixin from '@/sparkpay/mixins/SalesDetailsMixin.js';
import BolixPaymentActions from '@/sparkpay/views/Sales/components/BolixPaymentActions.vue';
import Tag from '@/components/MTag.vue';
import StatusMixin from '@/mixins/StatusMixin';
import RecurrencyPaymentDrawer from './RecurrencyPaymentDrawer.vue';

import CookieHelper from '@/shared/helpers/cookie.js';

export default {
  name: 'PaymentsTable',
  mixins: [SalesDetailsMixin, StatusMixin],
  components: {
    MButton: () => import('@/shared/components/MButton.vue'),
    BolixPaymentActions,
    Tag,
    RecurrencyPaymentDrawer,
  },
  props: {
    sales: {
      required: true,
      type: Array,
    },
    subscription: {},
  },
  data() {
    return {
      disableReprocessButton: false,
      selectedPayment: null,
      fields: [
        {
          key: 'due_at',
          label: this.$t('sparkpay.sales.sales-table.fields.created_at'),
          sortable: true,
        },
        { key: 'amount', label: this.$t('sparkpay.sales.sales-table.fields.total'), sortable: true },
        { key: 'status', label: this.$t('sparkpay.sales.sales-table.fields.status'), sortable: true },
        { key: 'action', label: '' },
      ],
    };
  },
  methods: {
    isPaidOrRefunded(status) {
      return [PaymentStatusList.PAID, PaymentStatusList.REFUNDED].includes(status);
    },
    setSelectedPayment(payment) {
      if (!payment) {
        this.selectedPayment = null;
        return;
      }

      const { paymentMethod, status } = payment;

      if (paymentMethod === PaymentMethodsList.BANK_SLIP) {
        this.selectedPayment = status === PaymentStatusList.REFUNDED ? payment : null;
        return;
      }

      const isCardOrPix = [PaymentMethodsList.CREDIT_CARD, PaymentMethodsList.PIX].includes(paymentMethod);
      const isPaidOrRefunded = this.isPaidOrRefunded(status);

      if (isCardOrPix && isPaidOrRefunded) {
        this.selectedPayment = payment;
        return;
      }

      this.selectedPayment = null;
    },
    isCreditCard(payment) {
      return payment.paymentMethod === PaymentMethodsList.CREDIT_CARD;
    },
    formatDueDate(payment) {
      const date = this.selectDueDate(payment);

      return date ? dayjs.utc(date).format('DD/MM/YYYY') : '—';
    },
    totalReceivedText(subscription) {
      return GeneralHelper.currency(subscription.netValueAfterCommission);
    },
    selectDueDate(payment) {
      const { paymentMethod, dueAt, bankSlipExpirationDate, pixExpirationAt } = payment;

      if (paymentMethod === PaymentMethodsList.BANK_SLIP) return bankSlipExpirationDate;
      if (paymentMethod === PaymentMethodsList.PIX) return pixExpirationAt;

      return dueAt;
    },
    isProcessingReprocess(row) {
      const reprocessingPayment = CookieHelper.getCookie(this.paymentCookieName(row.item.paymentId));
      if (this.canReprocess(row)) {
        CookieHelper.deleteCookie(this.paymentCookieName(row.item.paymentId));
        return false;
      }
      return reprocessingPayment;
    },
    async reprocess(id) {
      try {
        await paymentsService.reprocess(id);

        this.$emit('getOrder');
        CookieHelper.setCookie(true, 0.01, paymentCookieName(id));
        ToastHelper.successMessage(this.$t('sparkpay.sales.sales-table.reprocess.sucess'));
      } catch (error) {
        this.$emit('getOrder');
        ToastHelper.dangerMessage(this.$t('sparkpay.sales.sales-table.reprocess.error'));
        return error;
      }
    },
    isOverdue(row) {
      const subscriptionStatuses = ['past_due', 'ended'];
      const paymentStatuses = ['refused', 'error', 'overdue'];

      const isAPaymentReprocessedAndWaitingPayment = row.item.status === 'waiting_payment' && row.item.reprocessed;

      const subscriptionStatus = this.subscription?.subscriptionStatus || '';

      return (
        subscriptionStatuses.includes(subscriptionStatus) &&
        (isAPaymentReprocessedAndWaitingPayment || paymentStatuses.includes(row.item.status))
      );
    },
    canReprocess(row) {
      return this.isOverdue(row) && row.item.enabledReprocess;
    },
    messageButton(row) {
      return this.canReprocess(row) != true ? this.$t('sparkpay.sales.sales-table.reprocess.tooltip') : '';
    },
    paymentCookieName(id) {
      return `recover-processing-${id}`;
    },
  },
};
</script>
<style lang="scss">
.tooltip-wd {
  width: fit-content;
}

.payments-table {
  @media (min-width: $screen-md) {
    .table {
      thead {
        tr {
          height: 37px;
        }
      }

      tr {
        height: 56px;
      }

      td {
        padding: 8px 32px 8px 16px;
      }
    }
  }
}

.payments-table__actions-wrapper {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}
</style>
