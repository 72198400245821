<template>
  <div class="cancel-modal-sales-installments">
    <div tabindex="0" @click="$emit('close')" variant="link" class="cancel-modal-sales-installments__close-modal">
      <hs-icon icon="times" />
    </div>
    <hsLoading data-testid="loading" v-if="isLoading" />
    <template v-else>
      <img width="180" :src="image" alt="Robô com balão de erro" />

      <h3 class="cancel-modal-sales-installments__title">
        {{ $t('sparkpay.sales.details.modal.purchase-cancellation') }}
      </h3>
      <p class="cancel-modal-sales-installments__description">
        {{ $t('sparkpay.sales.details.modal.purchase-message', { memberName: sale.name, productName: sale.product }) }}
      </p>
      <div class="cancel-modal-sales-installments__buttons-wrapper">
        <m-button class="cancel-modal-sales-installments__close-button" variant="link" @click="$emit('close')">
          {{ $t('sparkpay.sales.details.modal.close') }}
        </m-button>
        <m-button
          @click="cancelOrder(sale.id)"
          class="cancel-modal-sales-installments__confirm-button"
          variant="critical"
        >
          {{ $t('sparkpay.sales.details.modal.purchase-cancel') }}
        </m-button>
      </div>
    </template>
  </div>
</template>

<script>
import MButton from '@/shared/components/MButton.vue';
import { orderService } from '@/services';
import ToastHelper from '@/shared/helpers/toast';
import { hsLoading } from '@/components';

export default {
  components: { MButton, hsLoading },
  props: {
    sale: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isLoading: false,
      image: require('@/assets/images/SparkPay/commons/img-robot-message.svg'),
    };
  },
  methods: {
    async cancelOrder(id) {
      try {
        this.isLoading = true;
        await orderService.cancelOrder({ id });

        this.$emit('on-success');
      } catch (error) {
        ToastHelper.dangerMessage(this.$t('sparkpay.sales.details.modal.purchase-cancel-error'));
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style lang="scss">
.cancel-modal-sales-installments {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  row-gap: 20px;

  @media (min-width: 768px) {
    row-gap: 32px;
  }
}

.cancel-modal-sales-installments__close-modal {
  position: absolute;
  top: 12px;
  right: 12px;
  cursor: pointer;
  color: $grey;
}

.cancel-modal-sales-installments__title {
  font-size: 1.5rem;
  line-height: 1.875rem;
  font-weight: 700;
  margin: 0;
}

.cancel-modal-sales-installments__description {
  font-size: 0.875rem;
  line-height: 1.313rem;
  margin: 0;
  text-align: center;
}

.cancel-modal-sales-installments__buttons-wrapper {
  display: flex;
  width: 100%;
  gap: 12px;
  flex-direction: column;

  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: space-around;
  }
}
</style>
