var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"auto-pilot-banner",class:{
    'auto-pilot-banner--vertical': _vm.vertical,
    'auto-pilot-banner--with-icon': _vm.icon,
  }},[(_vm.icon)?_c('hs-icon',{staticClass:"auto-pilot-banner__icon",attrs:{"variant":"light","icon":_vm.icon}}):(_vm.imageName)?_c('img',{staticClass:"auto-pilot-banner__image",attrs:{"src":require(("@/assets/images/SalesReport/" + _vm.imageName))}}):_vm._e(),_vm._v(" "),_c('div',{staticClass:"auto-pilot-banner__content"},[(_vm.title)?_c('div',{staticClass:"auto-pilot-banner__title"},[_vm._v("\n      "+_vm._s(_vm.title)+"\n    ")]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"auto-pilot-banner__description",class:{ 'auto-pilot-banner__description--without-margin': !_vm.title }},[_vm._v("\n      "+_vm._s(_vm.description)+"\n    ")])]),_vm._v(" "),_c('router-link',{staticClass:"auto-pilot-banner__button",attrs:{"to":{
      name: 'Automations',
      query: {
        tab: _vm.automationModelsTab,
        automationModel: _vm.automationModel,
      },
    }}},[_vm._v("\n    "+_vm._s(_vm.$t('menu.sparkpay.sales.open_auto_pilot'))+"\n  ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }