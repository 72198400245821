<template>
  <b-dropdown right>
    <template #button-content>
      <hs-icon variant="regular" icon="ellipsis-v" size="20" />
    </template>
    <b-dropdown-item v-for="option in dropdownOptions" :key="option.title" @click="option.action">
      <hs-icon variant="light" :icon="option.icon" />
      <span>{{ option.title }}</span>
    </b-dropdown-item>
  </b-dropdown>
</template>

<script>
export default {
  name: 'DropdownStrategySales',
  props: ['sale'],
  data() {
    return {
      dropdownOptions: [
        { title: 'Ver detalhes da venda', icon: 'piggy-bank', action: this.showSellDetails },
        { title: 'Ver detalhes do membro', icon: 'user-circle', action: this.showMemberDetails },
      ],
    };
  },
  methods: {
    showSellDetails() {
      const route = this.$router.resolve({ name: 'SalesInstallmentDetails', params: { id: this.sale.id } });
      window.open(route.href, '_blank');
    },
    showMemberDetails() {
      this.$router.push({ name: 'MemberDetail', params: { id: this.sale.buyer_id } });
    },
  },
};
</script>

<style lang="scss">
.dropdown {
  button {
    background: transparent;
    color: $grey-32;
    box-shadow: none;
    &::after {
      content: none;
    }

    &:hover {
      background-color: transparent;
      color: $grey-32;
    }

    &:active {
      background-color: transparent !important;
      color: $grey-32 !important;
    }

    &:focus {
      background-color: transparent;
      color: $grey-32;
    }
    &.btn-secondary.dropdown-toggle {
      background-color: transparent;
      color: $grey-32;
    }
    &.btn-secondary:focus {
      box-shadow: none;
    }
  }

  .dropdown-menu {
    border-radius: 4px;
    border: 1px solid $grey-ba;
    padding: 8px;
    color: $grey;

    .dropdown-item {
      display: flex;
      align-items: center;
      padding: 8px;
      border-radius: 4px;
      i {
        margin-right: 8px;
        width: 17.5px;
      }
      span {
        font-size: 0.875rem;
        line-height: 21px;
      }

      &:hover {
        background-color: $grey-16;
        i {
          color: $purple-dark;
        }
      }

      &:active {
        span {
          color: $grey;
        }
      }
    }
  }
}
</style>
