export enum EmitReportTypes {
  PAYMENT_CONFIRMED = 'payment_confirmed',
  NEVER = 'never',
  AFTER_GUARANTEE = 'after_guarantee',
}

export enum EmitInvoiceFeesTypes {
  WITHOUT_FEES = 'without_fees',
  INSTALLMENT_FEES = 'installment_fees',
}
