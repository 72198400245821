<template>
  <div>
    <MongoReport
      dashboard-id="b627bac3-f5ca-4c6d-8ab7-0c7f50976975"
      :translate-key="translateKey"
      :disable-report-download="false"
      report-collection="purchase"
      export-type="refused_sales"
    />
  </div>
</template>

<script>
import MongoReport from './components/MongoReport.vue';

export default {
  name: 'RefusedSalesReport',
  components: {
    MongoReport,
  },
  data() {
    return {
      translateKey: 'menu.sparkpay.sales.refused_sales_report',
    };
  },
};
</script>

<style lang="scss" scoped>
.subscription-sales-made-report__container {
  padding: 24px 16px;

  @media (min-width: $screen-md) {
    padding: 24px 32px;
  }
}
</style>
