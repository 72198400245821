<template>
  <div class="tlw-flex tlw-flex-col tlw-items-center tlw-bg-white tlw-w-full tlw-pt-12 tlw-pb-11 tlw-px-9">
    <hs-button @click="$bvModal.hide(modalCancelSuccessId)" variant="link" class="tlw-absolute tlw-top-1 tlw-right-1">
      <hs-icon class="tlw-text-black" icon="times" />
    </hs-button>
    <img width="180" :src="image" alt="cancel-subscription-modal" />
    <h3 class="tlw-text-red-500 tlw-text-3xl tlw-font-bold tlw-text-center tlw-mt-11">
      {{ $t('sparkpay.sales.details.modal.cancellation-confirmed') }}
    </h3>
    <p class="tlw-mt-6 tlw-text-center">
      {{
        $t('sparkpay.sales.details.modal.cancellation-message', {
          memberName: sale.buyer.fullName,
          productName: sale.productName,
        })
      }}
    </p>
    <div class="tlw-flex tlw-justify-center tlw-w-full tlw-mt-6">
      <m-button class="tlw-h-11 tlw-w-9/12 lg:tlw-w-6/12" variant="primary" @click="confirmSuccess">
        {{ $t('sparkpay.sales.details.modal.cancellation-ok') }}
      </m-button>
    </div>
  </div>
</template>

<script>
import MButton from '@/shared/components/MButton.vue';

export default {
  components: { MButton },
  props: {
    sale: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      image: require('@/assets/images/SparkPay/commons/img-robot.svg'),
      modalCancelSuccessId: 'sale-view-cancel-success',
    };
  },
  methods: {
    confirmSuccess() {
      this.$emit('on-success');
      this.$bvModal.hide(this.modalCancelSuccessId);
    },
  },
};
</script>
