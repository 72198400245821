<template>
  <div class="sidebar-detailed">
    <div class="sidebar-detailed__close-button">
      <hs-button variant="link" @click="$emit('close')">
        <hs-icon icon="times" />
      </hs-button>
    </div>
    <div class="sidebar-detailed__content">
      <Tag
        :label="getStatusLabel('installments', sale.status)"
        :variant="getStatusVariant('installments', sale.status)"
      />
      <div class="sidebar-detailed__general-info">
        <div class="sidebar-detailed__buyer-name">
          {{ sale.name }}
        </div>

        <div class="sidebar-detailed__general-info-row">
          <div class="sidebar-detailed__general-info-label">
            {{ $t('sparkpay.sales.detailed-sales-sidebar.email') }}:
          </div>

          <div class="sidebar-detailed__general-info-data">
            {{ sale.email }}
          </div>
        </div>

        <div class="sidebar-detailed__general-info-row">
          <div class="sidebar-detailed__general-info-label">{{ $t('sparkpay.sales.detailed-sales-sidebar.cpf') }}:</div>

          <div class="sidebar-detailed__general-info-data">
            {{ formatCPF(sale.cpf) }}
          </div>
          <hs-button v-if="sale.cpf" variant="link" size="sm" @click="() => (showCpf = !showCpf)">
            <hs-icon variant="light" :icon="`eye${showCpf ? '-slash' : ''}`" />
          </hs-button>
        </div>

        <div v-if="sale.phone" data-testid="buyer-phone" class="sidebar-detailed__general-info-row">
          <div class="sidebar-detailed__general-info-label">
            {{ $t('sparkpay.sales.detailed-sales-sidebar.phone') }}:
          </div>

          <div class="sidebar-detailed__general-info-data">+{{ sale.ddi }} {{ sale.phone }}</div>

          <a
            data-testid="whatsapp-link"
            :href="getWhatsappLink(sale.phone, '', sale.ddi)"
            target="_blank"
            aria-label="Abrir conversa no WhatsApp"
            class="tlw-ml-2"
            @click="trackWhatsappEvent"
          >
            <i
              class="fab fa-whatsapp sidebar-detailed__whatsapp-icon"
              aria-hidden="true"
              title="Abrir conversa no WhatsApp"
            />
          </a>
        </div>
      </div>

      <hr class="sidebar-detailed__divider" />

      <SidebarDetails :sale="sale" />

      <hr class="sidebar-detailed__divider" />

      <div
        v-if="!isPaymentCanceledOrRefunded"
        data-testid="info-tax-text"
        v-html="infoTaxText"
        class="sidebar-detailed__extra-info"
      />

      <hs-button
        v-if="showRefundRequestButton"
        :disabled="!sale.enable_refund"
        block
        class="sidebar-detailed__refund-action"
        variant="outline-danger"
        size="lg"
        @click="showRefundSidebar = true"
      >
        <span v-b-tooltip="refundExpiredTooltip">{{ refundRequestButtonText }}</span>
      </hs-button>
      <CopyPublicLinkButton v-if="sale.public_id" :public_id="sale.public_id" />
    </div>
    <refund-sidebar v-if="showRefundSidebar" :sale="sale" @close="showRefundSidebar = false" />
  </div>
</template>

<script>
import { analyticsService } from '@/services';
import GeneralHelper from '@/shared/helpers/general';
import { getWhatsappLink } from '@/shared/helpers/phone';
import PaymentSummaryMixin from '@/sparkpay/mixins/PaymentSummaryMixin.js';
import CopyPublicLinkButton from './CopyPublicLinkButton.vue';
import Tag from '@/components/MTag.vue';
import StatusMixin from '@/mixins/StatusMixin';

export default {
  name: 'SidebarDetailed',
  mixins: [PaymentSummaryMixin, StatusMixin],
  props: {
    sale: {
      type: Object,
      required: true,
    },
  },
  components: {
    Tag,
    RefundSidebar: () => import('./RefundSidebar'),
    SidebarDetails: () => import('./SidebarDetails'),
    CopyPublicLinkButton: () => import('./CopyPublicLinkButton.vue'),
  },
  data() {
    return {
      showRefundSidebar: false,
      showCpf: false,
    };
  },
  computed: {
    isSubscription() {
      return this.sale.kind === 'subscription';
    },
    infoTaxText() {
      return this.$t('sparkpay.sales.detailed-sales-sidebar.info-tax');
    },
    isPaymentCanceledOrRefunded() {
      return ['canceled', 'refund_analyzing', 'refunded'].includes(this.sale.status);
    },
    showRefundRequestButton() {
      const { payment_method, status } = this.sale;

      return (
        status === 'confirmed' &&
        (payment_method.includes('credit_card') || payment_method.includes('multicard')) &&
        this.viewOfProducer(this.sale)
      );
    },
    refundRequestButtonText() {
      const textKey = this.sale.enable_refund ? 'request-refund' : 'refund-unavailable';
      return this.$t(`sparkpay.sales.detailed-sales-sidebar.${textKey}`);
    },
    refundExpiredTooltip() {
      return {
        disabled: this.sale.enable_refund,
        title: this.$t('sparkpay.sales.detailed-sales-sidebar.refund-expired-tooltip'),
      };
    },
  },
  methods: {
    formatCPF(cpf) {
      return cpf ? GeneralHelper.cpf(cpf, !this.showCpf) : '-';
    },
    trackWhatsappEvent() {
      analyticsService.track({
        event: 'Whatsapp button clicked',
        props: {
          location: 'sales_list',
        },
      });
    },
    getWhatsappLink,
  },
};
</script>

<style lang="scss" scoped>
.sidebar-detailed {
  position: fixed;
  right: 0;
  width: 350px;
  min-height: calc(100% - 69px);
  z-index: 2;
  background-color: $white;
  padding: 24px;
  top: 69px;
  box-shadow: -1px 2px 8px rgba(0, 0, 0, 0.25);
}

.sidebar-detailed__close-button {
  position: absolute;
  right: 24px;
}

.sidebar-detailed__content {
  width: 100%;
  height: calc(100vh - 110px);
  overflow-y: auto;
}

.sidebar-detailed__general-info {
  display: flex;
  flex-direction: column;
  margin-top: 16px;
}

.sidebar-detailed__general-info-row {
  margin-top: 8px;
  display: flex;
  align-items: center;
}

.sidebar-detailed__general-info-label {
  font-size: 0.875rem;
  color: #6f6f6f;
  width: 65px;
}

.sidebar-detailed__general-info-data {
  margin-left: 8px;
  font-size: 0.875rem;
  color: $grey;
  font-weight: 600;
}

.sidebar-detailed__buyer-name {
  color: $grey;
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 2rem;
}

.sidebar-detailed__divider {
  border-color: var(--color-gray-100);
  width: 100%;
  margin-top: 16px;
  margin-bottom: 16px;
}

.sidebar-detailed__whatsapp-icon {
  color: #6f6f6f;
}

.sidebar-detailed__extra-info {
  font-size: 0.875rem;
  color: $grey;
}

.sidebar-detailed__refund-action {
  margin-top: 24px;

  &:disabled {
    background-color: #e3e3e3;
    border: 2px solid #bababa;
    color: #bababa;
  }
}

@media screen and (max-width: 576px) {
  .sidebar-detailed {
    position: fixed;
    width: 100%;
    top: 0;
    background-color: white;
    box-shadow: none;
    padding-top: 70px;
  }
}
</style>
