import GeneralHelper from '@/shared/helpers/general';
import { gatewayService } from '@/services';
import toastHelper from '@/shared/helpers/toast';
import sparkpay from '@/sparkpay/services/sparkpay';

export var GetBankMixin = {
  data() {
    return {
      showBalance: false,
      bank: null,
      bankAccount: {},
      isLoading: true,
      gateway: {},
    };
  },
  methods: {
    currency(value) {
      return this.showBalance ? GeneralHelper.currency(value / 100, false) : '—';
    },
  },
  computed: {
    bankName() {
      if (!this.bank) return '---';
      const { name, bank_code } = this.bank;
      return `${bank_code} - ${name}`;
    },
    bankCode() {
      if (!this.bank) return '---';
      return this.bank.bank_code;
    },
    bankAccountType() {
      if (!this.bankAccount) return '---';
      return this.bankAccount.account_type;
    },
    bankAccountInfo() {
      if (!this.bankAccount || !this.bank) return '---';
      return `${GeneralHelper.bankAccount(this.bankAccount.account, true)}-${this.bankAccount.account_vd}`;
    },
    documentNumber() {
      if (!this.bankAccount) return '---';
      return this.bankAccount.document_type === 'cpf'
        ? GeneralHelper.cpf(this.bankAccount.document_number)
        : GeneralHelper.cnpj(this.bankAccount.document_number);
    },
    legalName() {
      if (!this.bankAccount) return '---';
      return this.bankAccount.legal_name;
    },
    hasBankAccount() {
      return this.bankAccount !== null;
    },
  },
  async mounted() {
    try {
      this.isLoading = true;
      const getwayResponse = await gatewayService.getGateway(this.selectedSchool.id, [
        { key: 'gateway', value: 'spark_pay' },
        { key: 'recipient_balance', value: true },
        { key: 'recipient_withdrawls', value: true },
      ]);

      const { bank_accounts } = await sparkpay.bankAccount.list();
      this.gateway = getwayResponse.gateway_credentials.length !== 0 && getwayResponse.gateway_credentials[0];
      const filteredBankAccount = bank_accounts.filter(bankAccount => bankAccount.bank_account_type !== 'pix');
      this.bankAccount = filteredBankAccount[0] === undefined ? null : filteredBankAccount[0];

      if (this.bankAccount) this.bank = gatewayService.getBankByCode(this.bankAccount.bank_code);
    } catch (error) {
      if (error.name === 'TypeError') {
        toastHelper.dangerMessage(
          'Não foi possível identificar seus dados bancários, entre em contato com o suporte para regularizar a situação'
        );
      } else {
        toastHelper.dangerMessage('Problema ao carregar seus dados, se persistir entre em contato com o suporte');
      }
    } finally {
      this.isLoading = false;
    }
  },
};
