<template>
  <div class="bolix-payment-actions">
    <CopyContentButton
      :content="getPaymentCode"
      button-text="Link de pagamento"
      icon="fal fa-link"
      success-message="Link copiado, compartilhe com seu cliente!"
    />
    <RecoveryPaymentBolixButton
      v-if="isFirstPaymentPaid && !isPixPayment"
      :payment-id="selectedPaymentId"
      :payment-status="sale.status"
      :payment-method="selectedPaymentMethod"
      @fetch-orders="$emit('fetch-orders')"
    />
  </div>
</template>

<script>
import RecoveryPaymentBolixButton from '@/sparkpay/views/Sales/SalesInstallmentDetails/components/RecoveryPaymentBolixButton.vue';
import CopyContentButton from '@/components/CopyContentButton.vue';
import { PaymentMethodsList } from '@/types/payments';

export default {
  name: 'BolixPaymentActions',
  props: {
    sale: {
      required: true,
      type: Object,
    },
    isFirstPaymentPaid: {
      required: true,
      type: Boolean,
    },
  },
  components: {
    RecoveryPaymentBolixButton,
    CopyContentButton,
  },
  computed: {
    selectedPaymentId() {
      const { id, paymentId } = this.sale;

      return id || paymentId;
    },
    selectedPaymentMethod() {
      const { payment_method, paymentMethod } = this.sale;

      return paymentMethod || payment_method;
    },
    getPaymentCode() {
      const { pix_qr_code, bank_slip_file_url, pixQrCode, bankSlipFileUrl } = this.sale;

      const selectedPixQrCode = pixQrCode || pix_qr_code;
      const selectedBankSlipFileUrl = bankSlipFileUrl || bank_slip_file_url;

      return this.isPixPayment ? selectedPixQrCode : selectedBankSlipFileUrl;
    },
    isPixPayment() {
      return this.selectedPaymentMethod === PaymentMethodsList.PIX;
    },
  },
};
</script>

<style lang="scss" scoped>
.bolix-payment-actions {
  display: flex;
  gap: 8px;
  flex-direction: column;

  @media (min-width: $screen-md) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    min-width: 388px;
  }
}
</style>
