<template>
  <div>
    <MongoReport
      dashboard-id="7cf3c247-06d6-4e93-ad3d-c843c3a130b3"
      :translate-key="translateKey"
      :disable-report-download="false"
      report-collection="purchase"
      export-type="subscription_sales"
    />
    <div class="subscription-sales-made-report__container">
      <AutoPilotBanner
        :title="$t(`${translateKey}.banner.title`)"
        :description="$t(`${translateKey}.banner.description`)"
        imageName="coin-upgrade.svg"
        selected-model="upsell"
      />
    </div>
  </div>
</template>

<script>
import AutoPilotBanner from '@/components/AutoPilotBanner.vue';
import MongoReport from './components/MongoReport.vue';

export default {
  name: 'SubscriptionSalesMadeReport',
  components: {
    AutoPilotBanner,
    MongoReport,
  },
  data() {
    return {
      translateKey: 'menu.sparkpay.sales.subscription_sales_made_report',
    };
  },
};
</script>

<style lang="scss" scoped>
.subscription-sales-made-report__container {
  padding: 24px 16px;

  @media (min-width: $screen-md) {
    padding: 24px 32px;
  }
}
</style>
