<template>
  <div class="sales-report">
    <div class="sales-report__hero-tips">
      <div class="sales-report__hero-tips-title">
        <i class="fal fa-info-circle sales-report__hero-tips-icon" />

        {{ $t(`${translateKey}.hero_tips_title`) }}
      </div>

      <div class="sales-report__hero-tips-description">
        {{ $t(`${translateKey}.hero_tips_description`) }}
      </div>
    </div>

    <TipsReportBanner />

    <div class="sales-report__reports-container">
      <button
        v-for="(report, index) in reports"
        :key="index"
        @click="handleReportClick(report)"
        class="sales-report__router-link"
        v-show="report.condition === undefined || report.condition"
      >
        <SalesReportCard
          :title="$t(`${translateKey}.cards.${report.titleKey}.title`)"
          :description="$t(`${translateKey}.cards.${report.descriptionKey}.description`)"
          :icon="report.icon"
          :vertical="report.vertical || false"
          :new-feature="report.newFeature || false"
        />
      </button>
    </div>
  </div>
</template>

<script>
import SalesReportCard from './SalesReportCard.vue';
import TipsReportBanner from './components/TipsReportBanner.vue';
import { analyticsService } from '@/services';

export default {
  name: 'SalesReport',
  components: {
    SalesReportCard,
    TipsReportBanner,
  },
  data() {
    return {
      translateKey: 'menu.sparkpay.sales.sales_report',
    };
  },
  methods: {
    handleReportClick(report) {
      analyticsService.track({
        event: 'Accessed report',
        props: {
          location: 'sales_report_tab',
          report: report.name,
        },
      });

      this.$router.push({ name: report.name });
    },
  },
  computed: {
    hasCheckoutTracking() {
      return this.$FCL.hasCheckoutTracking();
    },
    reports() {
      return [
        { name: 'InvoiceReport', titleKey: 'invoice', descriptionKey: 'invoice', icon: 'file', vertical: true },
        { name: 'SalesMadeReport', titleKey: 'done_sales', descriptionKey: 'done_sales', icon: 'usd-circle' },
        {
          name: 'AbandonedSalesReport',
          titleKey: 'abandoned_sales',
          descriptionKey: 'abandoned_sales',
          icon: 'money-bill-alt',
        },
        {
          name: 'OrderBumpSalesMadeReport',
          titleKey: 'order_bump_sales_made',
          descriptionKey: 'order_bump_sales_made',
          icon: 'cart-plus',
        },
        {
          name: 'SubscriptionSalesMadeReport',
          titleKey: 'subscription_sales',
          descriptionKey: 'subscription_sales',
          icon: 'box-usd',
          newFeature: true,
        },
        {
          name: 'RefusedSalesReport',
          titleKey: 'refused_sales',
          descriptionKey: 'refused_sales',
          icon: 'times-circle',
          newFeature: true,
        },
        {
          name: 'AbandonedCartReport',
          titleKey: 'abandoned_cart',
          descriptionKey: 'abandoned_cart',
          icon: 'empty-set',
          newFeature: true,
          condition: this.hasCheckoutTracking,
        },
        {
          name: 'AffiliatesCoproducerReport',
          titleKey: 'affiliates_coproducer',
          descriptionKey: 'affiliates_coproducer',
          icon: 'users-crown',
          newFeature: true,
        },
        {
          name: 'SalesStrategyReport',
          titleKey: 'sales_strategy',
          descriptionKey: 'sales_strategy',
          icon: 'lightbulb-dollar',
          newFeature: true,
        },
        {
          name: 'RefundedSalesReport',
          titleKey: 'refunded_sales',
          descriptionKey: 'refunded_sales',
          icon: 'redo',
          newFeature: true,
        },
      ];
    },
  },
};
</script>

<style lang="scss">
$page-vertical-margin: 24px;
$page-horizontal-margin: 32px;
$page-horizontal-margin-mobile: 16px;

.sales-report {
  padding: 0 $page-vertical-margin $page-horizontal-margin-mobile;

  @media (min-width: $screen-md) {
    padding: $page-vertical-margin $page-horizontal-margin;
  }
}

.sales-report__banner {
  display: flex;
  flex-direction: column;
  padding: 24px;
  background-color: #fff;
  border-radius: 4px;
  margin: (-$page-vertical-margin) (-$page-horizontal-margin-mobile) 0;

  @media (min-width: $screen-md) {
    display: grid;
    grid-template-columns: 82px auto;
    column-gap: 24px;
    margin: 0;
  }
}

.sales-report__banner-image {
  width: 52px;
  height: 50px;

  @media (min-width: $screen-md) {
    width: 82px;
    height: 80px;
  }
}

.sales-report__banner-content {
  display: flex;
  flex-direction: column;
  margin-top: 4px;

  @media (min-width: $screen-md) {
    margin-top: 0;
  }
}

.sales-report__banner-title {
  color: $grey;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.5rem;

  @media (min-width: $screen-md) {
    font-size: 1.25rem;
    line-height: 1.563rem;
  }
}

.sales-report__banner-description {
  color: #6f6f6f;
  font-size: 0.75rem;
  line-height: 1.125rem;
  margin-top: 4px;

  @media (min-width: $screen-md) {
    font-size: 0.875rem;
    line-height: 1.313rem;
  }
}

.sales-report__banner-info {
  padding: 8px;
  background-color: #e3e3e3;
  border-radius: 4px;
  display: grid;
  grid-template-columns: 16px auto;
  column-gap: 8px;
  margin-top: 16px;
  width: fit-content;
  align-items: center;
}

.sales-report__banner-info-text {
  color: #525252;
  font-size: 0.75rem;
  line-height: 1.125rem;

  @media (min-width: $screen-md) {
    font-size: 0.875rem;
    line-height: 1.313rem;
  }
}

.sales-report__banner-icon {
  color: #525252;
  text-align: center;
}

.sales-report__reports-container {
  display: grid;
  grid-template-columns: 1fr;
  margin-top: 24px;
  gap: 20px;

  @media (min-width: $screen-md) {
    margin: 0;
    margin-top: 24px;
    grid-template-columns: repeat(3, 1fr);
  }
}

.sales-report__router-link {
  text-align: unset;
  cursor: pointer;

  &:hover {
    text-decoration: none;
  }

  &:focus {
    outline: 0;
  }
}

.sales-report__hero-tips {
  background-color: #ffdcd5;
  padding: 24px;
  margin-left: -$page-vertical-margin;
  margin-right: -$page-vertical-margin;
  margin-bottom: 24px;

  @media (min-width: $screen-md) {
    display: none;
  }
}

.sales-report__hero-tips-title {
  color: #802121;
  display: flex;
  align-items: center;
  font-size: 0.875rem;
}

.sales-report__hero-tips-icon {
  font-size: 1.125rem;
  margin-right: 4px;
}

.sales-report__hero-tips-description {
  color: $grey;
  font-size: 0.75rem;
  line-height: 1.125rem;
  margin-top: 4px;
}
</style>
