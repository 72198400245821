<template>
  <section id="no-sales-warning" class="h-100 tlw-w-full tlw-flex tlw-flex-col tlw-justify-center">
    <b-img-lazy class="no-sales-warning__image" :src="noDataImg"></b-img-lazy>
    <h3 id="title" class="text-cherry tlw-text-center tlw-my-6 tlw-font-bold">
      {{ $t('sparkpay.sales.no-sales-warning.title') }}
    </h3>
    <div>
      <p class="tlw-text-center">{{ $t('sparkpay.sales.no-sales-warning.p') }}</p>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      noDataImg: require('@/assets/images/commons/no_data.svg'),
    };
  },
};
</script>

<style lang="scss">
.no-sales-warning__image {
  height: 170px;
}
</style>
