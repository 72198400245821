<template>
  <section class="tlw-flex tlw-relative flex-fill tlw-flex-col">
    <div class="flex-fill tlw-flex tlw-flex-col">
      <PageHeader
        :title="$t('sparkpay.sales.details.header.sell')"
        :back-only-mobile="false"
        back-route="SalesStrategyList"
        back-text="Voltar para vendas"
      />
      <hsLoading v-if="isLoading" />
      <template v-else>
        <SalesBoxUserInfo
          :status="sale.status"
          :buyerName="sale.name"
          :buyerEmail="sale.email"
          :buyerDocument="sale.cpf"
          :subscriptionId="sale.id"
          :createdAt="sale.created_at"
          :productName="sale.product"
          :netValueCents="sale.net_value_cents"
          page="installments"
        >
          <template #action>
            <div class="sale-installment-details-actions">
              <MButton
                v-if="sale.status === 'active' || sale.status === 'past_due'"
                class="sales-installment-details-cancel-purchase"
                variant="critical-outline"
                @click="() => $bvModal.show(modalCancelId)"
              >
                Cancelar compra
              </MButton>

              <CopyContentButton
                v-if="saleHasPaymentsWithCreditCard"
                :content="salesDetailsLink"
                button-text="Copiar link de troca de cartão"
                success-message="Link copiado com sucesso, compartilhe com seu cliente!"
              />
            </div>
          </template>
        </SalesBoxUserInfo>
        <CardsInfoSubscription :sale="sale" />
        <PaymentsTable
          v-if="sale.payments"
          :sales="orderedByDueAtPayments"
          :subscription="sale"
          @fetch-orders="getOrder(sale.id)"
        />
      </template>
    </div>

    <b-modal
      :id="modalCancelId"
      size="md"
      centered
      dialogClass="cancel-subscription-modal"
      :hideHeader="true"
      hide-footer
    >
      <CancelModalSalesInstallments
        :sale="sale"
        @on-success="handleCancelSuccess()"
        @close="$bvModal.hide(modalCancelId)"
      />
    </b-modal>
  </section>
</template>

<script>
import { mapGetters } from 'vuex';
import first from 'lodash/first';
import PageHeader from '@/components/_structures/PageHeader';
import SalesBoxUserInfo from '@/sparkpay/views/Sales/components/SalesBoxUserInfo.vue';
import CardsInfoSubscription from './components/CardsInfoSubscription';
import PaymentsTable from './components/PaymentsTable';
import { hsLoading } from '@/components';
import { orderService } from '@/services';
import paymentsService from '@/services/sparkecommerce/payments';
import PaymentSummaryMixin from '@/sparkpay/mixins/PaymentSummaryMixin.js';
import { PaymentMethodsList } from '@/types/payments';
import CopyContentButton from '@/components/CopyContentButton.vue';
import MButton from '@/shared/components/MButton.vue';
import CancelModalSalesInstallments from './components/CancelModalSalesInstallments.vue';

export default {
  name: 'SalesInstallmentDetails',
  components: {
    PageHeader,
    SalesBoxUserInfo,
    CardsInfoSubscription,
    PaymentsTable,
    hsLoading,
    CopyContentButton,
    MButton,
    CancelModalSalesInstallments,
  },
  mixins: [PaymentSummaryMixin],
  data() {
    return {
      sale: {},
      isLoading: true,
      public_id: '',
      modalCancelId: 'sale-installment-cancel',
    };
  },
  created() {
    if (!this.$FCL.canMembersSubscriptionOffers()) {
      this.$router.push({ name: 'Sales' });
    }
  },
  async mounted() {
    await this.getOrder();
    await this.getPublicId();
  },
  computed: {
    ...mapGetters('school', ['getDomainWithHttp']),
    salesDetailsLink() {
      return this.$FCL.hasMemberArea()
        ? `${this.getDomainWithHttp}/orders/${this.sale.cart_id}/payments`
        : `${this.getDomainWithHttp}/public/orders/${this.public_id}`;
    },
    saleHasPaymentsWithCreditCard() {
      return this.sale.payments.some(payment => payment.payment_method === PaymentMethodsList.CREDIT_CARD);
    },
    orderedByDueAtPayments() {
      const paymentsArray = [...this.sale.payments];

      return paymentsArray.sort((a, b) => {
        const aDate = new Date(this.getPaymentDate(a));
        const bDate = new Date(this.getPaymentDate(b));

        return aDate - bDate;
      });
    },
  },
  methods: {
    async getPublicId() {
      const { public_id } = await paymentsService.getPublicCart(this.sale.cart_id);
      this.public_id = public_id;
    },
    getPaymentDate(payment) {
      const { payment_method, due_at, bank_slip_expiration_date, pix_expiration_at } = payment;

      if (payment_method === PaymentMethodsList.BANK_SLIP) return bank_slip_expiration_date;
      if (payment_method === PaymentMethodsList.PIX) return pix_expiration_at;
      return due_at;
    },
    async getOrder(id = this.$route.params.id) {
      const response = await orderService.getOrder({ id });
      this.sale = {
        id: response.id,
        cart_id: response.cart_id,
        total: response.amount_total_subscriptions,
        paid: response.total,
        name: response.student.full_name,
        email: response.student.email,
        cpf: response.student.cpf,
        product: response.products[0].title,
        created_at: response.created_at,
        kind: response.kind,
        status: response.status,
        coupon: response.coupon,
        payments: response.payments,
        net_value_cents: this.netValueByParticipation(first(response.payments)),
        total_installments: response.total_installments,
        total_net_value_cents: response.total_net_value_cents,
      };
      this.isLoading = false;
    },
    handleCancelSuccess() {
      this.$bvModal.hide(this.modalCancelId);
      this.getOrder();
    },
  },
};
</script>

<style lang="scss">
.sales-installment-details-cancel-purchase {
  width: 100%;
}

.cancel-subscription-modal {
  max-width: 360px;
}

.sale-installment-details-actions {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
}
</style>
