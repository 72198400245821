<template>
  <div class="units-list-sales-table" :class="{ 'units-list-sales-table--sidebar-open': sidebarIsOpen }">
    <hs-table
      ref="unitsListSalesTable"
      hover
      responsive
      selectable
      :fields="fields"
      :items="sales"
      select-mode="single"
      @selectedItems="purchase => selectPurchase(purchase)"
    >
      <template slot="created_at" slot-scope="row">
        {{ formatDate(row.item.purchasedAt) }}
      </template>

      <template slot="product" slot-scope="row">
        <div class="units-list-badge-container">
          <hs-badge
            v-if="row.item.orderBumpUsed && row.item.orderBumpItems.length"
            variant="outline-purple"
            data-testid="order-bump-badge"
            class="units-list-sales-table__badge"
            pill
          >
            {{ $t('sparkpay.sales.detailed-sales-sidebar.order-bump') }}
          </hs-badge>

          <hs-badge v-if="row.item.isUpsell" variant="outline-purple" class="units-list-sales-table__badge" pill>
            Upsell
          </hs-badge>
        </div>
        <div>{{ row.item.productName }}</div>
      </template>

      <template slot="status" slot-scope="row">
        <Tag
          v-if="row.item.status"
          :label="getStatusLabel('unitary', row.item.status)"
          :variant="getStatusVariant('unitary', row.item.status)"
        />

        <div class="tlw-mt-1">
          {{ translatePaymentMethod(row.item.paymentMethod) }}
        </div>
      </template>

      <template slot="name" slot-scope="row">
        {{ row.item.buyer.firstName }} {{ row.item.buyer.lastName }}

        <div class="tlw-mt-1">
          {{ row.item.buyer.email }}
        </div>
      </template>

      <template slot="total" slot-scope="row">
        <div class="tlw-flex tlw-justify-between tlw-items-center">
          <div :class="{ 'units-list-sales-table__cancelled-text': !isApprovedPayment(row.item) }">
            {{ formatNetValue(row.item.totalNetValue) }}
          </div>

          <i class="far fa-chevron-right tlw-mr-2" />
        </div>
      </template>
    </hs-table>

    <UnitarySalesDrawer
      v-if="sidebarIsOpen"
      :key="selectedPurchase.saleId"
      :purchase="selectedPurchase"
      @close="closeSidebar()"
      @refunded="$emit('refunded')"
    />
  </div>
</template>

<script>
import dayjs from 'dayjs';
import GeneralHelper from '@/shared/helpers/general';
import UnitarySalesDrawer from '@/sparkpay/views/Sales/components/UnitarySalesDrawer';
import Tag from '@/components/MTag.vue';
import StatusMixin from '@/mixins/StatusMixin';

export default {
  name: 'UnitsListSalesTable',
  mixins: [StatusMixin],
  props: {
    sales: {
      required: true,
      type: Array,
    },
  },
  components: {
    UnitarySalesDrawer,
    Tag,
  },
  data() {
    return {
      sidebarIsOpen: false,
      selectedPurchase: null,
      fields: [
        {
          key: 'created_at',
          label: 'Data',
        },
        { key: 'product', label: this.$t('sparkpay.sales.units-list-sales-table.fields.product') },
        { key: 'name', label: this.$t('sparkpay.sales.units-list-sales-table.fields.name') },
        { key: 'status', label: this.$t('sparkpay.sales.units-list-sales-table.fields.status') },
        { key: 'total', label: this.$t('sparkpay.sales.units-list-sales-table.fields.total') },
      ],
    };
  },
  methods: {
    closeSidebar() {
      this.selectedPurchase = null;
      this.sidebarIsOpen = false;
    },
    selectPurchase(purchase) {
      if (!purchase.length) return;

      this.selectedPurchase = purchase[0];
      this.sidebarIsOpen = true;
    },
    formatNetValue(netValue) {
      return GeneralHelper.currency(netValue);
    },
    isApprovedPayment(item) {
      return ['paid'].includes(item.status);
    },
    formatDate(date) {
      return date ? dayjs(date).format('DD/MM/YYYY HH:mm') : '—';
    },
    translatePaymentMethod(paymentMethod) {
      return paymentMethod ? this.$t(`sparkpay.sales.sales-table.kinds.${paymentMethod}`) : '-';
    },
  },
};
</script>

<style lang="scss">
.units-list-sales-table {
  .table.b-table {
    th {
      color: var(--color-gray-800);
    }

    th[aria-sort] {
      .sorting {
        &::after {
          content: '\f15d';
        }
      }
    }

    th[aria-sort='descending'] {
      .sorting {
        color: $cyan;

        &::after {
          content: '\f882';
        }
      }
    }

    th[aria-sort='ascending'] {
      .sorting {
        color: $cyan;

        &::after {
          content: '\f15d';
        }
      }
    }
  }
}

.units-list-sales-table--sidebar-open {
  width: calc(100% - 335px);
  transition: width 0.3s cubic-bezier(1, 0.5, 0.8, 1);

  @media screen and (max-width: 576px) {
    width: 100%;
  }
}

.units-list-sales-table__cancelled-text {
  color: #bababa;
}

.units-list-sales-table__badge {
  margin-bottom: 8px;
}

.units-list-badge-container {
  display: flex;
  align-items: center;
  column-gap: 8px;
}

.table.b-table {
  tbody {
    > .table-active {
      > td {
        border: 2px solid #7427f1;
        border-left: none;
        border-right: none;
        background-color: #fff;

        &:first-child,
        &:last-child {
          border: 2px solid #7427f1;
        }

        &:first-child {
          border-right: none;
        }

        &:last-child {
          border-left: none;
        }
      }

      &:hover {
        td {
          background-color: #fff;
          background-image: none !important;
        }
      }
    }

    td {
      background-color: #fff;
      border: 2px solid #fff;
      border-left: none;
      border-right: none;

      &:first-child,
      &:last-child {
        border: 2px solid #fff;
      }

      &:first-child {
        border-right: none;
      }

      &:last-child {
        border-left: none;
      }
    }

    > tr:hover {
      td {
        background-color: #fff;
        border: 2px solid #7427f1;
        border-left: none;
        border-right: none;

        &:first-child,
        &:last-child {
          border: 2px solid #7427f1;
        }

        &:first-child {
          border-right: none;
        }

        &:last-child {
          border-left: none;
        }
      }
    }
  }
}
</style>
