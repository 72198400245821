<template>
  <MButton
    :icon="btnIcon"
    variant="link"
    label="Link da tela de detalhes da compra"
    class="mt-3 px-0 w-100"
    @click="copyToClipBoard"
    v-b-tooltip.hover.top="
      'Com este link o cliente consegue realizar a troca de cartão da compra e visualizar os detalhes da compra.'
    "
    :disabled="loadingCopyLink"
  />
</template>
<script>
import { mapGetters } from 'vuex';
import ToastHelper from '@/shared/helpers/toast';
import Clipboard from '@/shared/mixins/ClipboardClass';
import PaymentService from '@/services/sparkecommerce/payments.js';

export default {
  mixins: [Clipboard],
  props: {
    public_id: {
      type: Number,
      required: true,
    },
  },
  components: {
    MButton: () => import('@/shared/components/MButton.vue'),
  },
  data: () => ({
    btnIcon: 'link',
    loadingCopyLink: false,
  }),
  computed: {
    ...mapGetters('school', ['getDefaultDomain']),
  },
  methods: {
    async copyToClipBoard() {
      this.btnIcon = 'circle-notch fa-spin';
      this.loadingCopyLink = true;
      let result, url;
      try {
        if (this.$FCL.hasMemberArea()) {
          url = `${this.getDefaultDomain}/orders/${this.public_id}/payments`;
        } else {
          const { public_id } = await PaymentService.getPublicCart(this.public_id);
          url = `${this.getDefaultDomain}/public/orders/${public_id}`;
        }
        result = this.clipboardCopy('cart-details', url);
        if (!result) throw new Error('Exec command failed');
        ToastHelper.successMessage('Link copiado com sucesso');
      } catch (e) {
        ToastHelper.dangerMessage('Erro ao copiar link');
      } finally {
        this.btnIcon = 'link';
        this.loadingCopyLink = false;
      }
    },
  },
};
</script>
