<template>
  <div class="refund-receipt">
    <header class="refund-receipt__header">
      <img src="@/assets/images/logos/herospark-text-logo.svg" alt="HeroSpark" class="refund-receipt__logo" />
      <h1 class="refund-receipt__title">Comprovante de estorno</h1>
    </header>

    <section class="refund-receipt__section">
      <h2 class="refund-receipt__section-title">Dados da Transação</h2>
      <div class="refund-receipt__data-row">
        ID da Transação:
        <span class="refund-receipt__data-value">
          {{ paymentData.paymentId }}
        </span>
      </div>
      <div class="refund-receipt__data-row">
        Data da Transação:
        <span class="refund-receipt__data-value">
          {{ formatDate(paymentData.paidAt) }}
        </span>
      </div>
      <div class="refund-receipt__data-row">
        Nome do Comprador:
        <span class="refund-receipt__data-value">
          {{ buyerName }}
        </span>
      </div>
      <div class="refund-receipt__data-row">
        Valor da Compra:
        <span class="refund-receipt__data-value">
          {{ paymentData.refundedValue }}
        </span>
      </div>
      <div class="refund-receipt__data-row">
        Método de Pagamento:
        <span class="refund-receipt__data-value">
          {{ translatePaymentMethod(paymentData.paymentMethod) }}
        </span>
      </div>
      <div class="refund-receipt__data-row">
        Data do Cancelamento:
        <span class="refund-receipt__data-value">
          {{ formatDate(paymentData.refundedAt) }}
        </span>
      </div>
      <div class="refund-receipt__data-row">
        Valor do Estorno:
        <span class="refund-receipt__data-value">
          {{ paymentData.refundedValue }}
        </span>
      </div>
    </section>

    <section class="refund-receipt__section">
      <h2 class="refund-receipt__section-title">Dados da Empresa</h2>
      <div class="refund-receipt__data-row">
        Nome da Empresa: <span class="refund-receipt__data-value">{{ selectedSchool.name }}</span>
      </div>
      <div class="refund-receipt__data-row">
        Número do Documento: <span class="refund-receipt__data-value">{{ schoolDocument }}</span>
      </div>
      <div class="refund-receipt__data-row">
        Contato: <span class="refund-receipt__data-value">{{ selectedSchool.email }}</span>
      </div>
    </section>

    <p class="refund-receipt__info">
      <strong class="refund-receipt__info-highlight">Informações importantes:</strong>

      <template v-if="isCreditCard">
        Após a conclusão do estorno, o banco do titular decide como devolver o valor, podendo: estornar o total na
        fatura seguinte e manter a cobrança das parcelas, cobrar todas as parcelas e estornar o total na última fatura,
        ou realizar cobrança e estorno simultâneo na mesma fatura, podendo dividir o estorno ao longo das faturas
        seguintes em casos parciais.
        <br /><br />
        Para mais detalhes, consulte o banco emissor do cartão.
      </template>
      <template v-else>
        O estorno de pagamentos via {{ translatePaymentMethod(paymentData.paymentMethod) }}
        será reembolsado em até 3 dias úteis diretamente na conta bancária utilizada para o pagamento.
      </template>
    </p>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import GeneralHelper from '@/shared/helpers/general';
import { format } from '@/shared/helpers/dates';

export default {
  name: 'RefundReceiptTemplate',
  props: {
    paymentData: {
      type: Object,
      required: true,
    },
    buyerName: {
      type: String,
      required: true,
    },
  },
  methods: {
    formatDate(date) {
      return format(date, 'DD/MM/YYYY');
    },
    translatePaymentMethod(method) {
      const methodsMap = {
        pix: 'PIX',
        credit_card: 'Cartão de Crédito',
        bank_slip: 'Boleto Bancário',
      };

      return methodsMap[method] || method;
    },
  },
  computed: {
    ...mapState('school', ['selectedSchool']),
    schoolDocument() {
      if (this.selectedSchool.cnpj) {
        return GeneralHelper.cnpj(this.selectedSchool.cnpj);
      }

      if (this.selectedSchool.cpf) {
        return GeneralHelper.cpf(this.selectedSchool.cpf);
      }

      return '';
    },
    isCreditCard() {
      return this.paymentData.paymentMethod === 'credit_card';
    },
  },
};
</script>

<style scoped>
.refund-receipt {
  color: #262626;
  max-width: 800px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  row-gap: 24px;
}

.refund-receipt__header {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 20px;
}

.refund-receipt__logo {
  width: 133px;
}

.refund-receipt__title {
  font-size: 1.25rem;
  font-weight: 700;
  color: #262626;
  margin: 0;
}

.refund-receipt__section {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
}

.refund-receipt__section-title {
  font-size: 1rem;
  font-weight: 700;
  color: #262626;
}

.refund-receipt__data-row {
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.refund-receipt__data-value {
  color: #262626;
  font-weight: 700;
}

.refund-receipt__info {
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: #262626;
}

.refund-receipt__info-highlight {
  font-weight: 700;
}
</style>
