<template>
  <div class="recurrency-sales-table" :class="{ 'recurrency-sales-table--sidebar-open': sidebarIsOpen }">
    <hs-table
      ref="salesTable"
      hover
      responsive
      selectable
      :fields="fields"
      :items="sales"
      select-mode="single"
      @selectedItems="sale => selectPurchase(sale)"
    >
      <template slot="creation_date" slot-scope="row">
        {{ formatDate(row.item.purchasedAt) }}
      </template>

      <template slot="product" slot-scope="row">
        <hs-badge
          v-if="row.item.orderBumpUsed && row.item.orderBumpItems.length"
          variant="outline-purple"
          data-testid="order-bump-badge"
          pill
        >
          {{ $t('sparkpay.sales.detailed-sales-sidebar.order-bump') }}
        </hs-badge>
        <div class="sales-table__product-name">{{ row.item.productName }}</div>
      </template>

      <template slot="name" slot-scope="row">
        {{ row.item.buyer.fullName }}

        <div class="tlw-mt-1">
          {{ row.item.buyer.email }}
        </div>
      </template>

      <template slot="status" slot-scope="row">
        <div class="recurrency-sales-table__status-wrapper">
          <Tag
            :label="getStatusLabel('recurrency', row.item.subscriptionStatus)"
            :variant="getStatusVariant('recurrency', row.item.subscriptionStatus)"
          />

          {{ $t(`sparkpay.sales.sales-table.kinds.${row.item.paymentMethod}`) }}
        </div>
      </template>

      <template slot="recurring_value" slot-scope="row">
        {{
          $t(`sparkpay.sales.sales-table.periodicity-value.${row.item.subscriptionPeriod}`, {
            value: row.item.totalNetValue,
          })
        }}
      </template>
    </hs-table>

    <RecurrencySalesDrawer
      v-if="sidebarIsOpen"
      :key="selectedPurchase.subscriptionId"
      :sale="selectedPurchase"
      @close="closeSidebar()"
    />
  </div>
</template>

<script>
import dayjs from 'dayjs';
import RecurrencySalesDrawer from '@/sparkpay/views/Sales/components/RecurrencySalesDrawer.vue';
import Tag from '@/components/MTag.vue';
import StatusMixin from '@/mixins/StatusMixin';

export default {
  name: 'RecurrencySalesTable',
  mixins: [StatusMixin],
  props: {
    fields: {
      required: true,
      type: Array,
    },
    sales: {
      required: true,
      type: Array,
    },
  },
  components: {
    RecurrencySalesDrawer,
    Tag,
  },
  data() {
    return {
      sidebarIsOpen: false,
      selectedPurchase: null,
    };
  },
  methods: {
    closeSidebar() {
      this.selectedPurchase = null;
      this.sidebarIsOpen = false;
    },
    selectPurchase(purchase) {
      if (!purchase.length) return;

      this.selectedPurchase = purchase[0];
      this.sidebarIsOpen = true;
    },
    getBadgeVariant(status) {
      const statusMap = {
        initiated: 'secondary',
        processing: 'warning',
        waiting_payment: 'warning',
        no_wait_processing: 'warning',
        confirmed: 'success',
        active: 'success',
        abandoned: 'cherry',
        canceled: 'cherry',
        subscription_canceled: 'cherry',
        overdue: 'warning',
      };

      return statusMap[status];
    },
    formatDate(date) {
      const dayjsDate = dayjs(date);
      return dayjsDate.format('DD/MM/YYYY HH:mm');
    },
  },
};
</script>

<style lang="scss">
.recurrency-sales-table {
  .table.b-table {
    th {
      color: var(--color-gray-800);
    }

    th[aria-sort] {
      .sorting {
        &::after {
          content: '\f15d';
        }
      }
    }

    th[aria-sort='descending'] {
      .sorting {
        color: $cyan;

        &::after {
          content: '\f882';
        }
      }
    }

    th[aria-sort='ascending'] {
      .sorting {
        color: $cyan;

        &::after {
          content: '\f15d';
        }
      }
    }
  }
}

.recurrency-sales-table--sidebar-open {
  width: calc(100% - 320px);
  transition: width 0.3s cubic-bezier(1, 0.5, 0.8, 1);

  @media screen and (max-width: 576px) {
    width: 100%;
  }
}

.recurrency-sales-table__product-name {
  margin-top: 8px;
}

.recurrency-sales-table__status-wrapper {
  display: grid;
  grid-template-columns: 1fr;
}

.recurrency-sales-table__status-badge {
  width: fit-content;
}

.table.b-table {
  tbody {
    > .table-active {
      > td {
        border: 2px solid #7427f1;
        border-left: none;
        border-right: none;
        background-color: #fff;

        &:first-child,
        &:last-child {
          border: 2px solid #7427f1;
        }

        &:first-child {
          border-right: none;
        }

        &:last-child {
          border-left: none;
        }
      }

      &:hover {
        td {
          background-color: #fff;
          background-image: none !important;
        }
      }
    }

    td {
      background-color: #fff;
      border: 2px solid #fff;
      border-left: none;
      border-right: none;

      &:first-child,
      &:last-child {
        border: 2px solid #fff;
      }

      &:first-child {
        border-right: none;
      }

      &:last-child {
        border-left: none;
      }
    }

    > tr:hover {
      td {
        background-color: #fff;
        border: 2px solid #7427f1;
        border-left: none;
        border-right: none;

        &:first-child,
        &:last-child {
          border: 2px solid #7427f1;
        }

        &:first-child {
          border-right: none;
        }

        &:last-child {
          border-left: none;
        }
      }
    }
  }
}
</style>
