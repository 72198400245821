




















import { Vue, Component } from 'vue-property-decorator';
import MongoReport from './components/MongoReport.vue';

@Component({
  components: {
    AutoPilotBanner: () => import('@/components/AutoPilotBanner.vue'),
    MongoReport,
  },
})
export default class SalesMadeReport extends Vue {
  translateKey = 'menu.sparkpay.sales.sales_made_report';

  get canAutomaticPilot() {
    return this.$ACL.canAutomaticPilotPage();
  }
}
