


















































































































































import { Component, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import TrackingHelper from '@/shared/helpers/tracking';
import SparkEcommerceApiClient from '@/services/sparkecommerce/api_client.js';
import ToastHelper from '@/shared/helpers/toast';
import MSwitch from '@/shared/components/MSwitch.vue';
import MButton from '@/shared/components/MButton.vue';
import { EmitReportTypes, EmitInvoiceFeesTypes } from './types';

const SaleModule = namespace('sale');

@Component({
  components: {
    MSwitch,
    MButton,
    RemoveIntegrationModal: () => import('./RemoveIntegrationModal.vue'),
  },
})
export default class InvoiceConfigSidebar extends Vue {
  @SaleModule.Action setSidebarInvoiceEnabled!: (status: boolean) => void;

  translatePrefix = 'menu.sparkpay.sales.invoice_report.invoice_sidebar';
  displayDisableButton: boolean = false;
  enotasApiKey: string | null = null;
  emitInvoiceReport: string = EmitReportTypes.PAYMENT_CONFIRMED;
  emitInvoiceFees: string = EmitInvoiceFeesTypes.INSTALLMENT_FEES;
  emitInvoiceReportOptions: typeof EmitReportTypes = EmitReportTypes;
  emitInvoiceFeesOptions: typeof EmitInvoiceFeesTypes = EmitInvoiceFeesTypes;
  sendInvoiceByEmail: boolean = true;
  cancelInvoiceOnRefund: boolean = true;
  disableInvoiceByEmail: boolean = false;
  isLoading: boolean = false;
  modalId: string = 'remove-integration-modal';

  async mounted() {
    await SparkEcommerceApiClient.invoiceSettings
      .get()
      .then(data => {
        const {
          api_key,
          emit_invoice_when,
          invoice_canceled_build_refund,
          send_invoice_email,
          enabled,
          price_format_to_send,
        } = data;

        this.displayDisableButton = enabled;
        this.enotasApiKey = api_key;
        this.emitInvoiceReport = emit_invoice_when;
        this.emitInvoiceFees = price_format_to_send;
        this.cancelInvoiceOnRefund = invoice_canceled_build_refund;
        this.sendInvoiceByEmail = send_invoice_email;
      })
      .catch(() => {
        ToastHelper.dangerMessage('Erro!', 'Erro ao carregar configurações');
      });
  }

  get enableSaveButton() {
    return Boolean(this.enotasApiKey) && !this.isLoading;
  }

  openRemoveModal() {
    this.$bvModal.show(this.modalId);
  }

  async saveConfigs() {
    try {
      this.isLoading = true;

      const payload = {
        api_key: this.enotasApiKey,
        emit_invoice_when: this.emitInvoiceReport,
        price_format_to_send: this.emitInvoiceFees,
        send_invoice_email: this.sendInvoiceByEmail,
        invoice_canceled_build_refund: this.cancelInvoiceOnRefund,
        enabled: true,
      };

      await SparkEcommerceApiClient.invoiceSettings.upsert(payload);

      TrackingHelper.trackEnotasConfigEnabled();

      ToastHelper.successMessage('Integração concluída!', 'A integração com o eNotas foi concluída com sucesso.');
    } catch {
      ToastHelper.dangerMessage('Erro!', 'Erro ao salvar configurações');
    } finally {
      this.isLoading = false;
      this.setSidebarInvoiceEnabled(false);
    }
  }

  async removeIntegration() {
    try {
      const payload = {
        api_key: null,
        emit_invoice_when: EmitReportTypes.PAYMENT_CONFIRMED,
        price_format_to_send: EmitInvoiceFeesTypes.INSTALLMENT_FEES,
        send_invoice_email: true,
        invoice_canceled_build_refund: true,
        enabled: false,
      };

      await SparkEcommerceApiClient.invoiceSettings.upsert(payload);

      this.setConfigsToDefault();
      ToastHelper.successMessage('Integração removida!', 'A integração com o eNotas foi removida com sucesso.');
    } catch {
      ToastHelper.dangerMessage('Erro!', 'Erro ao salvar configurações');
    } finally {
      this.setSidebarInvoiceEnabled(false);
    }
  }

  setConfigsToDefault() {
    this.enotasApiKey = null;
  }

  @Watch('emitInvoiceReport')
  onEmitInvoiceReportChange(autoEmit) {
    if (autoEmit === EmitReportTypes.NEVER) {
      this.sendInvoiceByEmail = false;
      this.disableInvoiceByEmail = true;
      return;
    }

    this.disableInvoiceByEmail = false;
  }
}
