<template>
  <div>
    <WithdrawlsTable v-if="hasWithdrawals" :loading="isLoading" :withdrawals="withdrawals" />
    <div v-else-if="isLoading">
      <hs-placeholder animated width="100%" height="74px" />
      <br />
      <hs-placeholder animated width="100%" height="74px" />
      <br />
      <hs-placeholder animated width="100%" height="74px" />
    </div>
    <div class="empty-state" v-else>
      <img class="" src="@/assets/images/coin.svg" />
      <h4>Você ainda não realizou nenhum saque</h4>
      <p>Quando fizer seu primeiro saque, ele aparecerá aqui.</p>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

import WithdrawlsTable from '../components/WithdrawlsTable.vue';

import sparkpay from '@/sparkpay/services/sparkpay';
import toastHelper from '@/shared/helpers/toast';
import GeneralHelper from '@/shared/helpers/general';

export default {
  name: 'WalletWithdrawals',
  components: {
    WithdrawlsTable,
  },
  mounted() {
    this.getWithdrawls();
  },
  data() {
    return {
      isLoading: false,
      withdrawals: [],
    };
  },
  computed: {
    ...mapState('school', ['selectedSchool']),
    hasWithdrawals() {
      return this.withdrawals.length > 0;
    },
  },
  methods: {
    formartDate(date) {
      const [datePart, timePart] = date.split(' - ');
      const [day, month, year] = datePart.split('/');
      const [hours, minutes] = timePart.split(':');

      return new Date(`${year}-${month}-${day}T${hours}:${minutes}`);
    },
    async getWithdrawls() {
      this.isLoading = true;

      try {
        const withdrawsResponse = await sparkpay.withdrawList();

        this.withdrawals = withdrawsResponse.withdrawals
          ? withdrawsResponse.withdrawals.map(withdrawal => {
              return {
                ...withdrawal,
                created_at: this.formartDate(withdrawal.created_at),
                amount_cents: GeneralHelper.currency(withdrawal.amount_cents / 100),
              };
            })
          : [];
      } catch (error) {
        toastHelper.dangerMessage('Não foi possível obter os seus saques. Por favor, tente novamente mais tarde.');
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.empty-state {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h4 {
    font-size: 16px;
    font-weight: bold;
  }

  p {
    font-size: 14px;
  }
}
</style>
