<template>
  <div class="banner">
    <slot>
      <img src="@/assets/images/question.svg" alt="Question" />
      <div>
        <h4>{{ title }}</h4>
        <a :href="link" target="_blank">{{ linkText }}</a>
      </div>
    </slot>
  </div>
</template>

<script>
export default {
  name: 'TipsReportBanner',
  props: {
    title: {
      type: String,
    },
    link: {
      type: String,
    },
    linkText: {
      type: String,
    },
  },
};
</script>

<style scoped lang="scss">
.banner {
  background-color: #fff;
  padding: 20px;
  border-radius: 4px;
  box-shadow: 0px 4px 8px 0px #3f3f3f14;

  display: flex;
  align-items: center;
  gap: 12px;

  h4 {
    margin: 0;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
  }

  a {
    color: #7427f1;
    text-decoration: underline;
    font-size: 14px;
    line-height: 21px;

    &:hover {
      color: #35126e;
    }
  }
}

@media screen and (max-width: $screen-bs-md) {
  .banner {
    align-items: flex-start;
  }
}
</style>
