<template>
  <div class="recurrency-sales-drawer">
    <div class="recurrency-sales-drawer__close-button">
      <hs-button variant="link" @click="$emit('close')">
        <hs-icon icon="times" />
      </hs-button>
    </div>
    <div class="recurrency-sales-drawer__content">
      <Tag
        :label="getStatusLabel('recurrency', sale.subscriptionStatus)"
        :variant="getStatusVariant('recurrency', sale.subscriptionStatus)"
      />

      <div class="recurrency-sales-drawer__general-info">
        <div class="recurrency-sales-drawer__buyer-name">
          {{ sale.buyer.fullName }}
        </div>

        <div class="recurrency-sales-drawer__general-info-row">
          <div class="recurrency-sales-drawer__general-info-label">
            {{ $t('sparkpay.sales.detailed-sales-sidebar.email') }}:
          </div>

          <div class="recurrency-sales-drawer__general-info-data">
            {{ sale.buyer.email }}
          </div>
        </div>

        <div class="recurrency-sales-drawer__general-info-row">
          <div class="recurrency-sales-drawer__general-info-label">
            {{ $t('sparkpay.sales.detailed-sales-sidebar.cpf') }}:
          </div>

          <div class="recurrency-sales-drawer__general-info-data">
            {{ formatCPF(sale.buyer.document) }}
          </div>
          <hs-button v-if="sale.buyer.document" variant="link" size="sm" @click="() => (showCpf = !showCpf)">
            <hs-icon variant="light" :icon="`eye${showCpf ? '-slash' : ''}`" />
          </hs-button>
        </div>

        <div v-if="sale.buyer.phone" data-testid="buyer-phone" class="recurrency-sales-drawer__general-info-row">
          <div class="recurrency-sales-drawer__general-info-label">
            {{ $t('sparkpay.sales.detailed-sales-sidebar.phone') }}:
          </div>

          <div class="recurrency-sales-drawer__general-info-data">+{{ sale.buyer.ddi }} {{ sale.buyer.phone }}</div>

          <a
            data-testid="whatsapp-link"
            :href="getWhatsappLink(sale.buyer.phone, '', sale.buyer.ddi)"
            target="_blank"
            aria-label="Abrir conversa no WhatsApp"
            class="tlw-ml-2"
            @click="trackWhatsappEvent"
          >
            <i
              class="fab fa-whatsapp recurrency-sales-drawer__whatsapp-icon"
              aria-hidden="true"
              title="Abrir conversa no WhatsApp"
            />
          </a>
        </div>
      </div>

      <hr class="recurrency-sales-drawer__divider" />

      <RecurrencySalesDrawerDetails :sale="sale" />

      <hr class="recurrency-sales-drawer__divider" />

      <MXButton
        v-if="isMainProducer"
        variant="secondary"
        class="recurrency-sales-drawer__see-details-button"
        behavior="block"
        @click="goToSalesDetails"
      >
        Ver detalhes
      </MXButton>

      <CopyPublicLinkButton v-if="sale.cartId" :public_id="sale.cartId" />
    </div>
  </div>
</template>

<script>
import { analyticsService } from '@/services';
import GeneralHelper from '@/shared/helpers/general';
import { getWhatsappLink } from '@/shared/helpers/phone';
import MXButton from '@/shared/components/MXButton.vue';
import Tag from '@/components/MTag.vue';
import StatusMixin from '@/mixins/StatusMixin';

export default {
  name: 'RecurrencySalesDrawer',
  mixins: [StatusMixin],
  props: {
    sale: {
      type: Object,
      required: true,
    },
  },
  components: {
    RecurrencySalesDrawerDetails: () => import('@/sparkpay/views/Sales/components/RecurrencySalesDrawerDetails.vue'),
    CopyPublicLinkButton: () => import('./CopyPublicLinkButton.vue'),
    MXButton,
    Tag,
  },
  data() {
    return {
      showCpf: false,
    };
  },
  computed: {
    infoTaxText() {
      return this.$t('sparkpay.sales.detailed-sales-sidebar.info-tax');
    },
    isPaymentCanceledOrRefunded() {
      return ['canceled', 'refund_analyzing', 'refunded'].includes(this.sale.subscriptionStatus);
    },
    isMainProducer() {
      return this.sale.participation === 'producer';
    },
  },
  methods: {
    goToSalesDetails() {
      const route = this.$router.resolve({ name: 'SalesDetails', params: { id: this.sale.mongoDocumentId } });
      window.open(route.href, '_blank');
    },
    formatCPF(cpf) {
      return cpf ? GeneralHelper.cpf(cpf, !this.showCpf) : '-';
    },
    trackWhatsappEvent() {
      analyticsService.track({
        event: 'Whatsapp button clicked',
        props: {
          location: 'sales_list',
        },
      });
    },
    getWhatsappLink,
  },
};
</script>

<style lang="scss" scoped>
.recurrency-sales-drawer {
  position: fixed;
  right: 0;
  width: 350px;
  min-height: calc(100% - 69px);
  z-index: 2;
  background-color: $white;
  padding: 24px;
  top: 69px;
  box-shadow: -1px 2px 8px rgba(0, 0, 0, 0.25);

  @media screen and (max-width: 576px) {
    width: 100%;
    top: 0;
    box-shadow: none;
    padding-top: 70px;
    height: 100vh;
  }
}

.recurrency-sales-drawer__close-button {
  position: absolute;
  right: 24px;
}

.recurrency-sales-drawer__content {
  width: 100%;
  height: calc(100vh - 110px);
  overflow-y: auto;
}

.recurrency-sales-drawer__general-info {
  display: flex;
  flex-direction: column;
  margin-top: 16px;
}

.recurrency-sales-drawer__general-info-row {
  margin-top: 8px;
  display: flex;
  align-items: center;
}

.recurrency-sales-drawer__general-info-label {
  font-size: 0.875rem;
  color: #6f6f6f;
  width: 65px;
}

.recurrency-sales-drawer__general-info-data {
  margin-left: 8px;
  font-size: 0.875rem;
  color: $grey;
  font-weight: 600;
}

.recurrency-sales-drawer__buyer-name {
  color: $grey;
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 2rem;
}

.recurrency-sales-drawer__divider {
  border-color: var(--color-gray-100);
  width: 100%;
  margin-top: 16px;
  margin-bottom: 16px;
}

.recurrency-sales-drawer__whatsapp-icon {
  color: #6f6f6f;
}

.recurrency-sales-drawer__extra-info {
  font-size: 0.875rem;
  color: $grey;
}

.recurrency-sales-drawer__refund-action {
  margin-top: 24px;

  &:disabled {
    background-color: #e3e3e3;
    border: 2px solid #bababa;
    color: #bababa;
  }
}
</style>
