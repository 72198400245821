<template>
  <div class="sales-strategy-list">
    <Filters :filters="filters" @changed="mountUnitsList(1)" />

    <div v-if="showNoSalesWarning" class="sales-strategy-no-sales">
      <img :src="require('@/assets/images/robot-purple-dollar.svg')" />

      <div class="sales-strategy-no-sales__title">
        {{ $t('sparkpay.sales.smart-installments.no-sales.title') }}
      </div>

      <div class="sales-strategy-no-sales__description">
        {{ $t('sparkpay.sales.smart-installments.no-sales.description') }}
      </div>

      <div class="sales-strategy-no-sales__extra-info">
        {{ $t('sparkpay.sales.smart-installments.no-sales.extra-info') }}
      </div>
    </div>
    <template v-else>
      <SalesStrategyCardList :sales-list="strategySales" class="sales-strategy-list__mobile-only" />
      <SalesTable
        :sales="strategySales"
        :fields="fields"
        page="sales-strategy"
        class="sales-strategy-list__desktop-only"
      />

      <div v-if="isTotalNumberOfSubscriptionsHigherThanTen" class="tlw-flex flex-fill tlw-flex-col tlw-justify-end">
        <hs-pagination
          v-model="currentPage"
          :go-page-text="$t('sparkpay.sales.pagination.go-to-page')"
          :per-page="10"
          :total-rows="totalNumberOfSubscriptions"
          align="center"
        />
      </div>
    </template>
  </div>
</template>

<script>
import orderService from '@/services/order';
import Filters from '@/components/Filters/index.vue';
import GeneralHelper from '@/shared/helpers/general';
import ToastHelper from '@/shared/helpers/toast';
import SalesTable from '@/sparkpay/views/Sales/components/SalesTable.vue';
import SalesStrategyCardList from '@/sparkpay/views/Sales/SalesStrategyList/SalesStrategyCardList.vue';
import FilterMixin from '@/sparkpay/mixins/FilterMixin';
import StatusMixin from '@/mixins/StatusMixin';

export default {
  name: 'SalesStrategyList',
  mixins: [FilterMixin, StatusMixin],
  components: {
    SalesTable,
    Filters,
    SalesStrategyCardList,
  },
  data() {
    return {
      isLoading: false,
      strategySales: [],
      totalNumberOfSubscriptions: 0,
      currentPage: 1,
      fields: [
        { key: 'created_at', label: 'Data' },
        { key: 'product', label: 'Produto' },
        { key: 'name', label: 'Cliente' },
        { key: 'status', label: 'Status' },
        { key: 'total_collected', label: 'Valor Recebido' },
        { key: 'options', label: '' },
      ],
      filters: [
        {
          type: 'Main',
          key: 'full_name',
          label: this.$t('sparkpay.sales.filters.full-name.smart-installment-label'),
          value: '',
        },
        {
          type: 'MultiSelect',
          key: 'status',
          label: this.$t('sparkpay.sales.filters.status.label'),
          multiple: true,
          options: [],
          value: null,
        },
        {
          type: 'MultiSelect',
          key: 'type',
          label: this.$t('sparkpay.sales.filters.kind.label'),
          multiple: false,
          options: [
            {
              item: {
                label: this.$t('sparkpay.sales.filters.kind.options.credit_card.label'),
                value: 'credit_card',
              },
            },
            {
              item: {
                label: this.$t('sparkpay.sales.filters.kind.options.boleto.label'),
                value: 'bank_slip',
              },
            },
            {
              item: {
                label: this.$t('sparkpay.sales.filters.kind.options.pix.label'),
                value: 'pix',
              },
            },
          ],
          value: null,
        },
        {
          type: 'MultiSelect',
          key: 'product_id',
          label: this.$t('sparkpay.sales.filters.product.label'),
          multiple: false,
          options: [],
          value: null,
        },
        {
          type: 'Date',
          key: 'created_at_start',
          label: 'Data de criação inicial',
          value: null,
        },
        {
          type: 'Date',
          key: 'created_at_end',
          label: 'Data de criação final',
          value: null,
        },
      ],
    };
  },
  methods: {
    mountUnitsList(page) {
      this.isLoading = true;
      orderService
        .getStrategySalesOrders([
          { key: 'per_page', value: '10' },
          { key: 'page', value: page },
          ...this.parseFiltersToParams(),
        ])
        .then(res => {
          this.strategySales = this.parseSubscriptions(res.orders);
          this.totalNumberOfSubscriptions = res.total_count;
        })
        .catch(() => ToastHelper.dangerMessage(this.$t('sparkpay.sales.mount-sales-list-error')))
        .finally(() => {
          this.isLoading = false;
        });
    },
    parseSubscriptions(subscriptions) {
      return subscriptions.map(p => ({
        total_installments: p.total_installments,
        paid_installments: p.paid_installments,
        total_collected: GeneralHelper.currency(p.total_collected / 100),
        id: p.id,
        total: GeneralHelper.currency(p.amount_to_pay / 100),
        total_fees: p.total_fees ? GeneralHelper.currency(p.total_fees / 100) : null,
        total_receive: p.net_value_cents ? GeneralHelper.currency(p.net_value_cents / 100) : null,
        net_value_cents: p.total_net_value_cents,
        base_price: GeneralHelper.currency(p.total_amount_cents / 100),
        refund_price: GeneralHelper.currency(p.total_amount_to_be_paid_cents / 100),
        discount: GeneralHelper.currency((p.total - p.amount_to_pay) / 100),
        name: p.student ? `${p.student.first_name} ${p.student.last_name !== null ? p.student.last_name : ''}` : '—',
        email: p.student ? p.student.email : '—',
        phone: p.student ? p.student.phone : null,
        ddi: p.student ? p.student.ddi : null,
        cpf: p.student ? p.student.cpf : null,
        product: p.products && p.products[0] ? p.products[0].title : '—',
        created_at: p.created_at,
        payment_method: p.payments ? p.payments[0].kind : null,
        kind: p.kind,
        payment_id: p.payments ? p.payments[0].gateway_reference : '—',
        status: p.status,
        coupon: p.coupon,
        coupon_code: p.coupon_code,
        error_message: p.error_message,
        order_bump_used: p.order_bump_used,
        order_bump_items: p.other_items,
        participation: p.participation,
        affiliate_fees_cents: p.affiliate_fees_cents,
        public_id: p.public_id,
        affiliate_fees: GeneralHelper.currency(p.affiliate_fees_cents / 100),
        coproduction_fees_cents: p.coproduction_fees_cents,
        coproduction_fees: GeneralHelper.currency(p.coproduction_fees_cents / 100),
        coproductions_fees_cents: p.coproductions_fees_cents,
        coproductions_fees: GeneralHelper.currency(p.coproductions_fees_cents / 100),
        installments_text: p.installments_text,
        is_strategy_sales: true,
        refund_limit_date: p.refund_limit_date,
        refund_limit_days: p.refund_limit_days,
        refunded_at: p.refunded_at,
        refund_request_at: p.refund_request_at,
        enable_refund: p.enable_refund,
        refund_payment_id: p.refund_payment_id,
        buyer_id: p.buyer.core_student_id,
      }));
    },
  },
  computed: {
    showNoSalesWarning() {
      return !this.isLoading && !this.strategySales.length;
    },
    isTotalNumberOfSubscriptionsHigherThanTen() {
      return this.totalNumberOfSubscriptions > 10;
    },
  },
  created() {
    this.mountUnitsList(1);
    this.loadProductsOptions(1, true);
  },
  mounted() {
    const strategySalesStatus = {
      ...this.statusTypes.smartInstallment,
      ...this.statusTypes.installments,
    };

    const statusInstallmentTypes = Object.entries(strategySalesStatus).map(([key, value]) => {
      return {
        item: {
          label: value.key,
          value: key,
        },
      };
    });

    this.filters.find(filter => filter.key === 'status').options = statusInstallmentTypes;
  },
  watch: {
    currentPage(newValue) {
      this.mountUnitsList(newValue);
    },
  },
};
</script>

<style lang="scss">
.sales-strategy-list {
  height: 100%;
  padding: 24px 16px;
  display: flex;
  flex-direction: column;

  @media (min-width: $screen-md) {
    padding: 24px 32px;
  }
}

.sales-strategy-list__mobile-only {
  width: 100%;

  @media (min-width: $screen-md) {
    display: none;
  }
}

.sales-strategy-list__desktop-only {
  display: none;

  @media (min-width: $screen-md) {
    display: block;
    width: 100%;
  }
}

.sales-strategy-no-sales {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 480px;
  margin-top: 40px;
  align-self: center;
}

.sales-strategy-no-sales__title {
  font-size: 1.5rem;
  line-height: 1.875rem;
  font-weight: 700;
  color: $grey;
  margin-top: 24px;
}

.sales-strategy-no-sales__description {
  font-size: 1rem;
  line-height: 1.5rem;
  color: $grey;
  margin-top: 16px;
  text-align: center;
}

.sales-strategy-no-sales__extra-info {
  font-size: 0.75rem;
  line-height: 1.125rem;
  text-align: center;
  color: #525252;
  margin-top: 8px;
}
</style>
