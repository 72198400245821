import TokenHelper from '@/shared/helpers/token';
import axios from 'axios';
import { checkUnauthorizedResponse } from '@/libs/authentication';

const jarvisClient = axios.create({
  baseURL: process.env.VUE_APP_JARVIS_URL,
});

jarvisClient.interceptors.request.use(
  config => {
    config.headers['Content-Type'] = 'application/json';
    const token = TokenHelper.getToken();

    if (token) {
      config.headers['Authorization'] = token;
    }

    return config;
  },
  error => Promise.reject(error)
);

jarvisClient.interceptors.response.use(response => response, checkUnauthorizedResponse);

export { jarvisClient };
