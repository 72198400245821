<template>
  <section class="tlw-flex tlw-relative flex-fill tlw-flex-col">
    <div class="flex-fill tlw-flex tlw-flex-col">
      <hsPageHeader
        :title="$t('sparkpay.sales.details.header.title')"
        :back-text="$t('sparkpay.sales.details.header.subtitle')"
        :back-only-mobile="false"
        back-route="SubscriptionsList"
      >
      </hsPageHeader>
      <hsLoading v-if="isLoading" />
      <template v-else>
        <SalesBoxUserInfo
          :status="sale.subscriptionStatus"
          :buyerName="sale.buyer.fullName"
          :buyerEmail="sale.buyer.email"
          :buyerDocument="sale.buyer.document"
          :subscriptionId="sale.subscriptionId"
          :createdAt="sale.purchasedAt"
          :productName="sale.productName"
          page="recurrency"
        >
          <template v-if="isNotCancelled" #action>
            <div class="sales-details-action-wrapper">
              <m-button
                class="user-info__action"
                variant="critical-outline"
                @click="() => $bvModal.show(modalCancelId)"
              >
                {{ $t('sparkpay.sales.details.info-box-student.btn-cancel-subscription') }}
              </m-button>

              <CopyContentButton
                v-if="isCreditCardSubscription"
                :content="salesDetailsLink"
                button-text="Copiar link de troca de cartão"
                success-message="Link copiado com sucesso, compartilhe com seu cliente!"
              />
            </div>
          </template>
        </SalesBoxUserInfo>
        <CardsInfoSubscription :sale="sale" />
        <PaymentsTable
          v-if="hasSaleTransactions"
          :sales="orderedByDueAtPayments"
          :subscription="sale"
          @getOrder="getOrder()"
        />
        <b-modal
          :id="modalCancelId"
          size="md"
          centered
          modalClass="cancel-subscription-modal"
          :hideHeader="true"
          hide-footer
        >
          <CancelModalSubscription :sale="sale" @onSuccess="openSuccessModal" />
        </b-modal>
        <b-modal
          :id="modalCancelSuccessId"
          size="md"
          centered
          modalClass="cancel-subscription-modal"
          :hideHeader="true"
          hide-footer
        >
          <CancelModalSubscriptionSuccess :sale="sale" @on-success="getOrder" />
        </b-modal>
      </template>
    </div>
  </section>
</template>

<script>
import { mapGetters } from 'vuex';
import hsPageHeader from '@/components/_structures/PageHeader';
import SalesBoxUserInfo from '@/sparkpay/views/Sales/components/SalesBoxUserInfo.vue';

import CardsInfoSubscription from './components/CardsInfoSubscription';
import PaymentsTable from './components/PaymentsTable';
import { hsLoading } from '@/components';
import paymentsService from '@/services/sparkecommerce/payments';
import { PaymentMethodsList } from '@/types/payments';

import CancelModalSubscription from './components/CancelModalSubscription';
import CancelModalSubscriptionSuccess from './components/CancelModalSubscriptionSuccess';
import MButton from '@/shared/components/MButton.vue';
import CopyContentButton from '@/components/CopyContentButton.vue';

import StatusMixin from '@/mixins/StatusMixin';

export default {
  name: 'SalesDetails',
  components: {
    hsPageHeader,
    SalesBoxUserInfo,
    CardsInfoSubscription,
    PaymentsTable,
    hsLoading,
    CancelModalSubscription,
    CancelModalSubscriptionSuccess,
    MButton,
    CopyContentButton,
  },
  mixins: [StatusMixin],
  data() {
    return {
      sale: {},
      isLoading: true,
      modalCancelId: 'sale-view-cancel',
      modalCancelSuccessId: 'sale-view-cancel-success',
      public_id: '',
    };
  },
  created() {
    if (!this.$FCL.canMembersSubscriptionOffers()) {
      this.$router.push({ name: 'Sales' });
    }
  },
  async mounted() {
    await this.getOrder(this.$route.params.id);
    await this.getPublicId();
  },
  computed: {
    ...mapGetters('school', ['getDomainWithHttp']),
    hasSaleTransactions() {
      return this.sale.transactions.length > 0;
    },
    salesDetailsLink() {
      return this.$FCL.hasMemberArea()
        ? `${this.getDomainWithHttp}/orders/${this.sale.cartId}/payments`
        : `${this.getDomainWithHttp}/public/orders/${this.public_id}`;
    },
    isNotCancelled() {
      const cancelledStatuses = this.getRecurrencyCancelledStatuses();

      return !cancelledStatuses.includes(this.sale.subscriptionStatus);
    },
    isCreditCardSubscription() {
      return this.sale.paymentMethod === PaymentMethodsList.CREDIT_CARD;
    },
    orderedByDueAtPayments() {
      const paymentsArray = [...this.sale.transactions];

      return paymentsArray.sort((a, b) => {
        const aDate = new Date(this.getPaymentDate(a));
        const bDate = new Date(this.getPaymentDate(b));

        return aDate - bDate;
      });
    },
  },
  methods: {
    async getPublicId() {
      const { public_id } = await paymentsService.getPublicCart(this.sale.cartId);
      this.public_id = public_id;
    },
    getPaymentDate(payment) {
      const { paymentMethod, dueAt, bankSlipExpirationDate, pixExpirationAt } = payment;

      if (paymentMethod === PaymentMethodsList.BANK_SLIP) return bankSlipExpirationDate;
      if (paymentMethod === PaymentMethodsList.PIX) return pixExpirationAt;

      return dueAt;
    },
    async getOrder(id = this.$route.params.id) {
      this.sale = await paymentsService.getRecurrencySaleById(id);
      this.isLoading = false;
    },
    openSuccessModal() {
      this.$bvModal.show(this.modalCancelSuccessId);
    },
  },
};
</script>

<style lang="scss" scoped>
.user-info__action {
  width: 100%;
}

.sales-details-action-wrapper {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
}
</style>
