<template>
  <div class="recurrency-payment-details">
    <div class="recurrency-payment-details__title">
      Detalhes
    </div>

    <div class="recurrency-payment-details__item">
      <i class="fal fa-box-usd recurrency-payment-details__item-icon" />

      <div class="recurrency-payment-details__item-content">
        <div class="recurrency-payment-details__item-title">
          {{ subscription.productName }}
        </div>

        <div class="recurrency-payment-details__item-description">
          <span class="recurrency-payment-details__subtitle-light">
            ID da transação:
            <span class="recurrency-payment-details__subtitle">
              {{ payment.paymentId }}
            </span>
          </span>
        </div>
      </div>
    </div>

    <div class="recurrency-payment-details__item">
      <i class="fal fa-calendar-alt recurrency-payment-details__item-icon" />

      <div class="recurrency-payment-details__item-content">
        <div class="recurrency-payment-details__item-title">
          <span>
            {{ formatDay(payment.paidAt) }}
          </span>

          <span class="recurrency-payment-details__date-hour">
            {{ formatHour(payment.paidAt) }}
          </span>
        </div>

        <div class="recurrency-payment-details__item-description">
          <span class="recurrency-payment-details__subtitle-light">
            Data da cobrança
          </span>
        </div>
      </div>
    </div>

    <div class="recurrency-payment-details__item">
      <i class="fal fa-coins recurrency-payment-details__item-icon" />

      <div class="recurrency-payment-details__item-content">
        <div class="recurrency-payment-details__item-title">
          {{
            $t(`sparkpay.sales.sales-table.periodicity-value.${subscription.subscriptionPeriod}`, {
              value: totalReceivedText,
            })
          }}
        </div>

        <div class="recurrency-payment-details__item-description">
          <span class="recurrency-payment-details__subtitle-light">
            Valor líquido da assinatura, descontando taxas e possíveis comissões
          </span>
        </div>
      </div>
    </div>

    <div class="recurrency-payment-details__item">
      <i class="fal fa-hourglass-start recurrency-payment-details__item-icon" />

      <div class="recurrency-payment-details__item-content">
        <div
          class="recurrency-payment-details__item-title"
          :class="{ 'recurrency-payment-details__item-title--danger': availableRefundDays <= 0 }"
        >
          {{ $t('recurrency-refund-time', { days: availableRefundDays, limitDate: refundLimitDate }) }}
        </div>

        <div class="recurrency-payment-details__item-description">
          <span class="recurrency-payment-details__subtitle-light">
            Tempo restante para solicitar estorno
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs';
import GeneralHelper from '@/shared/helpers/general';

export default {
  name: 'RecurrencyPaymentDetails',
  props: {
    payment: {
      type: Object,
      required: true,
    },
    subscription: {
      type: Object,
      required: true,
    },
    availableRefundDays: {
      type: Number,
      required: true,
    },
  },
  computed: {
    totalReceivedText() {
      return GeneralHelper.currency(this.subscription.totalNetValue);
    },
    refundLimitDate() {
      return dayjs(this.payment.paidAt)
        .add(90, 'days')
        .format('DD/MM/YYYY');
    },
  },
  methods: {
    formatDay(date) {
      return dayjs(date).format('DD/MM/YYYY');
    },
    formatHour(date) {
      return dayjs(date).format('[às] HH:mm');
    },
  },
};
</script>

<style lang="scss" scoped>
.recurrency-payment-details {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
}

.recurrency-payment-details__title {
  color: #6f6f6f;
  font-weight: 600;
  font-size: 1rem;
  text-transform: uppercase;
}

.recurrency-payment-details__item {
  display: grid;
  grid-template-columns: auto 1fr;
  column-gap: 14px;
}

.recurrency-payment-details__item-icon {
  font-size: 20px;
}

.recurrency-payment-details__item-content {
  display: flex;
  flex-direction: column;
}

.recurrency-payment-details__item-title {
  color: $grey;
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.313rem;
}

.recurrency-payment-details__item-title--danger {
  color: #db3939;
}

.recurrency-payment-details__item-description {
  font-size: 0.75rem;
  margin-top: 4px;
}

.recurrency-payment-details__subtitle {
  color: $grey;
  font-weight: 600;
}

.recurrency-payment-details__subtitle-light {
  color: #6f6f6f;
}

.recurrency-payment-details__description-value {
  color: $grey;
}

.recurrency-payment-details__date-hour {
  font-size: 0.75rem;
  font-weight: 400;
  color: #6f6f6f;
}
</style>
