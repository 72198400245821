import i18n from '@/shared/helpers/i18n';

export const AutomationsTabs = {
  AUTOMATIONS_DASHBOARD_TAB: 'AutomationsDashboard',
  AUTOMATIONS_MODELS_TAB: 'AutomationsModels',
  AUTOMATIONS_LIST_TAB: 'AutomationsList',
};

export const Triggers = {
  STUDENT: ['new_student', 'free_order', 'accept_invite', 'invite_user'],
  ACADEMICS: [
    'ended_lesson',
    'completed_activity',
    'corrected_activity',
    'progress_in_25',
    'progress_in_50',
    'progress_in_75',
    'progress_in_100',
    'path_progress_in_25',
    'path_progress_in_50',
    'path_progress_in_75',
    'path_progress_in_100',
    'received_certificate',
    'enroll_student',
  ],
  MANAGMENT: ['new_course', 'new_school_product'],
  PURCHASE: [
    'new_order',
    'payment_under_analysis',
    'payment_boleto_created',
    'payment_confirmed',
    'payment_failed',
    'abandoned_cart',
  ],
  INTERACTION: ['new_forum_question', 'new_message', 'new_follower', 'forum_post_created'],
  SHOW: ['invite_user', 'enroll_student', 'new_message', 'forum_post_created'],
};

export const CustomFields = [
  { id: 'school_name', triggers: ['global'], field: 'school.name | remove_quotation_marks ' },
  { id: 'school_uri', triggers: ['global'], field: 'school_url' },
  { id: 'student_email', triggers: ['global'], field: 'user.email' },
  { id: 'student_name', triggers: ['global'], field: 'user | fullname | remove_quotation_marks' },
  { id: 'student_first_name', triggers: ['global'], field: 'user.first_name' },
  { id: 'student_last_name', triggers: ['global'], field: 'user.last_name' },
  { id: 'student_cpf', triggers: ['global'], field: 'user.cpf ' },
  { id: 'student_phone', triggers: ['global'], field: 'user.phone' },
];

export const FieldsForAbandonedCart = [
  { id: 'school_name', triggers: ['global'], field: 'school.name | remove_quotation_marks ' },
  { id: 'school_uri', triggers: ['global'], field: 'school_url' },
  { id: 'buyer_email', triggers: ['abandoned_cart'], field: 'buyerEmail' },
  { id: 'buyer_name', triggers: ['abandoned_cart'], field: 'buyerFullName' },
  { id: 'buyer_phone', triggers: ['abandoned_cart'], field: 'buyerPhone' },
  { id: 'offer_link', triggers: ['abandoned_cart'], field: 'offerLink' },
  { id: 'product_name', triggers: ['abandoned_cart'], field: 'productName' },
  { id: 'offer_price', triggers: ['abandoned_cart'], field: 'offerPrice' },
];

export const CustomFieldsByTrigger = (trigger: string) => {
  if (trigger === 'abandoned_cart') {
    return FieldsForAbandonedCart;
  }

  return CustomFields.filter(cf => cf.triggers.includes('global') || cf.triggers.includes(trigger));
};

export const getAutomationCategoryName = (trigger: string) => {
  const category = i18n.$t(`automations.triggers.${trigger}.category`);
  return i18n.$t(`automations.categories.${category}.name`);
};
