<template>
  <div
    :class="{ 'sales-total-collected--disabled': disabled }"
    class="sales-total-collected"
    data-testid="total-collected-wrapper"
  >
    <div data-testid="total-collected" class="sales-total-collected__total">
      {{ totalCollected }}
    </div>

    <div data-testid="total-progress" class="sales-total-collected__progress-count">
      {{ totalCollectedProgress }}
    </div>

    <div class="sales-total-collected__progress-bar">
      <div
        :style="{ width: progressPercentage }"
        data-testid="total-percentage"
        class="sales-total-collected__progress"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'SalesTotalCollected',
  props: {
    totalCollected: {
      type: String,
      required: true,
    },
    paidInstallments: {
      type: Number,
      required: true,
    },
    totalInstallments: {
      type: Number,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    totalCollectedProgress() {
      return `${this.paidInstallments}/${this.totalInstallments} parcelas pagas`;
    },
    progressPercentage() {
      const percentage = (this.paidInstallments / this.totalInstallments) * 100;

      return `${percentage}%`;
    },
  },
};
</script>

<style lang="scss">
.sales-total-collected {
  display: flex;
  flex-direction: column;
  row-gap: 4px;
  width: fit-content;
}

.sales-total-collected--disabled {
  opacity: 0.5;
}

.sales-total-collected__total {
  color: $grey;
  font-size: 0.875rem;
  line-height: 1.313rem;
}

.sales-total-collected__progress-count {
  color: $grey-40;
  font-size: 0.75rem;
  line-height: 1.125rem;
}

.sales-total-collected__progress-bar {
  height: 4px;
  background-color: $grey-16;
  border-radius: 200px;
}

.sales-total-collected__progress {
  height: 100%;
  background-color: #3575d4;
  border-radius: 200px;
}
</style>
