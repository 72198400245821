<template>
  <div>
    <MongoReport
      dashboard-id="c805f29e-f2dc-4360-99c4-4e37b4761ebb"
      :translate-key="translateKey"
      :disable-report-download="false"
      report-collection="purchase"
      export-type="affiliates"
    />
  </div>
</template>

<script>
import MongoReport from './components/MongoReport.vue';

export default {
  name: 'AffiliatesCoproducerReport',
  components: {
    MongoReport,
  },
  data() {
    return {
      translateKey: 'menu.sparkpay.sales.affiliates_coproducer_report',
    };
  },
};
</script>

<style lang="scss" scoped>
.subscription-sales-made-report__container {
  padding: 24px 16px;

  @media (min-width: $screen-md) {
    padding: 24px 32px;
  }
}
</style>
