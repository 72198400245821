<template>
  <div class="cards-info-subscription">
    <div class="cards-info-subscription__wrapper">
      <div class="cards-info-subscription__item">
        <hs-icon icon="calendar-alt" />

        <div class="cards-info-subscription__items-container">
          <div class="cards-info-subscription__item-data">
            <span class="cards-info-subscription__item-title">
              {{ formatDate(sale.purchasedAt) }}
            </span>

            <span class="cards-info-subscription__item-description">
              Data da compra da assinatura
            </span>
          </div>

          <div v-if="isCancelledSubscription" class="cards-info-subscription__item-data">
            <span v-if="hasCancelledAt" class="cards-info-subscription__item-title">
              {{ formatDate(sale.cancelledAt) }}
            </span>

            <span class="cards-info-subscription__item-description">
              {{ cancelledByText }}
            </span>
          </div>
        </div>
      </div>
    </div>

    <div class="cards-info-subscription__divider" />

    <div class="cards-info-subscription__wrapper">
      <div class="cards-info-subscription__item">
        <hs-icon icon="badge-dollar" />

        <div class="cards-info-subscription__items-container">
          <div class="cards-info-subscription__item-data">
            <span class="cards-info-subscription__item-title">
              {{ formattedTotal }}
            </span>

            <span class="cards-info-subscription__item-description">
              {{ $t('sparkpay.sales.details.cards-info.price') }}
            </span>
          </div>

          <div class="cards-info-subscription__item-data">
            <span class="cards-info-subscription__item-title">
              {{ totalReceivedText }}
            </span>

            <span class="cards-info-subscription__item-description">
              {{ $t('sparkpay.sales.details.cards-info.total-price') }}
            </span>
          </div>
        </div>
      </div>
    </div>

    <div class="cards-info-subscription__divider" />

    <div class="cards-info-subscription__wrapper">
      <div class="cards-info-subscription__item-data cards-info-subscription__item-data--align-right">
        <span class="cards-info-subscription__item-description">
          Total acumulado com esta assinatura
        </span>

        <span class="cards-info-subscription__item-title">
          {{ formattedTotalCollected }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs';
import GeneralHelper from '@/shared/helpers/general';

export default {
  props: {
    sale: {
      type: Object,
      required: true,
    },
  },
  methods: {
    formatDate(date) {
      return dayjs(date).format('DD/MM/YYYY');
    },
  },
  computed: {
    formattedTotal() {
      return GeneralHelper.currency(this.sale.offerBasePrice);
    },
    totalReceivedText() {
      return GeneralHelper.currency(this.sale.totalNetValue);
    },
    formattedTotalCollected() {
      return GeneralHelper.currency(this.sale.totalCollected);
    },
    isCancelledSubscription() {
      return this.sale.subscriptionStatus === 'cancelled';
    },
    hasCancelledAt() {
      return Boolean(this.sale.cancelledAt);
    },
    cancellerUser() {
      const userCancelMap = {
        buyer: 'assinante',
        producer: 'produtor',
        gateway: 'gateway de pagamento',
        super_admin: 'administrador',
      };

      return userCancelMap[this.sale.cancelledBy];
    },
    cancelledByText() {
      if (this.cancellerUser) {
        return `Cancelado pelo ${this.cancellerUser}`;
      }

      return this.hasCancelledAt ? 'Data de cancelamento da assinatura' : 'Assinatura cancelada';
    },
  },
};
</script>

<style lang="scss">
.cards-info-subscription {
  display: flex;
  flex-direction: column;
  margin: 16px 1rem;
  background-color: $white;
  border-radius: 4px;
  padding: 20px;
  gap: 24px;

  @media (min-width: $screen-md) {
    justify-content: space-between;
    flex-direction: row;
    margin: 16px 2rem;
    align-items: center;
  }
}

.cards-info-subscription__wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (min-width: $screen-md) {
    flex: 1 1 0;
  }
}

.cards-info-subscription__item {
  display: grid;
  grid-template-columns: 24px auto;
}

.cards-info-subscription__item-data {
  display: flex;
  flex-direction: column;
  row-gap: 4px;
}

.cards-info-subscription__item-data--align-right {
  text-align: end;
}

.cards-info-subscription__item-title {
  font-size: 1rem;
  line-height: 1rem;
  font-weight: 700;
  color: $grey-40;
}

.cards-info-subscription__item-description {
  font-size: 0.875rem;
  color: $grey-40;
}

.cards-info-subscription__items-container {
  display: flex;
  flex-direction: column;
  row-gap: 14px;
}

.cards-info-subscription__divider {
  border-bottom: 1px solid $grey-ba;
  height: 0;
  width: 100%;

  @media (min-width: $screen-md) {
    border-bottom: none;
    border-left: 1px solid $grey-ba;
    height: 106px;
    width: 0;
  }
}
</style>
