<template>
  <b-modal
    class="tlw-flex tlw-justify-center"
    :size="size"
    hide-footer
    centered
    header-class="pl-3 pl-md-4 tlw-py-6 border-bottom-0"
    body-class="py-0 px-3 py-3 py-md-0 px-1 px-md-5"
    v-bind="$attrs"
    @hidden="$emit('cancel')"
  >
    <div class="d-flex flex-column align-items-center">
      <div class="tlw-mx-auto md:tlw-my-8">
        <slot name="header-image">
          <img :src="image" alt="header-img" />
        </slot>
      </div>
      <slot name="content">
        <h3 class="tlw-text-2xl	mb-3 font-weight-bold text-center" :class="colorTextVariant" v-html="title"></h3>
        <p class="mx-4 mb-3 text-center" v-html="text"></p>
      </slot>
      <div class="tlw-mb-11 m-modal-footer tlw-w-full">
        <slot name="footer">
          <MButton
            id="btnOk"
            :variant="confirmButtonVariant"
            size="lg"
            class="tlw-w-full tlw-w-full tlw-mb-4"
            :label="confirmButtonLabel"
            @click="confirmed"
          />

          <MButton
            id="btnCancel"
            :variant="cancelButtonVariant"
            size="lg"
            class="tlw-w-full tlw-w-full tlw-bg-red-500"
            :label="cancelButtonLabel"
            @click="cancelled"
          />
        </slot>
      </div>
    </div>
  </b-modal>
</template>

<script>
import MButton from '@/shared/components/MButton.vue';

export default {
  inheritAttrs: false,
  components: {
    MButton,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    text: {
      type: String,
    },
    image: {
      type: String,
      default: require('@/assets/images/SparkAffiliates/Programs/activation-warning-robot.svg'),
    },
    confirmButtonLabel: {
      type: String,
    },
    cancelButtonLabel: {
      type: String,
    },
    confirmButtonColor: {
      type: String,
    },
    cancelButtonColor: {
      type: String,
    },
    variant: {
      type: String,
      default: 'primary',
    },
    size: {
      type: String,
      default: 'md',
    },
  },
  computed: {
    confirmButtonVariant() {
      switch (this.variant) {
        case 'delete':
          return 'critical';

        case 'secondary':
          return 'secondary';

        default:
          return 'primary';
      }
    },
    cancelButtonVariant() {
      switch (this.variant) {
        case 'delete':
          return 'critical-outline';

        case 'secondary':
          return 'secondary-outline';

        default:
          return 'primary-outline';
      }
    },
    colorTextVariant() {
      switch (this.variant) {
        case 'delete':
          return 'tlw-text-red-500';

        case 'secondary':
          return 'secondary-outline';

        default:
          return 'primary-outline';
      }
    },
  },

  methods: {
    confirmed() {
      this.$emit('confirm');
      this.$bvModal.hide('confirm-modal');
    },
    cancelled() {
      this.$bvModal.hide('confirm-modal');
      this.$emit('cancel');
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/ .m-modal-footer {
  max-width: 330px;
}
button {
  max-height: 47px;
}
</style>
