<template>
  <div class="recovery-payment-bolix">
    <span v-if="displayReactivateButton">
      <MXButton
        data-testid="recovery-payment-bolix"
        :disabled="!isOverduePayment"
        variant="secondary"
        class="recovery-payment-bolix__button"
        @click="reactivatePayment()"
      >
        {{ $t(`recovery-payment-button.${paymentMethod}-button-label`) }}
      </MXButton>
    </span>
  </div>
</template>

<script>
import MXButton from '@/shared/components/MXButton.vue';
import paymentsService from '@/services/sparkecommerce/payments';
import ToastHelper from '@/shared/helpers/toast';

export default {
  name: 'RecoveryPaymentBolixButton',
  components: { MXButton },
  props: {
    paymentId: {
      type: Number,
      required: true,
    },
    paymentStatus: {
      type: String,
      required: true,
    },
    paymentMethod: {
      type: String,
      required: true,
      validator: value => ['bank_slip', 'pix'].includes(value),
    },
  },
  data() {
    return {
      isLoading: false,
    };
  },
  methods: {
    async reactivatePayment() {
      try {
        this.isLoading = true;

        await paymentsService.reactivatePayment(this.paymentId);
        ToastHelper.successMessage(this.$t(`recovery-payment-button.${this.paymentMethod}-success-message`));
      } catch (error) {
        console.error('RecoveryPaymentBolixButton', error); // eslint-disable-line no-console
        ToastHelper.dangerMessage(this.$t(`recovery-payment-button.${this.paymentMethod}-error-message`));
      } finally {
        this.$emit('fetch-orders');
        this.isLoading = false;
      }
    },
  },
  computed: {
    displayReactivateButton() {
      return this.isOverduePayment || this.isWaitingPayment;
    },
    isOverduePayment() {
      return this.paymentStatus === 'overdue';
    },
    isWaitingPayment() {
      return this.paymentStatus === 'waiting_payment';
    },
  },
};
</script>

<style lang="scss">
.recovery-payment-bolix {
  width: fit-content;
}

.recovery-payment-bolix__button {
  width: 100%;

  @media (min-width: $screen-md) {
    width: fit-content;
  }
}

.recovery-payment-bolix__failed-attempts {
  display: flex;
  column-gap: 8px;
  align-items: center;
  color: #db3939;
  font-size: 0.875rem;
  line-height: 1.313rem;
  font-weight: 700;
  justify-content: center;

  @media (min-width: $screen-md) {
    justify-content: flex-start;
  }
}

.recovery-payment-bolix__redo-icon {
  margin-right: 8px;
}
</style>
