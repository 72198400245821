<template>
  <div>
    <MongoReport
      dashboard-id="024697f4-3979-480b-97c4-542d8c1a6cac"
      :translate-key="translateKey"
      :disable-report-download="false"
      :display-date-filter="false"
      report-collection="tracking"
      export-type="abandoned_carts"
    />
  </div>
</template>

<script>
import MongoReport from './components/MongoReport.vue';

export default {
  name: 'AbandonedCartReport',
  components: {
    MongoReport,
  },
  data() {
    return {
      translateKey: 'menu.sparkpay.sales.abandoned_cart_report',
    };
  },
};
</script>

<style lang="scss" scoped>
.subscription-sales-made-report__container {
  padding: 24px 16px;

  @media (min-width: $screen-md) {
    padding: 24px 32px;
  }
}
</style>
