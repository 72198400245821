<template>
  <div>
    <MongoReport
      dashboard-id="e014ffdd-95ca-45ff-9247-6102b23ab611"
      :translate-key="translateKey"
      :disable-report-download="false"
      report-collection="purchase"
      export-type="sales_strategy"
    />
  </div>
</template>

<script>
import MongoReport from './components/MongoReport.vue';

export default {
  name: 'SalesStrategyReport',
  components: {
    MongoReport,
  },
  data() {
    return {
      translateKey: 'menu.sparkpay.sales.sales_strategy_report',
    };
  },
};
</script>

<style lang="scss" scoped>
.subscription-sales-made-report__container {
  padding: 24px 16px;

  @media (min-width: $screen-md) {
    padding: 24px 32px;
  }
}
</style>
