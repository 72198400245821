<template>
  <section class="flex-fill tlw-flex tlw-flex-col tlw-justify-center tlw-items-center bg-transparent">
    <b-img-lazy :src="noFilteredDataImg"></b-img-lazy>
    <h3 class="text-cherry tlw-text-center tlw-my-6 tlw-font-bold">
      {{ $t('sparkpay.sales.no-filtered-sales-warning.title') }}
    </h3>
    <div>
      <p class="tlw-text-center">{{ $t('sparkpay.sales.no-filtered-sales-warning.p') }}</p>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      noFilteredDataImg: require('@/assets/images/SparkMembers/MembersList/components/NoFilteredMembersWarning/no_filtered_data.svg'),
    };
  },
};
</script>
