<template>
  <div class="sales-table">
    <hs-table
      ref="salesTable"
      hover
      responsive
      selectable
      :fields="fields"
      :items="sales"
      select-mode="single"
      @selectedItems="sale => openSidebar(sale)"
    >
      <template slot="total" slot-scope="row">
        <strong>{{ totalReceivedText(row.item) }}</strong>
      </template>
      <template slot="payment_method" slot-scope="row">
        {{ $t(`sparkpay.sales.sales-table.kinds.${row.item.payment_method}`) }}
      </template>
      <template slot="created_at" slot-scope="row">
        {{ formatDate(row.item.created_at, true) }}
      </template>
      <template slot="product" slot-scope="row">
        <hs-badge
          v-if="row.item.order_bump_used && row.item.order_bump_items.length"
          variant="outline-purple"
          data-testid="order-bump-badge"
          pill
        >
          {{ $t('sparkpay.sales.detailed-sales-sidebar.order-bump') }}
        </hs-badge>
        <div class="sales-table__product-name">{{ row.item.product }}</div>
      </template>
      <template slot="status" slot-scope="row">
        <div v-if="isSalesStrategyPage" class="sales-table__status-badge-container">
          <Tag
            :label="getStatusLabel('installments', row.item.status)"
            :variant="getStatusVariant('installments', row.item.status)"
          />

          <div class="sales-table__status-payment-method">
            {{ $t(`sparkpay.sales.sales-table.kinds.${row.item.payment_method}`) }}
          </div>
        </div>
        <Tag
          v-else
          :label="getStatusLabel('installments', row.item.status)"
          :variant="getStatusVariant('installments', row.item.status)"
        />
      </template>
      <template slot="subscription_due_at" slot-scope="row">
        <div class="d-flex align-items-center justify-content-center mr-1">
          <span class="tlw-ml-auto tlw-mr-4">{{ formatDate(row.item.subscription_due_at) }}</span>
          <hs-icon v-if="row.item.kind === 'subscription'" icon="chevron-right" class="tlw-ml-auto tlw-text-lg" />
        </div>
      </template>
      <template slot="total_collected" slot-scope="row">
        <SalesTotalCollected
          :disabled="isTotalCollectedDisabled(row.item.status)"
          :total-collected="calculateTotalCollected(row.item)"
          :paid-installments="row.item.paid_installments"
          :total-installments="row.item.total_installments"
        />
      </template>
      <template slot="options" slot-scope="row">
        <div class="sales-table__options-container">
          <DropdownStrategySales v-if="viewOfProducer(row.item)" :sale="row.item" />
          <hs-icon size="20" icon="angle-right" />
        </div>
      </template>
    </hs-table>
  </div>
</template>

<script>
import dayjs from 'dayjs';
import { mapActions } from 'vuex';
import GeneralHelper from '@/shared/helpers/general';
import PaymentSummaryMixin from '@/sparkpay/mixins/PaymentSummaryMixin.js';
import SalesTotalCollected from '@/sparkpay/views/Sales/components/SalesTotalCollected.vue';
import DropdownStrategySales from '@/sparkpay/views/Sales/components/DropdownStrategySales.vue';
import Tag from '@/components/MTag.vue';
import StatusMixin from '@/mixins/StatusMixin';

export default {
  name: 'SalesTable',
  mixins: [PaymentSummaryMixin, StatusMixin],
  components: {
    SalesTotalCollected,
    DropdownStrategySales,
    Tag,
  },
  props: {
    fields: {
      required: true,
      type: Array,
    },
    sales: {
      required: true,
      type: Array,
    },
    page: {
      type: String,
      default: 'recurrency',
      validator: value => ['sales-strategy', 'recurrency'].includes(value),
    },
  },
  computed: {
    isSalesStrategyPage() {
      return this.page === 'sales-strategy';
    },
  },
  methods: {
    ...mapActions('sale', ['selectSidebarSale']),
    getBadgeVariant(status) {
      const statusMap = {
        initiated: 'secondary',
        processing: 'warning',
        waiting_payment: 'warning',
        no_wait_processing: 'warning',
        confirmed: 'success',
        active: 'success',
        abandoned: 'cherry',
        canceled: 'cherry',
        subscription_canceled: 'cherry',
        overdue: 'warning',
      };

      return statusMap[status];
    },
    openSidebar(saleList) {
      if (saleList.length) {
        this.selectSidebarSale(saleList[0]);
      }
    },
    totalReceived(subscription) {
      if (this.isSalesStrategyPage) {
        return this.netValueByParticipation(subscription);
      }

      return this.netValueByParticipationMongo(subscription);
    },
    totalReceivedText(subscription) {
      return GeneralHelper.currency(this.totalReceived(subscription) / 100);
    },
    formatDate(date, showHours = false) {
      if (!date) return '-';

      const format = showHours ? 'DD/MM/YYYY HH:mm' : 'DD/MM/YYYY';

      return dayjs(date).format(format);
    },
    calculateTotalCollected(item) {
      const netValueByParticipation = this.totalReceived(item);
      const collectedValueByInstallment = netValueByParticipation / item.total_installments;
      const collectedTotalValue = collectedValueByInstallment * item.paid_installments;

      return GeneralHelper.currency(collectedTotalValue / 100);
    },
    isTotalCollectedDisabled(status) {
      return ['chargeback', 'refunded'].includes(status);
    },
  },
};
</script>

<style lang="scss">
.table-responsive table td .dropdown {
  display: flex;
  align-items: center;
  justify-content: center;

  &__content {
    right: 12px;
  }
}

.sales-table__product-name {
  margin-top: 8px;
}

.sales-table__options-container {
  display: flex;
  column-gap: 12px;
  align-items: center;
  justify-content: flex-end;
}

.sales-table__status-badge-container {
  display: flex;
  flex-direction: column;
}

.sales-table__status-payment-method {
  margin-top: 4px;
  font-size: 0.875rem;
  line-height: 1.313rem;
  color: $grey-40;
}
</style>
