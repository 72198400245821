<template>
  <div class="recurrency-payment-refund-details">
    <div class="recurrency-payment-refund-details__header">
      <h3 class="recurrency-payment-refund-details__header-title">
        Solicitação de estorno
      </h3>

      <p
        v-html="$t('sparkpay.sales.refund-sidebar.description')"
        class="recurrency-payment-refund-details__header-description"
      />
    </div>

    <div class="recurrency-payment-refund-details__buyer-data">
      <div class="recurrency-payment-refund-details__buyer-info-field">
        Nome:
      </div>
      <div class="recurrency-payment-refund-details__buyer-info-value">
        {{ buyerData.fullName }}
      </div>

      <div class="recurrency-payment-refund-details__buyer-info-field">
        E-mail:
      </div>
      <div class="recurrency-payment-refund-details__buyer-info-value">
        {{ buyerData.email }}
      </div>

      <div class="recurrency-payment-refund-details__buyer-info-field">
        CPF:
      </div>
      <div class="recurrency-payment-refund-details__buyer-info-value">
        {{ formatCPF(buyerData.document) }}

        <span tabindex="0" @click="showCpf = !showCpf" class="recurrency-payment-refund-details__cpf-icon">
          <hs-icon variant="light" :icon="`eye${showCpf ? '-slash' : ''}`" />
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import GeneralHelper from '@/shared/helpers/general';

export default {
  name: 'RecurrencyPaymentRefundDetails',
  props: {
    subscription: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      showCpf: false,
    };
  },
  computed: {
    buyerData() {
      return this.subscription.buyer;
    },
  },
  methods: {
    formatCPF(cpf) {
      return cpf ? GeneralHelper.cpf(cpf, !this.showCpf) : '-';
    },
  },
};
</script>

<style lang="scss">
.recurrency-payment-refund-details {
  display: flex;
  flex-direction: column;
  row-gap: 24px;
}

.recurrency-payment-refund-details__header {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
}

.recurrency-payment-refund-details__header-title {
  font-size: 1.25rem;
  line-height: 1.563rem;
  font-weight: 600;
  color: $grey;
  margin: 0;
}

.recurrency-payment-refund-details__header-description {
  font-size: 0.875rem;
  line-height: 1.313rem;
  color: $grey-40;
  margin: 0;

  strong {
    font-weight: 700;
  }
}

.recurrency-payment-refund-details__buyer-data {
  display: grid;
  grid-template-columns: 49px 1fr;
  gap: 8px;
}

.recurrency-payment-refund-details__buyer-info-field {
  font-size: 0.875rem;
  line-height: 1.313rem;
  color: $color-neutral-600;
}

.recurrency-payment-refund-details__buyer-info-value {
  font-size: 0.875rem;
  line-height: 1.313rem;
  color: $grey;
  font-weight: 600;
}

.recurrency-payment-refund-details__cpf-icon {
  color: $color-neutral-600;
  cursor: pointer;
  margin-left: 8px;
}
</style>
