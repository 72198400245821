<template>
  <NoSalesWarning v-if="showNoSalesWarning" />
  <div v-else>
    <Filters
      :filters="filters"
      data-testid="units-list-filter"
      class="sales-units-list__filter"
      @changed="mountUnitsList(1)"
    />

    <hsLoading v-if="isLoading" class="flex-fill" />
    <div v-else class="sales-units-list__list-container">
      <NoFilteredSalesWarning v-if="showNoFilteredSalesWarning" />
      <template v-else-if="hasSparkCheckout">
        <UnitsListSalesTable :sales="sales" @refunded="mountUnitsList(currentPage)" class="desktop-only" />

        <UnitaryCardList :sales-list="sales" class="mobile-only" />
      </template>

      <div v-if="totalNumberOfSales > 10">
        <hs-pagination
          v-model="currentPage"
          :go-page-text="$t('sparkpay.sales.pagination.go-to-page')"
          :per-page="10"
          :total-rows="totalNumberOfSales"
          align="center"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import orderService from '@/services/order';
import ToastHelper from '@/shared/helpers/toast';
import { hsLoading } from '@/components';
import NoSalesWarning from '@/sparkpay/views/Sales/components/NoSalesWarning';
import NoFilteredSalesWarning from '@/sparkpay/views/Sales/components/NoFilteredSalesWarning';
import Filters from '@/components/Filters/index.vue';
import FilterMixin from '@/sparkpay/mixins/FilterMixin';
import UnitsListSalesTable from './UnitsListSalesTable';
import UnitaryCardList from './UnitaryCardList';

export default {
  name: 'SalesUnitsList',
  mixins: [FilterMixin],
  data() {
    return {
      isLoading: false,
      filters: [
        {
          type: 'Main',
          key: 'full_name',
          label: this.$t('sparkpay.sales.filters.full-name.unit-label'),
          value: '',
        },
        {
          type: 'MultiSelect',
          key: 'status',
          label: this.$t('sparkpay.sales.filters.status.label'),
          multiple: true,
          options: [
            {
              item: {
                label: this.$t('sparkpay.sales.filters.status.options.initiated.label'),
                value: 'initiated',
              },
            },
            {
              item: {
                label: this.$t('sparkpay.sales.filters.status.options.canceled.label'),
                value: 'canceled',
              },
            },
            {
              item: {
                label: this.$t('sparkpay.sales.filters.status.options.confirmed.label'),
                value: 'confirmed',
              },
            },
            {
              item: {
                label: this.$t('sparkpay.sales.filters.status.options.waiting_payment.label'),
                value: 'waiting_payment',
              },
            },
            {
              item: {
                label: this.$t('sparkpay.sales.filters.status.options.chargeback.label'),
                value: 'chargeback',
              },
            },
            {
              item: {
                label: this.$t('sparkpay.sales.filters.status.options.refunded.label'),
                value: 'refunded',
              },
            },
          ],
          value: null,
        },
        {
          type: 'MultiSelect',
          key: 'type',
          label: this.$t('sparkpay.sales.filters.kind.label'),
          multiple: false,
          options: [
            {
              item: {
                label: this.$t('sparkpay.sales.filters.kind.options.credit_card.label'),
                value: 'credit_card',
              },
            },
            {
              item: {
                label: this.$t('sparkpay.sales.filters.kind.options.boleto.label'),
                value: 'bank_slip',
              },
            },
            {
              item: {
                label: this.$t('sparkpay.sales.filters.kind.options.pix.label'),
                value: 'pix',
              },
            },
          ],
          value: null,
        },
        {
          type: 'MultiSelect',
          key: 'product_id',
          label: this.$t('sparkpay.sales.filters.product.label'),
          multiple: false,
          options: [],
          value: null,
        },
        {
          type: 'Date',
          key: 'created_at_start',
          label: 'Data de criação inicial',
          value: null,
        },
        {
          type: 'Date',
          key: 'created_at_end',
          label: 'Data de criação final',
          value: null,
        },
      ],
      sales: [],
      fields: [
        { key: 'total', label: this.$t('sparkpay.sales.sales-table.fields.total'), sortable: true },
        { key: 'name', label: this.$t('sparkpay.sales.sales-table.fields.name'), sortable: true },
        { key: 'email', label: this.$t('sparkpay.sales.sales-table.fields.email'), sortable: true },
        { key: 'product', label: this.$t('sparkpay.sales.sales-table.fields.product'), sortable: true },
        {
          key: 'created_at',
          label: this.$t('sparkpay.sales.sales-table.fields.created_at'),
          sortable: true,
        },
        { key: 'payment_method', label: this.$t('sparkpay.sales.sales-table.fields.kind'), sortable: true },
        { key: 'status', label: this.$t('sparkpay.sales.sales-table.fields.status'), sortable: true },
        { key: '', label: '' },
      ],
      currentPage: 1,
      totalNumberOfSales: 0,
    };
  },
  watch: {
    currentPage(newValue) {
      this.mountUnitsList(newValue);
    },
  },
  components: {
    hsLoading,
    UnitsListSalesTable,
    NoSalesWarning,
    NoFilteredSalesWarning,
    UnitaryCardList,
    Filters,
  },
  methods: {
    ...mapActions('sale', ['selectSidebarSale']),
    mountUnitsList(page) {
      this.isLoading = true;
      orderService
        .getUnitaryOrders(this.filterPaginationParams([{ key: 'per_page', value: '10' }, { key: 'page', value: page }]))
        .then(res => {
          this.sales = res.orders;
          this.totalNumberOfSales = res.total_count;
        })
        .catch(error => {
          ToastHelper.dangerMessage(this.$t('sparkpay.sales.mount-sales-list-error'));
          console.log(error); // eslint-disable-line
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    filterPaginationParams(paginationParams = []) {
      return [{ key: 'kind', value: 'common' }, ...paginationParams, ...this.parseFiltersToParams()];
    },
  },
  computed: {
    showNoFilteredSalesWarning() {
      return !this.isLoading && !this.sales.length && this.filters.some(f => f.value);
    },
    showNoSalesWarning() {
      return !this.isLoading && !this.sales.length && !this.filters.some(f => f.value);
    },
    hasSparkCheckout() {
      return this.$store.getters['school/hasFeature']('spark_checkout');
    },
  },
  destroyed() {
    this.selectSidebarSale(null);
  },
  created() {
    this.mountUnitsList(1);
    this.loadProductsOptions();
  },
};
</script>

<style lang="scss" scoped>
.mobile-only {
  width: 100%;

  @media (min-width: $screen-md) {
    display: none;
  }
}

.desktop-only {
  display: none;

  @media (min-width: $screen-md) {
    display: block;
    width: 100%;
  }
}

.sales-units-list__list-container {
  padding: 0 16px 32px;

  @media (min-width: $screen-md) {
    padding: 0 32px 32px;
  }
}

.sales-units-list__filter {
  padding: 24px 16px 0;

  @media (min-width: $screen-md) {
    padding: 24px 32px 0;
  }
}
</style>
