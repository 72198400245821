<template>
  <div class="unitary-sales-drawer">
    <div class="unitary-sales-drawer__close-button">
      <hs-button variant="link" @click="$emit('close')">
        <hs-icon icon="times" />
      </hs-button>
    </div>
    <div class="unitary-sales-drawer__content">
      <Tag
        v-if="purchase.status"
        :label="getStatusLabel('unitary', purchase.status)"
        :variant="getStatusVariant('unitary', purchase.status)"
      />

      <div class="unitary-sales-drawer__general-info">
        <div class="unitary-sales-drawer__buyer-name">
          {{ purchase.buyer.fullName }}
        </div>

        <div class="unitary-sales-drawer__general-info-row">
          <div class="unitary-sales-drawer__general-info-label">
            {{ $t('sparkpay.sales.detailed-sales-sidebar.email') }}:
          </div>

          <div class="unitary-sales-drawer__general-info-data">
            {{ purchase.buyer.email }}
          </div>
        </div>

        <div class="unitary-sales-drawer__general-info-row">
          <div class="unitary-sales-drawer__general-info-label">
            {{ $t('sparkpay.sales.detailed-sales-sidebar.cpf') }}:
          </div>

          <div class="unitary-sales-drawer__general-info-data">
            {{ formatCPF(purchase.buyer.document) }}
          </div>
          <hs-button v-if="purchase.buyer.document" variant="link" size="sm" @click="() => (showCpf = !showCpf)">
            <hs-icon variant="light" :icon="`eye${showCpf ? '-slash' : ''}`" />
          </hs-button>
        </div>

        <div v-if="purchase.buyer.phone" data-testid="buyer-phone" class="unitary-sales-drawer__general-info-row">
          <div class="unitary-sales-drawer__general-info-label">
            {{ $t('sparkpay.sales.detailed-sales-sidebar.phone') }}:
          </div>

          <div class="unitary-sales-drawer__general-info-data">
            +{{ purchase.buyer.ddi }} {{ purchase.buyer.phone }}
          </div>

          <a
            data-testid="whatsapp-link"
            :href="getWhatsappLink(purchase.buyer.phone, '', purchase.buyer.ddi)"
            target="_blank"
            aria-label="Abrir conversa no WhatsApp"
            class="tlw-ml-2"
            @click="trackWhatsappEvent"
          >
            <i
              class="fab fa-whatsapp unitary-sales-drawer__whatsapp-icon"
              aria-hidden="true"
              title="Abrir conversa no WhatsApp"
            />
          </a>
        </div>
      </div>

      <hr class="unitary-sales-drawer__divider" />

      <UnitarySalesDrawerDetails :sale="purchase" />

      <hr class="unitary-sales-drawer__divider" />

      <div
        v-if="!isPaymentCanceledOrRefunded"
        data-testid="info-tax-text"
        v-html="infoTaxText"
        class="unitary-sales-drawer__extra-info"
      />

      <div v-if="showRefundRequestButton && !isProcessingRefund()" v-b-tooltip.hover.top="refundExpiredTooltip">
        <MXButton
          :disabled="!enableRefund"
          class="unitary-sales-drawer__refund-action"
          variant="danger-secondary"
          @click="showRefundSidebar = true"
        >
          {{ refundRequestButtonText }}
        </MXButton>
      </div>
      <div v-else-if="isProcessingRefund()" class="unitary-sales-drawer__processing-refund">
        <LoadingCircle :size="16" />
        Processando estorno
      </div>
      <DownloadRefundReceiptButton
        v-else-if="isPurchaseRefunded"
        :buyer-name="purchase.buyer.fullName"
        :payment-data="refundPaymentData"
      />
      <CopyPublicLinkButton v-if="purchase.cartId" :public_id="purchase.cartId" />
    </div>
    <UnitarySalesRefundSidebar
      v-if="showRefundSidebar"
      :sale="purchase"
      @close="showRefundSidebar = false"
      @refunded="handleSuccessRefund"
      @error="handleErrorRefund"
    />
  </div>
</template>

<script>
import { analyticsService } from '@/services';
import GeneralHelper from '@/shared/helpers/general';
import { getWhatsappLink } from '@/shared/helpers/phone';
import ToastHelper from '@/shared/helpers/toast';
import Tag from '@/components/MTag.vue';
import StatusMixin from '@/mixins/StatusMixin';
import CookieHelper from '@/shared/helpers/cookie.js';
import LoadingCircle from '@/components/LoadingCircle.vue';
import sparkPayService from '@/sparkpay/services/sparkpay';
import MXButton from '@/shared/components/MXButton.vue';
import DownloadRefundReceiptButton from './DownloadRefundReceiptButton.vue';
import CopyPublicLinkButton from './CopyPublicLinkButton.vue';

const REFUNDED_STATUS = ['refunded', 'refund_analyzing', 'canceled'];

export default {
  name: 'UnitarySalesDrawer',
  mixins: [StatusMixin],
  props: {
    purchase: {
      type: Object,
      required: true,
    },
  },
  components: {
    UnitarySalesRefundSidebar: () => import('@/sparkpay/views/Sales/components/UnitarySalesRefundSidebar.vue'),
    UnitarySalesDrawerDetails: () => import('@/sparkpay/views/Sales/components/UnitarySalesDrawerDetails.vue'),
    CopyPublicLinkButton,
    Tag,
    LoadingCircle,
    MXButton,
    DownloadRefundReceiptButton,
  },
  data() {
    return {
      showRefundSidebar: false,
      showCpf: false,
      isValidToRefund: false,
      saleCookieName: `refund-processing-${this.purchase.saleId}`,
    };
  },
  computed: {
    refundPaymentData() {
      return {
        paymentId: this.purchase.saleId,
        paymentMethod: this.purchase.paymentMethod,
        refundedValue: this.purchase.totalPaidByBuyer,
        paidAt: this.purchase.paidAt,
        createdAt: this.purchase.createdAt,
        refundedAt: this.purchase.refundedAt,
      };
    },
    enableRefund() {
      return this.isValidToRefund && this.enableRefundByTime;
    },
    enableRefundByTime() {
      return this.purchase.enableRefund;
    },
    infoTaxText() {
      return this.$t('sparkpay.sales.detailed-sales-sidebar.info-tax');
    },
    isPaymentCanceledOrRefunded() {
      return REFUNDED_STATUS.includes(this.purchase.status);
    },
    isPurchaseRefunded() {
      return this.purchase.status === 'refunded';
    },
    isMainProducer() {
      return this.purchase.participation === 'producer';
    },
    showRefundRequestButton() {
      const { paymentMethod, status } = this.purchase;

      return (
        (status === 'paid' && paymentMethod === 'credit_card') ||
        (status === 'paid' && paymentMethod === 'pix') ||
        (paymentMethod === 'multicard' && this.isMainProducer)
      );
    },
    refundRequestButtonText() {
      if (this.enableRefundByTime) return 'Solicitar estorno';

      return 'Estorno indisponível';
    },
    refundExpiredTooltip() {
      if (this.enableRefund) return '';

      if (!this.enableRefundByTime) {
        return 'O prazo para esta solicitação venceu';
      }

      return 'Você não possui saldo suficiente para esta ação';
    },
  },
  methods: {
    async fetchValidRefund() {
      if (!this.purchase) return;

      const { purchaseId, paymentServiceIdentifier } = this.purchase;

      this.isValidToRefund = false;

      try {
        const response = await sparkPayService.verifyValidRefund({
          purchase_id: purchaseId,
          payment_id: paymentServiceIdentifier,
        });

        this.isValidToRefund = response?.valid_refund;
      } catch (error) {
        this.isValidToRefund = false;
      }
    },
    isProcessingRefund() {
      const refundingSale = CookieHelper.getCookie(this.saleCookieName);

      if (this.isPaymentCanceledOrRefunded) {
        CookieHelper.deleteCookie(this.saleCookieName);
        return false;
      }

      return refundingSale;
    },
    handleSuccessRefund() {
      this.showRefundSidebar = false;
      ToastHelper.successMessage('Estorno realizado com sucesso');
      CookieHelper.setCookie(true, 1, this.saleCookieName);

      this.$emit('refunded');
    },
    handleErrorRefund() {
      this.showRefundSidebar = false;
      ToastHelper.dangerMessage('Erro ao realizar estorno');
    },
    formatCPF(cpf) {
      return cpf ? GeneralHelper.cpf(cpf, !this.showCpf) : '-';
    },
    trackWhatsappEvent() {
      analyticsService.track({
        event: 'Whatsapp button clicked',
        props: {
          location: 'sales_list',
        },
      });
    },
    getWhatsappLink,
  },
  mounted() {
    this.fetchValidRefund();
  },
  watch: {
    purchase() {
      this.fetchValidRefund();
    },
  },
};
</script>

<style lang="scss" scoped>
.unitary-sales-drawer {
  position: fixed;
  right: 0;
  width: 350px;
  min-height: calc(100% - 69px);
  z-index: 2;
  background-color: $white;
  padding: 24px;
  top: 69px;
  box-shadow: -1px 2px 8px rgba(0, 0, 0, 0.25);

  @media screen and (max-width: 576px) {
    width: 100%;
    top: 0;
    box-shadow: none;
    padding-top: 70px;
    height: 100vh;
  }
}

.unitary-sales-drawer__close-button {
  position: absolute;
  right: 24px;
}

.unitary-sales-drawer__content {
  width: 100%;
  height: calc(100vh - 110px);
  overflow-y: auto;
}

.unitary-sales-drawer__general-info {
  display: flex;
  flex-direction: column;
  margin-top: 16px;
}

.unitary-sales-drawer__general-info-row {
  margin-top: 8px;
  display: flex;
  align-items: center;
}

.unitary-sales-drawer__general-info-label {
  font-size: 0.875rem;
  color: #6f6f6f;
  width: 65px;
}

.unitary-sales-drawer__general-info-data {
  margin-left: 8px;
  font-size: 0.875rem;
  color: $grey;
  font-weight: 600;
}

.unitary-sales-drawer__buyer-name {
  color: $grey;
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 2rem;
}

.unitary-sales-drawer__divider {
  border-color: var(--color-gray-100);
  width: 100%;
  margin-top: 16px;
  margin-bottom: 16px;
}

.unitary-sales-drawer__whatsapp-icon {
  color: #6f6f6f;
}

.unitary-sales-drawer__extra-info {
  font-size: 0.875rem;
  color: $grey;
}

.unitary-sales-drawer__refund-action {
  width: 100%;
  margin-top: 24px;
}

.unitary-sales-drawer__processing-refund {
  display: flex;
  align-items: center;
  column-gap: 12px;
  justify-content: center;
  border-radius: 4px;
  padding: 12px;
  width: 100%;
  background-color: #e3e3e3;
  color: #262626;
  font-weight: 600;
  margin-top: 12px;
}

.unitary-sales-drawer__download-pdf {
  width: 100%;
  margin-top: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 576px) {
  .unitary-sales-drawer {
    position: fixed;
    width: 100%;
    top: 0;
    background-color: white;
    box-shadow: none;
    padding-top: 70px;
  }
}
</style>
