








































import { Vue, Component, Prop } from 'vue-property-decorator';
import { AutomationsTabs } from '@/libs/automation';
import { FilterAutomationModel } from '@/types/automation';

const MODELS_DICTIONARY = {
  recovery: FilterAutomationModel.CART_RECOVERY,
  recurrency: FilterAutomationModel.RECURRENCY,
  interactWithLeads: FilterAutomationModel.INTERACT_WITH_LEADS,
  upsell: FilterAutomationModel.UPSELL,
};

@Component
export default class AutoPilotBanner extends Vue {
  @Prop({ type: String, default: '' }) title: string;
  @Prop({ type: String }) description: string;
  @Prop({ type: String, default: '' }) imageName: string;
  @Prop({ type: Boolean, default: false }) vertical: boolean;
  @Prop({ type: String, default: '' }) icon: string;
  @Prop({
    type: String,
    default: 'recovery',
    validator: model => Object.keys(MODELS_DICTIONARY).includes(model),
  })
  selectedModel: string;

  automationModelsTab: string = AutomationsTabs.AUTOMATIONS_MODELS_TAB;
  automationModel: string = '';

  mounted() {
    this.automationModel = MODELS_DICTIONARY[this.selectedModel];
  }
}
