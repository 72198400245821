export default {
  methods: {
    displayBolixActions(sale, index) {
      if (index === 0) {
        return this.isPendingBolixStatus(sale.status);
      } else {
        if (this.isFirstPaymentPaid && this.isPendingBolixStatus(sale.status)) return true;

        return false;
      }
    },
    isPendingBolixStatus(status) {
      const pendingStatus = ['waiting_payment', 'overdue', 'scheduled'];
      return pendingStatus.includes(status);
    },
  },
  computed: {
    isFirstPaymentPaid() {
      return this.sales[0].status === 'confirmed' || this.sales[0].status === 'paid';
    },
  },
};
