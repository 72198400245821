<template>
  <div class="recurrency-card-list">
    <div v-for="sale in salesList" :key="sale.id" class="recurrency-card-list__card" @click="selectPurchase(sale)">
      <div class="recurrency-card-list__header">
        <div class="recurrency-card-list__header-date">
          <span class="recurrency-card-list__date-title">
            Data de criação
          </span>
          {{ formatDate(sale.purchasedAt) }}
        </div>

        <div class="recurrency-card-list__header-options">
          <hs-icon icon="angle-right" size="32" />
        </div>
      </div>

      <div class="recurrency-card-list__description">
        <div class="recurrency-card-list__product-name">
          {{ sale.productName }}
        </div>

        <div class="recurrency-card-list__user-info">
          {{ sale.buyer.fullName }}
          <div class="recurrency-card-list__email">
            {{ sale.buyer.email }}
          </div>
        </div>
      </div>

      <hr class="recurrency-card-list__divider" />

      <div class="recurrency-card-list__payment-info-wrapper">
        <div class="recurrency-card-list__status">
          <Tag
            :label="getStatusLabel('recurrency', sale.subscriptionStatus)"
            :variant="getStatusVariant('recurrency', sale.subscriptionStatus)"
          />

          {{ $t(`sparkpay.sales.sales-table.kinds.${sale.paymentMethod}`) }}
        </div>

        <div class="recurrency-card-list__received-value">
          {{
            $t(`sparkpay.sales.sales-table.periodicity-value.${sale.subscriptionPeriod}`, {
              value: sale.totalNetValue,
            })
          }}
        </div>
      </div>
    </div>

    <RecurrencySalesDrawer
      v-if="sidebarIsOpen"
      :key="selectedPurchase.subscriptionId"
      :sale="selectedPurchase"
      @close="closeSidebar()"
    />
  </div>
</template>

<script>
import dayjs from 'dayjs';
import StatusMixin from '@/mixins/StatusMixin';
import RecurrencySalesDrawer from '@/sparkpay/views/Sales/components/RecurrencySalesDrawer.vue';
import Tag from '@/components/MTag.vue';

export default {
  name: 'RecurrencyCardList',
  components: {
    Tag,
    RecurrencySalesDrawer,
  },
  mixins: [StatusMixin],
  props: {
    salesList: {
      required: true,
      type: Array,
    },
  },
  data() {
    return {
      sidebarIsOpen: false,
      selectedPurchase: null,
    };
  },
  methods: {
    closeSidebar() {
      this.selectedPurchase = null;
      this.sidebarIsOpen = false;
    },
    selectPurchase(purchase) {
      if (!purchase) return;

      this.selectedPurchase = purchase;
      this.sidebarIsOpen = true;
    },
    formatDate(date) {
      return dayjs(date).format('DD/MM/YYYY HH:mm');
    },
  },
  watch: {
    sidebarIsOpen(value) {
      if (value) {
        document.body.style.overflow = 'hidden';
      } else {
        document.body.style.overflow = '';
      }
    },
  },
};
</script>

<style lang="scss">
.recurrency-card-list {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
}

.recurrency-card-list__card {
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0px 4px 8px rgba(63, 63, 63, 0.08);
  padding: 16px;
}

.recurrency-card-list__header-date {
  display: flex;
  flex-direction: column;
}

.recurrency-card-list__date-title {
  font-size: 12px;
}

.recurrency-card-list__header {
  display: flex;
  justify-content: space-between;
}

.recurrency-card-list__divider {
  border-bottom: 1px solid #cfcfcf;
  margin: 16px 0;
}

.recurrency-card-list__header-options {
  width: 55px;
  display: flex;
  justify-content: flex-end;
  column-gap: 12px;
  align-items: center;
}

.recurrency-card-list__description {
  display: flex;
  flex-direction: column;
  margin-top: 16px;
}

.recurrency-card-list__product-name {
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.5rem;
  color: $grey;
}

.recurrency-card-list__user-info {
  font-size: 0.875rem;
  line-height: 1.313rem;
  color: $grey;
  margin-top: 16px;
}

.recurrency-card-list__email {
  margin-top: 4px;
}

.recurrency-card-list__payment-info-wrapper {
  display: grid;
  gap: 16px;
  grid-template-columns: 1fr 1fr;
  grid-template-areas: 'status received-value';
}

.recurrency-card-list__status {
  grid-area: status;
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 4px;
}

.recurrency-card-list__received-value {
  grid-area: received-value;
  text-align: right;
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 600;
  color: $grey;
}
</style>
