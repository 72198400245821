<template>
  <div
    :style="{
      height: `${size}px`,
      width: `${size}px`,
      border: `${size / 8}px solid ${shadowColor}`,
      borderTop: `${size / 8}px solid ${color}`,
    }"
    class="loading-circle"
  />
</template>

<script>
export default {
  props: {
    size: {
      type: Number,
      default: 8,
    },
    color: {
      type: String,
      default: '#262626',
    },
    shadowColor: {
      type: String,
      default: '#f3f3f3',
    },
  },
};
</script>

<style lang="scss" scoped>
.loading-circle {
  border-radius: 50%;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
