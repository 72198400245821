<template>
  <div class="recurrency-payment-drawer">
    <div class="recurrency-payment-drawer__header">
      <span
        v-if="showRefundSidebar"
        tabindex="0"
        class="recurrency-payment-drawer__back-button"
        @click="showRefundSidebar = false"
      >
        <hs-icon icon="long-arrow-alt-left" />
        Voltar
      </span>
      <MTag
        v-else
        :label="getStatusLabel('installment', payment.status)"
        :variant="getStatusVariant('installment', payment.status)"
      />

      <div tabindex="0" class="recurrency-payment-drawer__close-button" @click="$emit('close')">
        <hs-icon icon="times" />
      </div>
    </div>

    <div class="recurrency-payment-drawer__content">
      <RecurrencyPaymentRefundDetails v-if="showRefundSidebar" :subscription="subscription" />
      <RecurrencyPaymentDetails
        v-else
        :payment="payment"
        :subscription="subscription"
        :available-refund-days="availableRefundDays"
      />
    </div>

    <div class="recurrency-payment-drawer__footer">
      <hr class="recurrency-payment-drawer__divider" />

      <div v-if="showRefundSidebar" class="recurrency-payment-drawer__refund-info">
        <hs-icon icon="bell" class="recurrency-payment-drawer__refund-info-icon" />

        <p
          v-html="$t(`recurrency-refund-info.${payment.paymentMethod}`)"
          class="recurrency-payment-drawer__refund-info-text"
        />
      </div>

      <DownloadRefundReceiptButton
        v-if="isPurchaseRefunded"
        :buyer-name="subscription.buyer.fullName"
        :payment-data="refundPaymentData"
      />
      <MXButton
        v-else-if="showRefundSidebar"
        variant="danger-primary"
        class="recurrency-payment-drawer__refund-button"
        :disabled="disableRefundButton"
        @click="submitRefund()"
      >
        <LoadingCircle v-if="disableRefundButton" :size="16" />
        <span>
          {{ refundButtonLabel }}
        </span>
      </MXButton>
      <div v-else-if="!isProcessingRefund()" v-b-tooltip.hover.top="invalidRefundTooltipText">
        <MXButton
          variant="danger-secondary"
          class="recurrency-payment-drawer__refund-button"
          :disabled="!enableRefund"
          @click="showRefundSidebar = true"
        >
          {{ requestRefundButtonText }}
        </MXButton>
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs';
import MTag from '@/components/MTag.vue';
import StatusMixin from '@/mixins/StatusMixin';
import RecurrencyPaymentDetails from './RecurrencyPaymentDetails.vue';
import RecurrencyPaymentRefundDetails from './RecurrencyPaymentRefundDetails.vue';
import MXButton from '@/shared/components/MXButton.vue';
import sparkPayService from '@/sparkpay/services/sparkpay';
import paymentsService from '@/services/sparkecommerce/payments';
import ToastHelper from '@/shared/helpers/toast';
import LoadingCircle from '@/components/LoadingCircle.vue';
import CookieHelper from '@/shared/helpers/cookie.js';
import { PaymentStatusList } from '@/types/payments';
import DownloadRefundReceiptButton from '@/sparkpay/views/Sales/components/DownloadRefundReceiptButton.vue';

const REFUNDED_STATUS = [PaymentStatusList.REFUNDED, PaymentStatusList.REFUND_ANALYZING, PaymentStatusList.CANCELED];

export default {
  name: 'RecurrencyPaymentDrawer',
  mixins: [StatusMixin],
  props: {
    payment: {
      type: Object,
      required: true,
    },
    subscription: {
      type: Object,
      required: true,
    },
  },
  components: {
    RecurrencyPaymentDetails,
    RecurrencyPaymentRefundDetails,
    MTag,
    MXButton,
    LoadingCircle,
    DownloadRefundReceiptButton,
  },
  data() {
    return {
      showRefundSidebar: false,
      isValidToRefund: false,
      isLoadingRefundRequest: false,
      refundInitialLoading: true,
      isGeneratingPdf: false,
      showPdfTemplate: false,
      saleCookieName: `recurrency-refund-processing-${this.payment.transactionId}`,
    };
  },
  computed: {
    refundPaymentData() {
      return {
        paymentId: this.payment.paymentId,
        paymentMethod: this.payment.paymentMethod,
        refundedValue: this.payment.totalAmountRefund,
        paidAt: this.payment.paidAt,
        createdAt: this.payment.createdAt,
        refundedAt: this.payment.refundedAt,
      };
    },
    isPaymentCanceledOrRefunded() {
      return REFUNDED_STATUS.includes(this.payment.status);
    },
    isPurchaseRefunded() {
      return this.payment.status === PaymentStatusList.REFUNDED;
    },
    requestRefundButtonText() {
      if (this.availableRefundDays <= 0) return 'Estorno indisponível';

      return 'Solicitar estorno';
    },
    disableRefundButton() {
      return this.isLoadingRefundRequest || this.refundInitialLoading;
    },
    refundButtonLabel() {
      if (this.refundInitialLoading) return '';
      if (this.isLoadingRefundRequest) return 'Processando solicitação';

      return 'Enviar solicitação de estorno';
    },
    enableRefund() {
      return this.isValidToRefund && this.availableRefundDays > 0;
    },
    invalidRefundTooltipText() {
      if (this.enableRefund) return '';

      if (this.availableRefundDays <= 0) {
        return 'O prazo para esta solicitação venceu';
      }

      return 'Você não possui saldo suficiente para esta ação';
    },
    availableRefundDays() {
      const refundDays = 90 - dayjs().diff(this.payment.paidAt, 'days');
      return refundDays < 0 ? 0 : refundDays;
    },
  },
  methods: {
    disableBodyScroll() {
      if (window.innerWidth <= 768) {
        document.body.style.overflow = 'hidden';
      }
    },
    enableBodyScroll() {
      document.body.style.overflow = '';
    },
    async fetchValidRefund() {
      if (!this.payment) return;

      const { purchaseId, paymentServiceIdentifier } = this.payment;

      try {
        const response = await sparkPayService.verifyValidRefund({
          purchase_id: purchaseId,
          payment_id: paymentServiceIdentifier,
        });

        this.isValidToRefund = response?.valid_refund;
      } catch (error) {
        this.isValidToRefund = false;
      }
    },
    async submitRefund() {
      this.isLoadingRefundRequest = true;

      try {
        await paymentsService.refund(this.payment.paymentId);

        ToastHelper.successMessage('Estorno realizado com sucesso');
        CookieHelper.setCookie(true, 1, this.saleCookieName);
      } catch {
        ToastHelper.dangerMessage('Erro ao realizar estorno');
      } finally {
        this.isLoadingRefundRequest = false;
        this.showRefundSidebar = false;

        this.$emit('close');
      }
    },
    isProcessingRefund() {
      const refundingSale = CookieHelper.getCookie(this.saleCookieName);

      if (this.isPaymentCanceledOrRefunded) {
        CookieHelper.deleteCookie(this.saleCookieName);
        return false;
      }

      return refundingSale;
    },
  },
  mounted() {
    this.disableBodyScroll();
    this.fetchValidRefund();
  },
  beforeDestroy() {
    this.enableBodyScroll();
  },
  watch: {
    payment() {
      this.showRefundSidebar = false;
      this.isValidToRefund = false;

      this.fetchValidRefund();
    },
    showRefundSidebar() {
      if (!this.showRefundSidebar) return;

      setTimeout(() => {
        this.refundInitialLoading = false;
      }, 2000);
    },
  },
};
</script>

<style lang="scss" scoped>
.recurrency-payment-drawer {
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  box-shadow: none;
  height: 100%;
  z-index: 10;
  background-color: $white;
  padding: 24px;
  box-shadow: -1px 2px 8px rgba(0, 0, 0, 0.25);

  @media (min-width: 768px) {
    top: 69px;
    right: 0;
    bottom: 0;
    width: 394px;
    height: auto;
  }
}

.recurrency-payment-drawer__close-button {
  cursor: pointer;
}

.recurrency-payment-drawer__download-pdf {
  width: 100%;
  margin-top: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.recurrency-payment-drawer__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.recurrency-payment-drawer__content {
  width: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  margin-top: 24px;
}

.recurrency-payment-drawer__footer {
  position: absolute;
  right: 24px;
  bottom: 24px;
  left: 24px;
}

.recurrency-payment-drawer__divider {
  border-color: $grey-16;
  width: 100%;
  margin-top: 24px;
  margin-bottom: 24px;
}

.recurrency-payment-drawer__refund-button {
  width: 100%;
  display: flex;
  column-gap: 8px;
}

.recurrency-payment-drawer__back-button {
  cursor: pointer;
  font-size: 0.875rem;
  line-height: 1.313rem;
  color: $grey;
}

.recurrency-payment-drawer__refund-info {
  display: grid;
  grid-template-columns: 16px 1fr;
  column-gap: 4px;
  margin-bottom: 24px;
}

.recurrency-payment-drawer__refund-info-icon {
  font-size: 1rem;
  color: #db3939;
  margin-top: 3px;
}

.recurrency-payment-drawer__refund-info-text {
  font-size: 0.875rem;
  line-height: 1.313rem;
  color: $grey-40;
  margin: 0;

  strong {
    font-weight: 700;
  }
}
</style>
