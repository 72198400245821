export default {
  /**
   * Set a cookie on the browser
   * @param {String} value value of the cookie
   * @param {Number} days duration of the cookie in days to be saved
   * @param {String} name name of the cookie
   */
  setCookie(value, days = 7, name = 'herocookie') {
    if (name) {
      document.cookie = `${name}=${value}; max-age=${days * 86400}; Secure`;
    }
  },
  /**
   * Get a cookie on the browser
   * @param {String} name name of the cookie that you looking for
   */
  getCookie(name) {
    return document.cookie.split(';').some(item => item.includes(name));
  },
  /**
   * Delete a cookie on the browser
   * @param {String} name name of the cookie that you want to delete
   */
  deleteCookie(name) {
    document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; Secure`;
  },
};
