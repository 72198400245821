<template>
  <section>
    <div class="user-info">
      <div class="user-info__status">
        <Tag :label="getStatusLabel(page, status)" :variant="getStatusVariant(page, status)" />
        <h3 class="user-info__status-title">
          {{ buyerName }}
        </h3>
      </div>
      <div class="user-info__detailed">
        <p>{{ buyerEmail }}</p>
        <p>
          <span>{{ $t('sparkpay.sales.details.info-box-student.cpf') }}</span>
          {{ buyerDocument }}
        </p>
        <p>
          <span>{{ $t('sparkpay.sales.details.info-box-student.id') }}</span>
          {{ subscriptionId }}
        </p>
        <div v-if="page === 'smartInstallment'" class="icon">
          <hs-icon size="17" icon="calendar-alt" />
          <p>{{ formatDate(createdAt) }}</p>
        </div>
      </div>
      <div class="user-info__product">
        <div class="user-info__product-title">
          <hs-icon size="24" icon="box-open" />
          <div>
            <p>{{ $t('sparkpay.sales.details.info-box-student.name-product') }}</p>
            <h3>{{ productName }}</h3>
          </div>
        </div>
        <div v-if="page === 'smartInstallment'" class="user-info__product-amount">
          <hs-icon size="24" icon="piggy-bank" />
          <p>
            Total Líquido: <span>{{ totalNetValueCents }}</span>
          </p>
        </div>
      </div>

      <div v-if="showAction" class="user-info__action">
        <slot name="action"></slot>
      </div>
    </div>
  </section>
</template>

<script>
import dayjs from 'dayjs';
import GeneralHelper from '@/shared/helpers/general';
import Tag from '@/components/MTag.vue';
import StatusMixin from '@/mixins/StatusMixin';

export default {
  name: 'SalexBoxUserInfo',
  mixins: [StatusMixin],
  components: {
    Tag,
  },
  props: {
    status: {
      type: String,
      required: true,
    },
    buyerName: {
      type: String,
      required: true,
    },
    buyerEmail: {
      type: String,
      required: true,
    },
    buyerDocument: {
      type: String,
      required: true,
    },
    subscriptionId: {
      type: Number,
      required: true,
    },
    createdAt: {
      type: String,
      required: true,
    },
    productName: {
      type: String,
      required: true,
    },
    netValueCents: {
      type: Number,
      default: 0,
    },
    page: {
      type: String,
      required: true,
      validator: value => ['installments', 'recurrency'].includes(value),
    },
  },
  data() {
    return {
      showAction: false,
    };
  },
  mounted() {
    this.showAction = this.$slots.action?.[0];
  },
  computed: {
    totalNetValueCents() {
      return GeneralHelper.currency(this.netValueCents / 100);
    },
  },
  methods: {
    getBadgeVariant(status) {
      const statusMap = {
        initiated: 'outline-secondary',
        processing: 'outline-warning',
        refused: 'outline-warning',
        waiting_payment: 'outline-warning',
        no_wait_processing: 'outline-warning',
        confirmed: 'outline-success',
        active: 'outline-success',
        abandoned: 'outline-cherry',
        canceled: 'outline-warning',
        subscription_canceled: 'outline-cherry',
        refunded: 'outline-cherry',
        chargeback: 'outline-cherry',
        overdue: 'outline-warning',
      };

      return statusMap[status];
    },
    formatDate(created_at) {
      return dayjs(created_at).format('DD/MM/YYYY [às] HH:mm');
    },
  },
};
</script>

<style lang="scss" scoped>
.user-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 24px;

  background-color: $white;
  border-radius: 4px;

  padding: 1.5rem 2rem;
  margin: 32px 16px 0 16px;
  > div {
    width: 100%;
  }

  &__status {
    .m-tag {
      margin-bottom: 0.5rem;
    }

    &-title {
      font-weight: 700;
      color: $grey-40;
      font-size: 1.5rem;
    }
  }

  &__detailed {
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 8px;
    word-wrap: break-word;

    p {
      margin-bottom: 0;
    }

    > p,
    div {
      font-size: 0.875rem;
      color: $grey-32;
    }

    > div.icon {
      display: flex;
      gap: 6px;
      align-items: center;
    }
  }

  &__product {
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 4px;

    &-title,
    &-amount {
      display: flex;
      gap: 18px;
      color: $grey-32;
    }

    &-title > div {
      p {
        font-weight: 400;
        font-size: 1rem;
        margin-bottom: 4px;
      }

      h3 {
        font-size: 1rem;
        font-weight: 700;
      }
    }

    &-amount {
      align-items: flex-end;
      > p {
        margin: 0;
        span {
          font-weight: 700;
        }
      }
    }
  }
  &__action {
    display: flex;
    justify-content: center;
    width: auto;
  }
}

@media (min-width: 1024px) {
  .user-info {
    gap: 16px;
    flex-direction: row;
    margin: 32px 32px 0 32px;
  }
}
</style>
