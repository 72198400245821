<template>
  <div class="tips-report-banner">
    <img src="@/assets/images/question.svg" alt="Question" />
    <div>
      <h4>Dicas sobre os relatórios</h4>
      <a
        href="https://ajuda.herospark.com/hc/pt-br/articles/23663547316237-Entenda-o-Dashboards-e-Relat%C3%B3rios-da-Plataforma"
        target="_blank"
        >Consulte nossa FAQ para compreender e esclarecer suas dúvidas sobre os relatórios de vendas.</a
      >
    </div>
  </div>
</template>

<script>
export default {
  name: 'TipsReportBanner',
};
</script>

<style scoped lang="scss">
.tips-report-banner {
  background-color: #fff;
  padding: 20px;
  border-radius: 4px;
  box-shadow: 0px 4px 8px 0px #3f3f3f14;

  display: flex;
  align-items: center;
  gap: 12px;

  h4 {
    margin: 0;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
  }

  a {
    color: #7427f1;
    text-decoration: underline;
    font-size: 14px;
    line-height: 21px;

    &:hover {
      color: #35126e;
    }
  }
}

@media screen and (max-width: $screen-bs-md) {
  .tips-report-banner {
    align-items: flex-start;
  }
}
</style>
