










import { Vue, Component } from 'vue-property-decorator';
import MongoReport from './components/MongoReport.vue';

@Component({
  components: {
    MongoReport,
  },
})
export default class InvoiceReport extends Vue {
  translateKey = 'menu.sparkpay.sales.invoice_report';
}
