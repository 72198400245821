export enum PaymentMethodsList {
  CREDIT_CARD = 'credit_card',
  BANK_SLIP = 'bank_slip',
  PIX = 'pix',
  BANK_SLIP_TRANSLATED = 'boleto',
  MULTICARD = 'multicard',
}

export enum PaymentStatusList {
  REFUNDED = 'refunded',
  REFUND_ANALYZING = 'refund_analyzing',
  CHARGEBACK = 'chargeback',
  CANCELED = 'canceled',
  REFUSED = 'refused',
  PAID = 'paid',
}
