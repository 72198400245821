import GeneralHelper from '@/shared/helpers/general';

export const notNull = x => x !== null;

function choose(fns) {
  let comb = null;
  return (value, testf) => (
    (testf = testf || notNull),
    (comb = value => (testf(value) ? value : null)),
    fns.reduce((acc, fn) => acc || comb(fn(value)), null)
  );
}

const PRODUCER = null;
const AFFILIATE = 'affiliate';
const COPRODUCER = ['coproducer', 'co-coproducer', 'co-producer'];

export const viewOfAffiliate = ({ participation }) => participation === AFFILIATE;

export const viewOfCoprodutor = ({ participation }) => COPRODUCER.includes(participation);

export const viewOfProducer = ({ participation }) => participation === PRODUCER;

export const affiliateValue = ipayment => ipayment.affiliate_fees_cents || 0;

export const coproductionValue = ipayment => ipayment.coproduction_fees_cents || 0;

export const coproductionsValue = ipayment => ipayment.coproductions_fees_cents || 0;

export const netValue = ipayment => ipayment.net_value_cents || 0;

export const comissionValueCents = ipayment => {
  const { coproductions_fees_cents, affiliate_fees_cents } = ipayment;
  let value = 0;
  if (coproductions_fees_cents) value += coproductions_fees_cents;
  if (affiliate_fees_cents) value += affiliate_fees_cents;

  return value;
};

export const producerValueCents = ipayment => {
  return netValue(ipayment) - coproductionsValue(ipayment) - coproductionValue(ipayment) - affiliateValue(ipayment);
};

export const netValueByParticipation = choose([
  ipayment => viewOfAffiliate(ipayment) && affiliateValue(ipayment),
  ipayment => viewOfCoprodutor(ipayment) && coproductionValue(ipayment),
  ipayment => producerValueCents(ipayment),
]);

export const netValueMongoCents = ipayment => ipayment.net_value_after_commission * 100 || 0;

export const comissionValueMongoCents = ipayment => ipayment.commission_fees * 100 || 0;

export const producerValueMongoCents = ipayment => netValueMongoCents(ipayment);

export const netValueByParticipationMongo = choose([
  ipayment => viewOfAffiliate(ipayment) && affiliateValue(ipayment),
  ipayment => viewOfCoprodutor(ipayment) && coproductionValue(ipayment),
  ipayment => producerValueMongoCents(ipayment),
]);

export default {
  methods: {
    hasAffiliate(ipayment) {
      return affiliateValue(ipayment) > 0;
    },
    hasCoproduction(ipayment) {
      return coproductionsValue(ipayment) > 0 || coproductionValue(ipayment) > 0;
    },
    viewOfAffiliate,
    viewOfCoprodutor,
    viewOfProducer,
    affiliateValue,
    coproductionValue,
    coproductionsValue,
    producerValueCents,
    netValue,
    comissionValueCents,
    netValueByParticipation,
    netValueMongoCents,
    comissionValueMongoCents,
    producerValueMongoCents,
    netValueByParticipationMongo,
    valueText(receiving, value) {
      const sign = receiving ? '=' : '-';
      return `${sign} ${GeneralHelper.currency(value / 100)}`;
    },
  },
};
