import productService from '@/sparkmembers/services/product';

export default {
  methods: {
    loadProductsOptions(page = 1, filterById = false) {
      return productService.getAll([{ key: 'per_page', value: 50 }, { key: 'page', value: page }]).then(res => {
        const productFilterItem = this.filters.find(filter => filter.key === 'product_id');
        const filterValueField = filterById ? 'id' : 'title';

        const productOptions = res.school_products.map(p => ({ item: { label: p.title, value: p[filterValueField] } }));

        productFilterItem.options = productFilterItem.options.concat(productOptions);

        if (res.current_page < res.total_pages) this.loadProductsOptions(res.current_page + 1, filterById);
      });
    },
    parseFiltersToParams() {
      return this.filters.filter(f => f.value).map(f => ({ key: f.key, value: f.value }));
    },
  },
};
