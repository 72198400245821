<template>
  <div class="tlw-flex tlw-flex-col tlw-items-center tlw-bg-white tlw-w-full">
    <hs-button @click="$bvModal.hide(modalCancelId)" variant="link" class="tlw-absolute tlw-top-1 tlw-right-1">
      <hs-icon class="tlw-text-black" icon="times" />
    </hs-button>
    <hsLoading v-if="isLoading" />
    <div v-else>
      <div class="tlw-flex tlw-items-center tlw-justify-center">
        <img width="180" :src="image" alt="cancel-subscription-modal" />
      </div>
      <h3 class="tlw-text-red-500 tlw-text-3xl tlw-font-bold tlw-text-center tlw-mt-11">
        {{ $t('sparkpay.sales.details.modal.subscription-cancellation') }}
      </h3>
      <p class="tlw-mt-6 tlw-text-center">
        {{
          $t('sparkpay.sales.details.modal.subscription-message', {
            memberName: sale.buyer.fullName,
            productName: sale.productName,
          })
        }}
      </p>
      <div
        class="tlw-flex tlw-flex-col-reverse lg:tlw-flex-row tlw-items-center tlw-justify-between tlw-w-full tlw-mt-12"
      >
        <m-button
          class="tlw-h-11 tlw-w-9/12 lg:tlw-w-6/12 tlw-mr-0 lg:tlw-mr-5 tlw-mt-6 lg:tlw-mt-0"
          variant="primary-outline"
          @click="$bvModal.hide(modalCancelId)"
        >
          {{ $t('sparkpay.sales.details.modal.close') }}
        </m-button>
        <m-button
          @click="cancelOrder(sale.subscriptionId)"
          class="tlw-h-11 tlw-w-9/12 lg:tlw-w-6/12"
          variant="critical"
        >
          {{ $t('sparkpay.sales.details.modal.subscription-cancel') }}
        </m-button>
      </div>
    </div>
  </div>
</template>

<script>
import MButton from '@/shared/components/MButton.vue';
import { orderService } from '@/services';
import ToastHelper from '@/shared/helpers/toast';
import { hsLoading } from '@/components';

export default {
  components: { MButton, hsLoading },
  props: {
    sale: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isLoading: false,
      image: require('@/assets/images/SparkPay/commons/img-robot-message.svg'),
      modalCancelId: 'sale-view-cancel',
    };
  },
  methods: {
    async cancelOrder(id) {
      try {
        this.isLoading = true;
        await orderService.cancelOrder({ id });

        this.$bvModal.hide(this.modalCancelId);
        this.$emit('onSuccess');
      } catch (error) {
        ToastHelper.dangerMessage(this.$t('sparkpay.sales.details.modal.error'));
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>
