<template>
  <div>
    <MongoReport
      dashboard-id="64dd08a7-334c-4ab5-8b44-95fb873f1bf5"
      :translate-key="translateKey"
      :disable-report-download="false"
      report-collection="transaction"
      export-type="refunded_chargeback"
      isRefundedAt
    />
  </div>
</template>

<script>
import MongoReport from './components/MongoReport.vue';

export default {
  name: 'RefundedSalesReport',
  components: {
    MongoReport,
  },
  data() {
    return {
      translateKey: 'menu.sparkpay.sales.refunded_sales_report',
    };
  },
};
</script>

<style lang="scss" scoped>
.subscription-sales-made-report__container {
  padding: 24px 16px;

  @media (min-width: $screen-md) {
    padding: 24px 32px;
  }
}
</style>
