<template>
  <section class="tlw-flex tlw-relative flex-fill tlw-flex-col" v-if="!isRouteSalesNoHeader">
    <div class="sales flex-fill tlw-flex tlw-flex-col" :class="isSidebarSaleOpened ? 'sales--sidebar-opened' : ''">
      <div class="header-wrapper">
        <hsPageHeader
          class="header tlw-bg-grey-100"
          :title="$t('sparkpay.sales.title')"
          :sub-title="$t('sparkpay.sales.sub-title')"
        >
        </hsPageHeader>
        <div v-if="canAccessReports" class="button">
          <hsButton @click="$router.push({ name: 'ReleasesReport' })" variant="primary" class="btn-primary">
            <hs-icon variant="light" :size="20" icon="rocket" />
            Painel de lançamento
          </hsButton>
        </div>
      </div>
      <hs-alert
        v-if="feedback.status == 'error'"
        class="alert-refund"
        variant="danger"
        :show="feedbackAlert"
        :fade="true"
        @dismissed="feedbackAlert = false"
        dismissible
      >
        {{ $t('sparkpay.sales.sales-sidebar.refound.msg-feedback-error') }}
      </hs-alert>
      <hs-alert
        v-else
        class="alert-refund"
        variant="success"
        :show="feedbackAlert"
        :fade="true"
        @dismissed="feedbackAlert = false"
        dismissible
      >
        {{ $t('sparkpay.sales.sales-sidebar.refound.msg-feedback-success') }} {{ feedback.name }}
      </hs-alert>
      <template v-if="hasSubscriptionFlagEnabled || hasInvoiceMetabaseReportFlagEnabled">
        <nav class="px-3 px-md-5 bg-white">
          <ul class="tlw-flex tlw-items-center tlw-justify-start w-100 m-0 overflow-auto">
            <li class="tlw-mr-10">
              <router-link
                class="sales__tab tlw-flex w-100 pb-3 text-decoration-none text-dark"
                :to="{ name: 'UnitsList' }"
              >
                {{ $t('menu.sparkpay.sales.tabs.units') }}
              </router-link>
            </li>
            <li class="tlw-mr-10">
              <router-link
                class="sales__tab tlw-flex w-100 pb-3 text-decoration-none text-dark"
                :to="{ name: 'SalesStrategyList' }"
              >
                {{ $t('menu.sparkpay.sales.tabs.smart-installment') }}
              </router-link>
            </li>
            <li v-if="hasSubscriptionFlagEnabled">
              <router-link
                class="sales__tab tlw-flex w-100 pb-3 text-decoration-none text-dark"
                :to="{ name: 'SubscriptionsList' }"
              >
                {{ $t('menu.sparkpay.sales.tabs.subscriptions') }}
              </router-link>
            </li>
            <li class="tab-invoice-report" v-if="hasInvoiceMetabaseReportFlagEnabled && canAccessReports">
              <router-link
                class="sales__tab tlw-flex w-100 pb-3 text-decoration-none text-dark"
                :to="{ name: 'SalesReport' }"
              >
                {{ $t('menu.sparkpay.sales.tabs.sales_report') }}
              </router-link>
            </li>
          </ul>
        </nav>
        <router-view></router-view>
      </template>
      <UnitsList v-else />
    </div>
    <transition name="slide-fade">
      <SidebarDetailed
        v-if="isSidebarSaleOpened"
        :sale="sidebarSale"
        @close="() => selectSidebarSale(null)"
        @feedbackRefund="feedbackRefundReceive($event)"
      />
    </transition>
  </section>
  <section v-else>
    <router-view></router-view>
  </section>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import hsPageHeader from '@/components/_structures/PageHeader';
import UnitsList from './UnitsList';
import SidebarDetailed from './components/SidebarDetailed';
import HsButton from '@/components/hsButton.vue';

const routeSalesNoHeaderList = [
  'SalesDetails',
  'InvoiceReport',
  'SalesMadeReport',
  'OrderBumpSalesMadeReport',
  'AbandonedSalesReport',
  'SalesInstallmentDetails',
  'ReleasesReport',
];

export default {
  data() {
    return {
      feedback: {
        name: null,
        status: null,
      },
      feedbackAlert: false,
    };
  },
  components: {
    hsPageHeader,
    UnitsList,
    SidebarDetailed,
    HsButton,
  },
  computed: {
    ...mapState('sale', ['sidebarSale']),
    ...mapState('auth', ['loggedUser']),
    ...mapGetters('sale', ['isSidebarSaleOpened']),
    hasSubscriptionFlagEnabled() {
      return this.$FCL.canMembersSubscriptionOffers();
    },
    hasInvoiceMetabaseReportFlagEnabled() {
      return this.$FCL.hasInvoiceMetabaseReport();
    },
    canAccessReports() {
      return this.$ACL.canSalesPage() && this.loggedUser.role?.name != 'Assistência e Vendas';
    },
    isRouteSalesNoHeader() {
      return routeSalesNoHeaderList.includes(this.$route.name);
    },
  },
  methods: {
    ...mapActions('sale', ['selectSidebarSale']),
    feedbackRefundReceive($event) {
      this.feedback = $event;
      this.feedbackAlert = true;
      setTimeout(() => {
        this.feedbackAlert = false;
      }, 5000);
    },
  },
  created() {
    if (!this.isRouteSalesNoHeader && !this.hasSubscriptionFlagEnabled) {
      this.$router.push({ name: 'Sales' });
    }
  },
};
</script>

<style lang="scss" scoped>
.header-wrapper {
  > .header {
    flex: 1;
  }

  > .button {
    display: flex;
    align-items: center;
    padding-right: 2rem;

    button {
      background-color: #7427f1;
      padding: 0.54rem 1rem;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 0.5rem;
    }
  }
  background-color: white;
  display: flex;
}
.sales {
  transition: width 0.3s ease;

  &__tab {
    border-bottom: 3px solid transparent;
    &.router-link-active {
      border-color: $purple-dark;
      font-weight: bold;
    }
  }

  .tab-invoice-report {
    margin-left: 24px;
  }

  &--sidebar-opened {
    width: calc(100% - 335px);
    transition: width 0.3s cubic-bezier(1, 0.5, 0.8, 1);

    @media screen and (max-width: 576px) {
      width: 100%;
    }
  }
}

.slide-fade-enter-active {
  transition: all 0.3s ease;
}
.slide-fade-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active em versões anteriores a 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}

/deep/ {
  .hs-filter {
    > section {
      padding: 0 !important;
      background-color: transparent !important;
      box-shadow: none !important;
    }
  }
  .alert-refund {
    position: fixed;
    right: 0;
    top: 133px;
    width: 355px;
    z-index: 2;

    .d-flex {
      align-items: center;
    }
  }
}

@media screen and (max-width: $screen-bs-sm) {
  .header-wrapper {
    flex-wrap: wrap;
  }
  .header-wrapper > .button {
    padding: 0 16px;
    margin-bottom: 24px;
    width: 100%;

    button {
      width: 100%;
    }
  }

  nav {
    border-top: 1px solid #e3e3e3;
    padding-top: 16px;
  }
}
</style>
